import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { historyType } from 'types/reactRouter';
import { statuses } from 'constants/catalogue';
import { statusActions } from 'constants/adminCatalogue';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import Price from 'components/common/Price';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminCataloguePackageListItem = ({
  item,
  history,
  anchorElement,
  onMenuOpen,
  onMenuClose,
  onStatusChange,
}) => (
  <div className={classNames(
    rcStyles.list_item,
    item.isNewItem ? classNames(
      styles.c_flag_standard__PENDING,
      styles.o_list_item_tertiary__new,
    ) : '',
  )}
  >
    <div className={rcStyles.title}>
      <h3 className={styles.primer}>{item.name}</h3>
      <p className={styles.brevier}>{item.description}</p>
    </div>
    <div className={rcStyles.price}>
      {item.isFixed ? (
        <div className={styles.brevier}>{t('Fixed')}</div>
      ) : (
        <div className={styles.brevier}>{t('Not Fixed')}</div>
      )}
      <Price price={item.price} />
    </div>
    <div className={rcStyles.actions}>
      <IconButton
        aria-controls="edit-menu"
        aria-haspopup="true"
        data-test-id="moreOptions"
        onClick={onMenuOpen}
        className={rcStyles.rc_icon_height}
      >
        <MoreVerticalIcon />
      </IconButton>

      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={!!anchorElement}
        onClose={onMenuClose}
      >
        <MenuItem
          data-test-id="edit"
          onClick={() => history.push(`/catalogue/package/edit/${item.id}`)}
        >
          {t('Edit')}
        </MenuItem>
        {(item.status === statuses.RETIRED || item.status === statuses.DRAFT) && (
          <MenuItem
            onClick={() => {
              onStatusChange(item, statusActions.PUBLISH);
              onMenuClose();
            }}
            data-test-id="publish"
          >
            {t('Publish')}
          </MenuItem>
        )}
        {item.status === statuses.DRAFT && (
          <MenuItem
            onClick={() => onStatusChange(item, statusActions.DELETE)}
            data-test-id="delete"
          >
            {t('Delete')}
          </MenuItem>
        )}
        {item.status === statuses.PUBLISHED && (
          <MenuItem
            onClick={() => onStatusChange(item, statusActions.RETIRE)}
            data-test-id="retire"
          >
            {t('Retire')}
          </MenuItem>
        )}
      </Menu>
    </div>
  </div>
);

AdminCataloguePackageListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  history: historyType,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func,
};

export default AdminCataloguePackageListItem;
