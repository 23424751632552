import React from 'react';
import ReactDOM from 'react-dom';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { initialiseNetworkSubscriptions } from 'services/network/listeners';
import { store } from 'services/state';
import { setUpdatingMessageAction } from 'actions/user';
import App from './App';

ReactDOM.render(React.createElement(App), document.getElementById('root'));

const applicationUpdateConfiguration = {
  onUpdating: () => {
    store.dispatch(setUpdatingMessageAction('The application is being updated.'));
  },
  onUpdateReady: () => {
    // Tells to new SW to take control immediately
    OfflinePluginRuntime.applyUpdate();
    store.dispatch(setUpdatingMessageAction('Application successfully updated.'));
  },
  onUpdated: () => {
    // Reload the webpage to load into the new version
    store.dispatch(setUpdatingMessageAction(null));
    window.location.reload();
  },

  onUpdateFailed: () => {
    store.dispatch(setUpdatingMessageAction('Update failed, please refresh the page.'));
    console.error('SW Event:', 'onUpdateFailed');
  },
};

if (process.env.FEATURE_FLAG_AUTO_UPDATE_FUNCTIONALITY) {
  OfflinePluginRuntime.install(applicationUpdateConfiguration);
}

initialiseNetworkSubscriptions();
