import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconOffline from 'icons/IconOffline';
import IconSync from 'icons/IconSync';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const OfflineIndicator = ({ isSyncing }) => (
  <div className={rcStyles.offlineWrap}>
    <div className={styles.brevier}>
      {isSyncing ? t('Syncing') : t('Offline')}
    </div>
    <div className={rcStyles.offlineIcon}>
      {isSyncing ? <IconSync /> : <IconOffline />}
    </div>
  </div>
);

OfflineIndicator.propTypes = {
  isSyncing: PropTypes.bool.isRequired,
};

export default OfflineIndicator;
