export enum TelephoneType {
  WORK = 'WORK',
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  FAX = 'FAX'
}

export enum OrderDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
