import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CurrencyField from 'components/common/CurrencyField';
import CloseIcon from '@material-ui/icons/Close';
import { defaultMoneyObject, getUriForMediaByVariant } from 'services/utils';
import { mediaVariants } from 'constants/media';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminCatalogueItemVariant = ({
  variant,
  disabled,
  isDeletable,
  errors,
  index,
  onChange,
  onDeleteVariant,
  toggleSelectImageModal,
  onSelectVariantImage,
  onSaveVariantImage,
}) => (
  <Fragment>

    <div className={rcStyles.variant}>
      <div className={rcStyles.variant__group}>
        {variant.image ? (
          <div
            className={rcStyles.variant__image}
            style={{
              backgroundImage: `url('${getUriForMediaByVariant(variant.image, mediaVariants.FIT_200_X_200)}')`,
            }}
          >
            <IconButton
              className={rcStyles.is_delete}
              color="primary"
              variant="contained"
              aria-label="Delete"
              onClick={() => { onSelectVariantImage(null); onSaveVariantImage(variant.id); }}
              data-test-id="delete"
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className={rcStyles.variant__button}>
            <Button
              onClick={() => toggleSelectImageModal(variant.id)}
              variant="contained"
              color="primary"
              data-test-id="selectVariantImage"
              fullWidth
            >
              {t('Select Image')}
            </Button>
          </div>
        )}

        <div className={rcStyles.variant__inputs}>
          <TextField
            className={rcStyles.variant__name}
            label={t('Variant name *')}
            onChange={event => onChange('name', variant.id, event.target.value)}
            fullWidth
            InputProps={{
              inputProps: {
                'data-test-id': 'cost',
              },
            }}
            value={variant.name || ''}
            disabled={disabled}
            error={errors && errors.variants && errors.variants[index] && errors.variants[index].name}
            helperText={errors && errors.variants && errors.variants[index] && errors.variants[index].name}
          />
          <div className={rcStyles.variant__details}>
            <CurrencyField
              label={t('Cost price')}
              value={variant.prices && variant.prices.cost && variant.prices.cost.amount}
              fullWidth
              min={0}
              InputProps={{
                inputProps: {
                  'data-test-id': 'cost',
                },
              }}
              onChange={value => onChange('prices', variant.id, {
                ...variant.prices,
                cost: defaultMoneyObject(value),
              })}
              disabled={disabled}
            />
            <CurrencyField
              label={t('RRP')}
              value={variant.prices && variant.prices.retail && variant.prices.retail.amount}
              fullWidth
              min={0}
              InputProps={{
                inputProps: {
                  'data-test-id': 'retailPrice',
                },
              }}
              onChange={value => onChange('prices', variant.id, {
                ...variant.prices,
                retail: defaultMoneyObject(value),
              })}
              disabled={disabled}
            />
            <CurrencyField
              label={t('Sale price')}
              value={variant.prices && variant.prices.sale && variant.prices.sale.amount}
              fullWidth
              min={0}
              InputProps={{
                inputProps: {
                  'data-test-id': 'salePrice',
                },
              }}
              onChange={value => onChange('prices', variant.id, {
                ...variant.prices,
                sale: defaultMoneyObject(value),
              })}
              disabled={disabled}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={variant.isGuidePrice}
                  onChange={event => onChange('isGuidePrice', variant.id, event.target.checked)}
                  inputProps={{ 'data-test-id': 'isGuidePrice' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Guide price')}
            />
          </div>
        </div>
      </div>
      {isDeletable ? (
        <div className={rcStyles.variant__delete}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classNames(styles.o_button__round)}
            onClick={() => onDeleteVariant(variant.id)}
            disabled={disabled}
            data-test-id="delete"
          >
            <RemoveIcon aria-label={t('Delete')} />
          </Button>
        </div>
      ) : (
        <div />
      )}
    </div>
  </Fragment>
);

AdminCatalogueItemVariant.propTypes = {
  variant: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  isDeletable: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteVariant: PropTypes.func.isRequired,
  toggleSelectImageModal: PropTypes.func.isRequired,
  onSelectVariantImage: PropTypes.func.isRequired,
  onSaveVariantImage: PropTypes.func.isRequired,
};

export default AdminCatalogueItemVariant;
