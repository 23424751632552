import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import { t } from 'i18next';
import './chartistStyles.scss?raw';
import './tooltipStyles.scss?raw';
import 'chartist-plugin-legend';
import 'chartist-plugin-axistitle';
import classNames from 'classnames';
import styles from './singleValueStyles.scss';
import 'chartist-plugin-tooltips-updated';


const XAxisPaddingBottom = 20;
const chartHeight = 320;

// used to calculate the bar sizes for column chart
const estimatedChartHeight = chartHeight - 60 - XAxisPaddingBottom;

// used to calculate the bar sizes for bar chart
const estimatedChartWidth = 520;

/* eslint no-underscore-dangle: ["error", { "allow": ["_node"] }] */

class ChartistView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      chartType: null,
      chartOptions: {},
      listener: {},
      axis: props.axis,
      reportInfo: props.reportInfo,
    };
  }

  getDonut(chartData, width, legendOptions, listener) {
    let options = {
      showLabel: false,
      donut: true,
      // If specified the donut segments will be drawn as shapes instead of strokes.
      donutSolid: false,
      // Specify the donut stroke width
      // This option can be set as number or string to specify a relative width (i.e. 100 or '30%').
      donutWidth: '30px',
    };

    if (legendOptions) {
      options = Object.assign({
        plugins: [
          Chartist.plugins.legend(legendOptions),
          this.getToolTip(0, -15, false, 'Donut'),
        ],
      }, options);
    } else {
      options = Object.assign({
        plugins: [
          this.getToolTip(0, -15, false, 'Donut'),
        ],
      }, options);
    }

    return this.getChart(
      chartData,
      'Pie',
      options,
      listener || {},
      width,
    );
  }

  getToolTip = (xOffset, yOffset, anchor, chartType) => Chartist.plugins.tooltip({
    tooltipFnc: (arg, v) => {
      const value = (chartType === 'Pie' || chartType === 'Donut') ? `${v}%` : v;
      return `<span class="is_value">${value}</span><span class="is_label">${t(arg)}</span>`;
    },
    anchorToPoint: anchor,
    appendToBody: true,
    tooltipOffset: {
      x: xOffset,
      y: yOffset,
    },
  });

  barSizeProperties = (type) => {
    const { chartData } = this.state;

    const a = (type === 'bar') ? estimatedChartWidth : estimatedChartHeight;
    const seriesSpace = 2;

    const countSeries = chartData.series.length;
    const countLabels = chartData.labels.length;

    // f is the base factor that affects the spacing between the series groups
    // we use various arbitrary log functions as a way of applying an (inverse) non-linear
    // relationship between the number of labels and the size spacing between them
    // ie the more labels the smaller the gap (squash them together to fit more)
    // it uses a rough estimate of the size of the chart to do the inversion
    const f = Math.log2(a) - (Math.log2(countLabels) * Math.log(countLabels));

    // ensure at least 2 pixel gap and increase the value of f by dividing by log10
    // if 1 label (Infinity) set the spaing to 0
    let labelSpace = Math.max(f / Math.log10(countLabels), 2);
    labelSpace = (labelSpace === Infinity) ? 0 : labelSpace;

    // use the number of series and labels to calculate the barStroke and seriesDistance values
    const labelArea = (a / countLabels) - labelSpace;
    const seriesArea = (labelArea / countSeries) - seriesSpace;

    return {
      barStroke: seriesArea - seriesSpace,
      seriesDistance: seriesArea,
    };
  }

  axisTitles = (showX, showY, chartType) => {
    let config = {};
    const { axis } = this.state;

    let { x, y } = axis;

    if (chartType === 'Column') {
      x = axis.y;
      y = axis.x;
    }

    if (showX) {
      config = Object.assign({
        axisX: {
          axisTitle: t(x),
          axisClass: 'ct-axis-title',
          offset: {
            x: 0,
            y: 40,
          },
          textAnchor: 'middle',
        },
      }, config);
    }

    if (showY) {
      config = Object.assign({
        axisY: {
          axisTitle: t(y),
          axisClass: 'ct-axis-title',
          offset: {
            x: 0,
            y: 0,
          },
          textAnchor: 'middle',
          flipTitle: false,
        },
      }, config);
    }

    return config;
  }

  createLegend = async data => new Promise((resolve) => {
    setTimeout(() => {
      const ctChartEl = data.svg._node.parentElement;
      if (ctChartEl) {
        const legendEl = ctChartEl.getElementsByTagName('ul')[0];
        const legendWrapperEl = document.createElement('div');
        legendWrapperEl.setAttribute('class', 'ct-chart-legend');

        if (legendEl) {
          ctChartEl.parentElement.appendChild(legendWrapperEl);
          legendWrapperEl.appendChild(legendEl);
        }

        if (ctChartEl.parentElement.getElementsByClassName('ct-chart-legend').length > 1) {
          ctChartEl.parentElement.getElementsByClassName('ct-chart-legend')[0].remove();
        }
      }

      resolve(ctChartEl);
    }, 1500);
  })

  getChart = (chartData, chartType, chartOptions, listener, width) => {
    let options = Object.assign(chartOptions, {
      height: `${chartHeight}px`,
    });

    if (width === 'full') {
      options = Object.assign(options, {
        fullWidth: true,
      });
    } else {
      options = Object.assign(options, {
        fullWidth: false,
        width,
      });
    }

    const chartListener = listener || {};

    return (
      <ChartistGraph
        data={chartData}
        type={chartType}
        options={options}
        listener={chartListener}
      />
    );
  }

  renderBar() {
    const { chartData, reportInfo } = this.state;
    const { seriesDistance, barStroke } = this.barSizeProperties('bar');
    let showXaxis = true;

    const defaultLegendOptions = {
      position: 'bottom',
      legendNames: chartData.series.map(serie => t(serie.name)),
    };

    if (reportInfo.isTimeSeries === true) {
      showXaxis = false;
    }

    return (
      <div className={styles.grid_has_legend}>
        {this.getChart(
          chartData,
          'Bar',
          {
            chartPadding: {
              bottom: XAxisPaddingBottom,
            },
            seriesBarDistance: seriesDistance,
            plugins: [
              Chartist.plugins.legend(defaultLegendOptions),
              Chartist.plugins.ctAxisTitle(this.axisTitles(showXaxis, true, 'Bar')),
              this.getToolTip(0, -10, true, 'Bar'),
            ],
          },
          {
            'draw': (data) => {
              if (data.type === 'bar') {
                data.element._node.setAttribute('style', `stroke-width: ${barStroke}px`);
              }
            },
            'created': this.createLegend,
          },
          'full',
        )}
      </div>
    );
  }

  renderColumn() {
    const { seriesDistance, barStroke } = this.barSizeProperties('colum');
    const { chartData } = this.state;

    const defaultLegendOptions = {
      position: 'bottom',
      legendNames: chartData.series.map(serie => t(serie.name)),
    };

    return (
      <div className={styles.grid_has_legend}>
        {this.getChart(
          chartData,
          'Bar',
          {
            chartPadding: {
              bottom: XAxisPaddingBottom,
            },
            seriesBarDistance: seriesDistance,
            reverseData: true,
            horizontalBars: true,
            axisY: {
              offset: 70,
            },
            plugins: [
              Chartist.plugins.legend(defaultLegendOptions),
              Chartist.plugins.ctAxisTitle(this.axisTitles(true, false, 'Column')),
              this.getToolTip(0, -15, false, 'Column'),
            ],
          },
          {
            'draw': (data) => {
              if (data.type === 'bar') {
                data.element._node.setAttribute('style', `stroke-width: ${barStroke}px`);
              }
            },
            'created': this.createLegend,
          },
          'full',
        )}
      </div>
    );
  }

  renderPie() {
    const defaultLegendOptions = {};
    const { chartData } = this.state;

    return (
      <div className={styles.grid_has_legend}>
        {this.getChart(
          chartData,
          'Pie',
          {
            showLabel: false,
            plugins: [
              Chartist.plugins.legend(defaultLegendOptions),
              this.getToolTip(0, -15, false, 'Pie'),
            ],
          },
          {
            'created': this.createLegend,
          },
          'full',
        )}
      </div>
    );
  }

  renderDonut() {
    const { chartData } = this.state;

    const legendOptions = {
      position: 'bottom',
      clickable: true,
    };

    return (
      <div className={styles.grid_has_legend}>
        {this.getDonut(
          chartData,
          'full',
          legendOptions,
          {
            'created': this.createLegend,
          },
        )}
      </div>
    );
  }

  renderDonutMultiple() {
    const { chartData } = this.state;

    let legendOptions = false;
    const dataLen = chartData.length;

    return (
      <div className={classNames(
        styles.grid_has_legend,
        styles.has_multiple_charts,
      )}
      >
        {chartData.map((instanceData, i) => {
          if (dataLen === i + 1) {
            legendOptions = {
              position: 'bottom',
              clickable: false,
            };
          }
          return this.getDonut(
            instanceData,
            '100%',
            legendOptions,
            {
              'created': (data) => {
                const txt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                txt.setAttributeNS(null, 'x', '50%');
                txt.setAttributeNS(null, 'y', '50%');
                txt.setAttributeNS(null, 'text-anchor', 'middle');
                txt.setAttributeNS(null, 'dominant-baseline', 'middle');

                const name = document.createTextNode(instanceData.name);
                txt.appendChild(name);
                data.svg._node.appendChild(txt);

                this.createLegend(data);
              },
            },
          );
        })}
      </div>
    );
  }

  render() {
    const {
      chartData,
      chartType,
      chartOptions,
      listener,
    } = this.state;
    return this.getChart(chartData, chartType, chartOptions, listener, 'full');
  }
}

ChartistView.propTypes = {
  chartData: PropTypes.objectOf(PropTypes.object).isRequired,
  axis: PropTypes.objectOf(PropTypes.object),
  reportInfo: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ChartistView;
