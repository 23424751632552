import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { status as taskStatus } from 'constants/task';
import { checkArrangementsHaveViewings } from 'services/utils/arrangement';
import {
  getConfirmedArrangementFromBereavement,
  getUnconfirmedArrangementsFromBereavement,
} from 'services/utils/bereavement';
import { apolloClientType } from 'types/apollo';
import { bereavementType } from 'types/bereavement';
import { historyType, matchType } from 'types/reactRouter';

import CaseSummaryTile from './CaseSummaryTile';
import { getTasks } from './queries.gql';

class CaseSummaryTileContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    client: apolloClientType.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
    editBereavement: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onArrangementModalOpen: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { bereavement: { home, owner } } = props;

    this.state = {
      chartData: {},
      selectedHome: home,
      selectedOwner: owner,
      isLoading: true,
      isModalOpen: {
        appointment: false,
        assignCase: false,
        viewings: false,
        noArrangementsForViewings: false,
      },
    };
  }

  async componentDidMount() {
    const { client, bereavement } = this.props;

    const response = await client.query({
      query: getTasks,
      variables: {
        pagination: { first: 1000, after: null },
        bereavementId: bereavement.id,
      },
    });

    const tasks = response.data.tasks.edges.map(({ node }) => node);
    const pendingTasks = tasks.filter(task => task.status === taskStatus.PENDING);
    const completedTasks = tasks.filter(task => task.status === taskStatus.COMPLETED);

    this.setState({
      chartData: {
        name: `${tasks.length} tasks`,
        series: [
          { name: 'Pending', value: pendingTasks.length },
          { name: 'Completed', value: completedTasks.length },
        ],
      },
      isLoading: false,
    });
  }

  toggleModal = (key) => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [key]: !prevState.isModalOpen[key],
      },
    }));
  }

  handleHomeChange = (selectedHome) => {
    this.setState({ selectedHome, selectedOwner: null });
  }

  handleOwnerChange = (selectedOwner) => {
    this.setState({ selectedOwner });
  }

  handleAppointmentChange = (appointment) => {
    const { editBereavement } = this.props;
    editBereavement([{ key: 'appointment', value: appointment }]);
  }

  handleAssignCaseCancel = () => {
    const { bereavement: { home, owner } } = this.props;
    this.setState({
      selectedHome: home,
      selectedOwner: owner,
    });
    this.toggleModal('assignCase');
  }

  handleAssignCaseSave = (isValid) => {
    const { editBereavement } = this.props;
    const { selectedHome, selectedOwner } = this.state;
    if (!isValid) {
      return;
    }

    const updates = [
      { key: 'home', value: selectedHome },
      { key: 'homeId', value: selectedHome.id },
      { key: 'owner', value: selectedOwner },
      { key: 'ownerId', value: selectedOwner.id },
    ];
    editBereavement(updates);
    this.toggleModal('assignCase');
  }

  handleCreateAndEditViewings = (arrangement) => {
    const { history, match } = this.props;
    const { id: arrangementId } = arrangement;
    history.push(`${match.url}/${arrangementId}/care`);
  }

  handleViewingsOpen = () => {
    const { bereavement } = this.props;
    const { arrangements = [] } = bereavement || {};

    if (!arrangements?.length) {
      this.toggleModal('noArrangementsForViewings');
      return;
    }

    const confirmedArrangement = getConfirmedArrangementFromBereavement(bereavement, false);
    const unconfirmedArrangements = getUnconfirmedArrangementsFromBereavement(bereavement);

    const redirectToCare = arrangements.length === 1
      || (confirmedArrangement
      && checkArrangementsHaveViewings([confirmedArrangement])
      && !checkArrangementsHaveViewings(unconfirmedArrangements));

    if (redirectToCare) {
      const arrangement = confirmedArrangement || arrangements[0];
      this.handleCreateAndEditViewings(arrangement);
    } else {
      this.toggleModal('viewings');
    }
  }

  render() {
    const {
      chartData,
      selectedHome,
      selectedOwner,
      isLoading,
      isModalOpen,
    } = this.state;

    return (
      <CaseSummaryTile
        {...this.props}
        isModalOpen={isModalOpen}
        chartData={chartData}
        selectedHome={selectedHome}
        selectedOwner={selectedOwner}
        isLoading={isLoading}
        toggleAppointmentModal={() => this.toggleModal('appointment')}
        onAppointmentSave={this.handleAppointmentChange}
        onHomeChange={this.handleHomeChange}
        onOwnerChange={this.handleOwnerChange}
        onAssignCaseModalOpen={() => this.toggleModal('assignCase')}
        onAssignCaseCancel={this.handleAssignCaseCancel}
        onAssignCaseSave={this.handleAssignCaseSave}
        onViewingsOpen={this.handleViewingsOpen}
        onViewingsClose={() => this.toggleModal('viewings')}
        onCreateAndEditViewings={this.handleCreateAndEditViewings}
        toggleNoArrangementsForViewingsModal={() => this.toggleModal('noArrangementsForViewings')}
      />
    );
  }
}

export default withRouter(withValidation(withApollo(CaseSummaryTileContainer)));
