import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';

import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { bereavementType, arrangementType, serviceType } from 'types/bereavement';
import EstimateHeader from 'components/estimate/EstimateHeader';
import EstimateDiscountSelector from 'components/estimate/EstimateDiscountSelector';
import EstimateRows from 'components/estimate/EstimateRows';
import EstimateAddons from 'components/estimate/EstimateAddons';
import EstimateTotals from 'components/estimate/EstimateTotals';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { changeConfirmedArrangementMessage } from 'constants/arrangement';
import { featureFlags } from 'constants/features';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const ArrangementEstimateScreen = ({
  bereavement,
  arrangement,
  availableFeatures,
  duplicateSelectionItem,
  hasADiscountBeenApplied,
  isConfirmationWarningModalOpen,
  isOnline,
  disabled,
  onDuplicateItemConfirm,
  onDuplicateItemCancel,
  onHideDiscountChange,
  onOpenDuplicateSelectionModal,
  onOpenConfirmationWarningModal,
  onClickConfirmationWarningModalConfirm,
  onClickConfirmationWarningModalCancel,
  onSortEnd,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <div className={classNames(
      styles.c_page__full_height,
      styles.c_page__clear_bottom_nav,
      styles.has_faded_background,
    )}
    >
      <Helmet>
        <title>{t('Arrangement - Estimate')}</title>
      </Helmet>
      <div className={classNames(
        styles.o_view,
        styles.has_secondary_background,
      )}
      >
        <div className={styles.o_view__main}>
          <EstimateHeader
            bereavement={bereavement}
            arrangement={arrangement}
            availableFeatures={availableFeatures}
            disabled={disabled}
            isOnline={isOnline}
          />
          {availableFeatures.includes(featureFlags.ESTIMATE_DISCOUNT_SELECTOR) && (
            <Fragment>
              <EstimateDiscountSelector
                bereavementId={bereavement.id}
                arrangement={arrangement}
                disabled={disabled}
                onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
              />
              <FormControlLabel
                classes={{
                  ...hasADiscountBeenApplied() === false && { root: styles.u_hidden },
                }}
                control={(
                  <Checkbox
                    onChange={event => onHideDiscountChange(event.target.checked)}
                    inputProps={{ 'data-test-id': 'isDiscountHidden' }}
                    color="primary"
                    checked={arrangement.hideDiscount}
                  />
                )}
                label={t('Hide Discount')}
              />
            </Fragment>
          )}
          <div className={styles.o_block_content}>
            <EstimateRows
              bereavementId={bereavement.id}
              arrangement={arrangement}
              disabled={disabled}
              onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
              onSortEnd={onSortEnd}
            />
          </div>
          <div className={styles.o_block_content}>
            <EstimateAddons
              bereavementId={bereavement.id}
              bereavementHomeId={bereavement.homeId}
              arrangement={arrangement}
              disabled={disabled}
              onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
              onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
              onSortEnd={onSortEnd}
            />
          </div>
          <div className={styles.o_block_content}>
            <EstimateTotals
              bereavementId={bereavement.id}
              arrangement={arrangement}
              disabled={disabled}
              onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
            />
          </div>
          <ConfirmationModal
            message={t(`${duplicateSelectionItem?.title} has already been added to this estimate. Do you wish to continue to add this selection?`)}
            isOpen={!!duplicateSelectionItem}
            icon="duplicate"
            onConfirm={onDuplicateItemConfirm}
            onCancel={onDuplicateItemCancel}
          />
          <ConfirmationModal
            message={t(changeConfirmedArrangementMessage)}
            isOpen={isConfirmationWarningModalOpen}
            icon="warning"
            onConfirm={onClickConfirmationWarningModalConfirm}
            onCancel={onClickConfirmationWarningModalCancel}
          />
        </div>
      </div>
    </div>
  </MuiThemeProvider>
);

ArrangementEstimateScreen.propTypes = {
  bereavement: bereavementType.isRequired,
  arrangement: arrangementType.isRequired,
  availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  duplicateSelectionItem: serviceType,
  hasADiscountBeenApplied: PropTypes.func.isRequired,
  isConfirmationWarningModalOpen: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClickConfirmationWarningModalConfirm: PropTypes.func.isRequired,
  onClickConfirmationWarningModalCancel: PropTypes.func.isRequired,
  onDuplicateItemConfirm: PropTypes.func.isRequired,
  onDuplicateItemCancel: PropTypes.func.isRequired,
  onHideDiscountChange: PropTypes.func.isRequired,
  onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
};

export default ArrangementEstimateScreen;
