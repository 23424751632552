import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import overrideTheme from 'services/themes/overrideTheme';
import IconDown from '@material-ui/icons/GetApp';

import ScreenLayout from 'components/common/ScreenLayout';
import DatePicker from 'components/common/DatePicker';
import ImportFromCSVButton from 'components/export/ImportFromCSVButton';
import ImportFromCSVModal from 'components/export/ImportFromCSVModal';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const ExportScreen = ({
  casesStartDate,
  casesEndDate,
  accountCreatedDate,
  accountClosedDate,
  accountsPaymentsFromDate,
  accountsPaymentsToDate,
  primaryContactStartDate,
  primaryContactEndDate,
  onCasesStartDateChange,
  onCasesEndDateChange,
  onAccountFiltersChange,
  onAccountsPaymentsFiltersChange,
  onPrimaryContactFiltersChange,
  onClickExportCases,
  onClickExportProducts,
  onClickExportServices,
  onClickExportAccounts,
  onClickExportAccountsPayments,
  onClickExportPrimaryContacts,
  isExportCasesLoading,
  isExportProductsLoading,
  isExportServicesLoading,
  isExportAccountLoading,
  isExportAccountsPaymentsLoading,
  isExportPrimaryContactLoading,
  accountsCsvUriId,
  accountsPaymentsCsvUriId,
  primaryContactsCsvUriId,
  isModalOpen,
  onToggleModal,
}) => {
  const content = (
    <ScreenLayout
      title={t('Import/Export')}
      dashboardBannerProps={{
        breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/import-export', title: t('Export') }],
        description: t('Import and export CSV files for funerals, products and services'),
      }}
    >
      <div className={styles.o_view}>
        <div className={styles.o_view__main}>
          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Funerals')}</h3>
            </div>
            <p className={styles.u_push__bottom}>{t('Export a specified range of funerals to CSV')}</p>

            <div className={rcStyles.input_layout}>
              <DatePicker
                value={casesStartDate || null}
                label={t('From date created')}
                onChange={date => onCasesStartDateChange(date)}
                disableFuture
                isClearable
                data-test-id="casesStartDate"
              />
              <DatePicker
                value={casesEndDate || null}
                label={t('To date created')}
                onChange={date => onCasesEndDateChange(date)}
                disableFuture
                isClearable
                data-test-id="casesEndDate"

              />
              <span className={rcStyles.is_button}>
                <Button
                  onClick={onClickExportCases}
                  disabled={!casesStartDate || !casesEndDate}
                  color="primary"
                  variant="contained"
                  data-test-id="exportCases"
                >
                  {isExportCasesLoading ? (
                    <div className={styles.c_svg_loader_sml}>
                      <IconLoadSml />
                    </div>
                  ) : (
                    <Fragment>
                      <IconDown />
                      {t('Export to CSV')}
                    </Fragment>
                  )}
                </Button>
              </span>
            </div>
          </div>

          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Accounts')}</h3>
            </div>
            <div className={rcStyles.input_layout}>
              <p className={rcStyles.is_label}>
                {t('Export a specified range of accounts to CSV.')}
              </p>

              <DatePicker
                value={accountCreatedDate}
                label={t('From date created')}
                onChange={date => onAccountFiltersChange('accountCreatedDate', date)}
                disableFuture
                isClearable
                data-test-id="accountsStartDate"
              />
              <DatePicker
                value={accountClosedDate}
                label={t('From date closed')}
                onChange={date => onAccountFiltersChange('accountClosedDate', date)}
                disableFuture
                isClearable
                data-test-id="accountsEndDate"
              />
              <span className={rcStyles.is_button}>
                <Button
                  onClick={onClickExportAccounts}
                  disabled={!accountClosedDate || !accountCreatedDate}
                  color="primary"
                  variant="contained"
                  data-test-id="exportAccounts"
                  data-test-accounts-csv-uri-id={accountsCsvUriId}
                >
                  {isExportAccountLoading ? (
                    <div className={styles.c_svg_loader_sml}>
                      <IconLoadSml />
                    </div>
                  ) : (
                    <Fragment>
                      <IconDown />
                      {t('Export to CSV')}
                    </Fragment>
                  )}
                </Button>
              </span>
            </div>
            <div className={rcStyles.input_layout}>
              <p className={rcStyles.is_label}>
                {t('Export a specified range of payments to CSV.')}
              </p>
              <DatePicker
                value={accountsPaymentsFromDate}
                label={t('From date')}
                onChange={date => onAccountsPaymentsFiltersChange('accountsPaymentsFromDate', date)}
                disableFuture
                isClearable
                data-test-id="accountsPaymentsFromDate"
              />
              <DatePicker
                value={accountsPaymentsToDate}
                label={t('To date')}
                onChange={date => onAccountsPaymentsFiltersChange('accountsPaymentsToDate', date)}
                disableFuture
                isClearable
                data-test-id="accountsPaymentsToDate"
              />
              <span className={rcStyles.is_button}>
                <Button
                  onClick={onClickExportAccountsPayments}
                  disabled={!accountsPaymentsToDate || !accountsPaymentsFromDate}
                  color="primary"
                  variant="contained"
                  data-test-id="exportAccountsPayments"
                  data-test-accounts-csv-uri-id={accountsPaymentsCsvUriId}
                >
                  {isExportAccountsPaymentsLoading ? (
                    <div className={styles.c_svg_loader_sml}>
                      <IconLoadSml />
                    </div>
                  ) : (
                    <Fragment>
                      <IconDown />
                      {t('Export to CSV')}
                    </Fragment>
                  )}
                </Button>
              </span>
            </div>
          </div>

          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Products')}</h3>
            </div>
            <p className={styles.u_push__bottom}>
              {t('Export all products to CSV (coffins, flowers, urns).')}
            </p>

            <div className={classNames(
              styles.c_k_tile__action_bar,
              styles.is_clean,
            )}
            >
              <Button
                onClick={onClickExportProducts}
                color="primary"
                variant="contained"
                data-test-id="exportProducts"
              >
                {isExportProductsLoading ? (
                  <div className={styles.c_svg_loader_sml}>
                    <IconLoadSml />
                  </div>
                ) : (
                  <Fragment>
                    <IconDown />
                    {t('Export to CSV')}
                  </Fragment>
                )}
              </Button>
            </div>
          </div>

          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Services')}</h3>
            </div>
            <p className={styles.u_push__bottom}>
              {t('Export all services to CSV (crematoria, service venues, cemeteries, vehicles, officiants, disbursements, professional services & other services).')}
            </p>
            <div className={classNames(
              styles.c_k_tile__action_bar,
              styles.is_clean,
            )}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onClickExportServices}
                data-test-id="exportServices"
              >
                {isExportServicesLoading ? (
                  <div className={styles.c_svg_loader_sml}>
                    <IconLoadSml />
                  </div>
                ) : (
                  <Fragment>
                    <IconDown />
                    {t('Export to CSV')}
                  </Fragment>
                )}
              </Button>
            </div>
          </div>

          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Primary Contacts')}</h3>
            </div>
            <p className={styles.u_push__bottom}>{t('Export a specified range of primary contacts to CSV.')}</p>
            <div className={rcStyles.input_layout}>
              <DatePicker
                value={primaryContactStartDate || null}
                label={t('From date funeral created')}
                onChange={date => onPrimaryContactFiltersChange('primaryContactStartDate', date)}
                disableFuture
                isClearable
                data-test-id="primaryContactsStartDate"
              />
              <DatePicker
                value={primaryContactEndDate || null}
                label={t('To date funeral created')}
                onChange={date => onPrimaryContactFiltersChange('primaryContactEndDate', date)}
                disableFuture
                isClearable
                data-test-id="primaryContactsEndDate"
              />
              <span className={rcStyles.is_button}>
                <Button
                  onClick={onClickExportPrimaryContacts}
                  disabled={!primaryContactStartDate || !primaryContactEndDate}
                  color="primary"
                  variant="contained"
                  data-test-id="exportPrimaryContacts"
                  data-test-primary-contacts-csv-uri-id={primaryContactsCsvUriId}
                >
                  {isExportPrimaryContactLoading ? (
                    <div className={styles.c_svg_loader_sml}>
                      <IconLoadSml />
                    </div>
                  ) : (
                    <Fragment>
                      <IconDown />
                      {t('Export to CSV')}
                    </Fragment>
                  )}
                </Button>
              </span>
            </div>
          </div>

          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.long_primer}>{t('Import')}</h3>
            </div>
            <p className={styles.u_push__bottom}>
              {t('Import brochure items (products and services).')}
            </p>

            <div className={classNames(
              styles.c_k_tile__action_bar,
              styles.is_clean,
            )}
            >
              <ImportFromCSVButton
                onClick={() => onToggleModal('importFromCSV')}
                data-test-id="importFromCSVButton"
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen.importFromCSV && (
        <ImportFromCSVModal
          isOpen={isModalOpen.importFromCSV}
          onClose={() => onToggleModal('importFromCSV')}
        />
      )}
    </ScreenLayout>
  );

  return (
    <MuiThemeProvider theme={overrideTheme}>
      {content}
    </MuiThemeProvider>
  );
};

ExportScreen.propTypes = {
  casesStartDate: PropTypes.string,
  casesEndDate: PropTypes.string,
  accountCreatedDate: PropTypes.string,
  accountClosedDate: PropTypes.string,
  accountsPaymentsFromDate: PropTypes.string,
  accountsPaymentsToDate: PropTypes.string,
  primaryContactStartDate: PropTypes.string,
  primaryContactEndDate: PropTypes.string,
  onAccountFiltersChange: PropTypes.func.isRequired,
  onAccountsPaymentsFiltersChange: PropTypes.func.isRequired,
  onPrimaryContactFiltersChange: PropTypes.func.isRequired,
  onCasesStartDateChange: PropTypes.func.isRequired,
  onCasesEndDateChange: PropTypes.func.isRequired,
  onClickExportCases: PropTypes.func.isRequired,
  onClickExportProducts: PropTypes.func.isRequired,
  onClickExportServices: PropTypes.func.isRequired,
  onClickExportAccounts: PropTypes.func.isRequired,
  onClickExportAccountsPayments: PropTypes.func.isRequired,
  onClickExportPrimaryContacts: PropTypes.func.isRequired,
  isExportCasesLoading: PropTypes.bool.isRequired,
  isExportProductsLoading: PropTypes.bool.isRequired,
  isExportServicesLoading: PropTypes.bool.isRequired,
  isExportAccountLoading: PropTypes.bool.isRequired,
  isExportAccountsPaymentsLoading: PropTypes.bool.isRequired,
  isExportPrimaryContactLoading: PropTypes.bool.isRequired,
  accountsCsvUriId: PropTypes.string,
  accountsPaymentsCsvUriId: PropTypes.string,
  primaryContactsCsvUriId: PropTypes.string,
  isModalOpen: PropTypes.objectOf(PropTypes.any).isRequired,
  onToggleModal: PropTypes.func.isRequired,
};

export default ExportScreen;
