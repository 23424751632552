import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import { AccountConsumer } from 'contexts/account';
import { invoiceSectionType } from 'types/account';
import AddCustomItemModal from 'components/account/AddCustomItemModal';
import InvoiceSection from 'components/account/InvoiceSection';
import Price from 'components/common/Price';
import { moneyType } from 'types/common';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const InvoiceBuilder = ({
  sections,
  total,
  onEditSectionItem,
  onSelectSection,
  onSelectItem,
  isCustomItemModalOpen,
  toggleCustomItemModal,
  toggleAddCustomItemMenu,
  addCustomItemAnchorElement,
  onAddCustomCharge,
}) => (
  <AccountConsumer>
    {({ hasXeroIntegration }) => (
      <Fragment>
        <div className={classNames(
          styles.c_page__full_height,
          styles.has_faded_background,
          styles.u_push__top_double,
        )}
        >
          <div className={styles.o_view}>
            <div className={classNames(
              styles.o_view__main,
              styles.o_block_content,
            )}
            >
              <div className={rcStyles.add_button}>
                <Button
                  onClick={event => toggleAddCustomItemMenu(event.target)}
                  color="primary"
                  variant="outlined"
                  data-test-id="add"
                >
                  <IconAdd />
                  {t('Add')}
                </Button>
              </div>
              <Menu
                anchorEl={addCustomItemAnchorElement}
                open={!!addCustomItemAnchorElement}
                onClose={() => toggleAddCustomItemMenu()}
              >
                <MenuItem
                  onClick={(() => {
                    toggleCustomItemModal();
                    toggleAddCustomItemMenu();
                  })}
                  data-test-id="addCustomCharge"
                >
                  {t('Custom charge')}
                </MenuItem>
              </Menu>
              {sections.map(section => (
                <div key={section.title}>
                  <InvoiceSection
                    section={section}
                    onEditSectionItem={onEditSectionItem}
                    onSelectSection={onSelectSection}
                    onSelectItem={onSelectItem}
                  />
                </div>
              ))}
              <div className={rcStyles.price_total}>
                {t('Selected items total: ')}
                <Price
                  price={total}
                  customClass={classNames(
                    rcStyles.is_price,
                    styles.c_price_label,
                  )}
                  dataTestId="selectedItemsTotal"
                />
              </div>
            </div>
          </div>
          <div>
            <AddCustomItemModal
              isOpen={isCustomItemModalOpen}
              hasXeroIntegration={hasXeroIntegration}
              onSave={onAddCustomCharge}
              onClose={toggleCustomItemModal}
            />
          </div>
        </div>
      </Fragment>
    )}
  </AccountConsumer>
);

InvoiceBuilder.propTypes = {
  sections: PropTypes.arrayOf(invoiceSectionType).isRequired,
  total: moneyType.isRequired,
  addCustomItemAnchorElement: PropTypes.objectOf(PropTypes.any),
  onEditSectionItem: PropTypes.func.isRequired,
  onSelectSection: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isCustomItemModalOpen: PropTypes.bool.isRequired,
  toggleCustomItemModal: PropTypes.func.isRequired,
  toggleAddCustomItemMenu: PropTypes.func.isRequired,
  onAddCustomCharge: PropTypes.func.isRequired,
};

export default InvoiceBuilder;
