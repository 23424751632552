import React from 'react';
import { Link } from 'react-router-dom';

import Styled from './index.styled';
import { Props } from './index.types';

const CatalogueCategoryCard: React.FC<Props> = ({ category, url }: Props) => (
  <Link to={`${url}/catalogue/${category.path}`}>
    <Styled.Card imagePath={category.imagePath}>
      <div className="card_image" />
      <h3 className="card_title">{category.name}</h3>
    </Styled.Card>
  </Link>
);

export default CatalogueCategoryCard;
