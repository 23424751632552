import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import ChartistGraph from 'react-chartist';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import PersonIcon from '@material-ui/icons/Person';

import ArrangementViewingsModal from 'components/arrangement/ArrangementViewingsModal';
import AppointmentModal from 'components/bereavement/AppointmentModal';
import AssignCaseModal from 'components/bereavement/AssignCaseModal';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { caseStatuses } from 'constants/bereavement';
import { mediaVariants } from 'constants/media';
import IconLoad from 'icons/IconLoad';
import {
  buildNameString,
  buildAddressString,
  getUriForMediaByVariant,
  getStartAndEndDates,
} from 'services/utils';
import { getArrangementViewingsCount } from 'services/utils/arrangement';
import { getConfirmedArrangementFromBereavement } from 'services/utils/bereavement';
import { bereavementType, homeType } from 'types/bereavement';
import { staffMemberType } from 'types/staffMember';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import chartStyles from './chartStyles.scss?raw';

const CaseSummaryTile = ({
  bereavement,
  chartData,
  selectedHome,
  selectedOwner,
  isLoading,
  isModalOpen,
  onStatusChange,
  toggleAppointmentModal,
  onAppointmentSave,
  onHomeChange,
  onOwnerChange,
  onViewingsOpen,
  onViewingsClose,
  onArrangementModalOpen,
  onAssignCaseModalOpen,
  onAssignCaseCancel,
  onAssignCaseSave,
  onCreateAndEditViewings,
  toggleNoArrangementsForViewingsModal,
}) => {
  const {
    bereavedPeopleConnections,
    deceasedPeople,
    appointment,
    owner,
  } = bereavement;
  const primaryContact = bereavedPeopleConnections.find(connection => connection.isPrimaryContact);
  const clientsQty = bereavedPeopleConnections.filter(connection => connection.id !== primaryContact.id).length;
  const [deceasedPerson] = deceasedPeople;
  const {
    name,
    image,
    dateOfBirth,
    deathDateTime,
    possessions,
  } = deceasedPerson;
  const possessionsQty = possessions ? possessions.length : 0;
  const arrangement = getConfirmedArrangementFromBereavement(bereavement, true);
  const viewingsCount = getArrangementViewingsCount(arrangement);

  const chartOptions = {
    width: '190px',
    height: '190px',
    donutWidth: 80,
    donut: true,
  };

  return (
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <div className={classNames(
          styles.o_block_content,
          rcStyles.lead_block,
        )}
        >
          <div className={rcStyles.lead_block__deceased}>
            <Link
              to={`/case/${bereavement.id}/deceased/details`}
              className={rcStyles.base_button_slim}
            >
              <Avatar
                className={rcStyles.avatar}
              >
                {image && image.uri ? (
                  <img
                    alt={t('Deceased photo')}
                    src={getUriForMediaByVariant(image, mediaVariants.FIT_200_X_200)}
                  />
                ) : <PersonIcon />}
              </Avatar>
              <div className={styles.u_soft__bottom_double}>
                <h3 className={rcStyles.is_label}>{t('Deceased')}</h3>
                <span className={chartStyles.blank} />
                <h4 className={classNames(
                  styles.pica,
                  styles.is_primary,
                )}
                >
                  {buildNameString(name, false)}
                </h4>
                {deathDateTime && dateOfBirth && (
                  <div className={rcStyles.is_note}>
                    {getStartAndEndDates(dateOfBirth, deathDateTime, 'DD MMM YYYY')}
                  </div>
                )
                }
              </div>
            </Link>
            <Button
              onClick={onStatusChange}
              color="primary"
              variant="contained"
              data-test-id="caseStatusButton"
              className={
                bereavement.caseStatus === caseStatuses.OPEN ? rcStyles.button_case__open : rcStyles.button_case__closed
              }
            >
              {`${t('STATUS: ')}${bereavement.caseStatus}`}
            </Button>
          </div>
          <div className={rcStyles.lead_block__info_grid}>
            <div>
              <Link
                to={`/case/${bereavement.id}/bereaved`}
                className={rcStyles.base_button}
              >
                <h3 className={rcStyles.is_label}>{t('Clients')}</h3>
                <div className={rcStyles.is_main_data}>
                  {`${buildNameString(primaryContact.bereavedPerson.name, false)}${clientsQty > 0 ? `, ${clientsQty} more` : ''}`}
                </div>
                <div className={rcStyles.is_note}>
                  {primaryContact.bereavedPerson.address && buildAddressString(primaryContact.bereavedPerson.address)}
                </div>
              </Link>
            </div>
            <div>
              <ButtonBase
                onClick={onAssignCaseModalOpen}
                data-test-id="arrangerCard"
                className={rcStyles.base_button}
              >
                <h3 className={rcStyles.is_label}>{t('Arranger')}</h3>
                <div className={rcStyles.is_main_data}>{buildNameString(owner.name, false)}</div>
                <div className={rcStyles.is_note}>{buildAddressString(bereavement.home.address)}</div>
              </ButtonBase>
            </div>
            <div>
              <ButtonBase
                onClick={toggleAppointmentModal}
                data-test-id="appointmentCard"
                className={rcStyles.base_button}
              >
                <div>
                  <h3 className={rcStyles.is_label}>{t('Appointment')}</h3>
                  <div className={rcStyles.is_main_data}>{appointment ? moment(appointment.dateTime).format('DD MMM YYYY HH:mm') : 'No appointment booked'}</div>
                  <div className={rcStyles.is_note}>{appointment && buildAddressString(appointment.address)}</div>
                </div>
              </ButtonBase>
            </div>
            <div>
              <ButtonBase
                onClick={onViewingsOpen}
                data-test-id="viewingsCard"
                className={rcStyles.base_button}
              >
                <div>
                  <h3 className={rcStyles.is_label}>{t('Viewings')}</h3>
                  <div className={rcStyles.is_main_data}>{`${viewingsCount} scheduled`}</div>
                </div>
              </ButtonBase>
            </div>
            <div>
              <Link
                to={`/case/${bereavement.id}/deceased/possessions`}
                className={rcStyles.base_button}
              >
                <h3 className={rcStyles.is_label}>{t('Possessions')}</h3>
                <div className={rcStyles.is_main_data}>
                  {possessionsQty > 0 ? `${possessions[0].description}${possessionsQty > 1 ? `, ${possessionsQty - 1} more` : ''}` : 'No possessions'}
                </div>
              </Link>
            </div>
            <div className={rcStyles.tasks_donut}>
              {isLoading ? (
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad />
                </div>
              ) : (
                <Fragment>
                  <Link
                    to={`/tasks?bereavementId=${bereavement.id}`}
                    className={classNames(rcStyles.tasks_donut, rcStyles.base_chart_button)}
                  >
                    <div className={rcStyles.graph_container}>
                      <h3 className={rcStyles.is_chart_label}>Tasks </h3>
                      <ChartistGraph
                        data={chartData}
                        options={chartOptions}
                        type="Pie"
                        className={rcStyles.graphy}
                      />
                    </div>
                    <div className={rcStyles.tasks_donut__key}>
                      <div className={classNames(
                        rcStyles.key_item,
                        rcStyles.is_pending,
                      )}
                      >
                        <span className={rcStyles.is_count}>{chartData.series[0].value}</span>
                        {` ${t('Pending')}`}
                      </div>
                      <div className={classNames(
                        rcStyles.key_item,
                        rcStyles.is_completed,
                      )}
                      >
                        <span className={rcStyles.is_count}>{chartData.series[1].value}</span>
                        {` ${t('Completed')}`}
                      </div>
                    </div>
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <AppointmentModal
        appointment={bereavement.appointment}
        home={bereavement.home}
        isOpen={isModalOpen.appointment}
        onClose={toggleAppointmentModal}
        onSave={onAppointmentSave}
      />
      <AssignCaseModal
        title="Assign Case"
        home={selectedHome}
        owner={selectedOwner}
        isOpen={isModalOpen.assignCase}
        onHomeChange={onHomeChange}
        onOwnerChange={onOwnerChange}
        onClose={onAssignCaseCancel}
        onSave={onAssignCaseSave}
      />
      {isModalOpen.viewings && (
        <ArrangementViewingsModal
          arrangements={bereavement.arrangements}
          isOpen={isModalOpen.viewings}
          onClose={onViewingsClose}
          onCreateAndEditViewings={onCreateAndEditViewings}
        />
      )}
      <ConfirmationModal
        message={t('To schedule a viewing first create an arrangement')}
        confirmLabel={t('Create arrangement')}
        isOpen={isModalOpen.noArrangementsForViewings}
        icon="warning"
        onConfirm={() => {
          toggleNoArrangementsForViewingsModal();
          onArrangementModalOpen();
        }}
        onCancel={toggleNoArrangementsForViewingsModal}
        testIds={{ confirm: 'create-arrangement' }}
      />
    </div>
  );
};

CaseSummaryTile.propTypes = {
  bereavement: bereavementType.isRequired,
  chartData: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  selectedHome: homeType,
  selectedOwner: staffMemberType,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  onStatusChange: PropTypes.func.isRequired,
  toggleAppointmentModal: PropTypes.func.isRequired,
  onAppointmentSave: PropTypes.func.isRequired,
  onHomeChange: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired,
  onViewingsOpen: PropTypes.func.isRequired,
  onViewingsClose: PropTypes.func.isRequired,
  onAssignCaseModalOpen: PropTypes.func.isRequired,
  onAssignCaseCancel: PropTypes.func.isRequired,
  onAssignCaseSave: PropTypes.func.isRequired,
  onCreateAndEditViewings: PropTypes.func.isRequired,
  onArrangementModalOpen: PropTypes.func.isRequired,
  toggleNoArrangementsForViewingsModal: PropTypes.func.isRequired,
};

export default CaseSummaryTile;
