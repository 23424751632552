import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import CaseCard from 'components/common/cards/CaseCard';
import ScreenLayout from 'components/common/ScreenLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import { statusesFilters, ownersFilters, sortCasesFilters } from 'constants/bereavement';
import Select from 'components/common/Select';
import SortMenu from 'components/common/SortMenu';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const CaseListScreen = ({
  bereavements,
  homes,
  hasMoreBereavements,
  isLoading,
  filters,
  getMoreBereavements,
  onFiltersChange,
}) => (
  <ScreenLayout
    title={t('Funerals')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/case', title: t('Funerals') }],
      description: t('View all of your funerals.'),
    }}
  >
    <div className={classNames(
      styles.o_view,
      styles.has_white_background,
    )}
    >
      <div className={styles.o_view__main}>
        <Toolbar className={rcStyles.toolbar}>
          <Select
            value={filters.caseStatus}
            onChange={event => onFiltersChange('caseStatus', event.target.value)}
            label={t('Status')}
            inputProps={{ 'data-test-id': 'caseStatus' }}
          >
            {statusesFilters.map(status => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filters.organisationalUnitId}
            onChange={event => onFiltersChange('organisationalUnitId', event.target.value)}
            label={t('Home')}
            inputProps={{ 'data-test-id': 'homeFilter' }}
          >
            {homes.map(home => (
              <MenuItem key={home.id} value={home.id}>
                {home.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filters.ownerId}
            onChange={event => onFiltersChange('ownerId', event.target.value)}
            label={t('Owner')}
            inputProps={{ 'data-test-id': 'ownerFilter' }}
          >
            {ownersFilters.map(owner => (
              <MenuItem key={owner.id} value={owner.id}>
                {owner.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            className={styles.u_fullWidth}
            value={filters.searchTerm}
            onChange={event => onFiltersChange('searchTerm', event.target.value)}
            label={t('Search')}
            type="search"
            InputProps={{
              'data-test-id': 'searchTerm',
              'startAdornment': (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      </div>
    </div>
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <span className={styles.u_text__right}>
          <SortMenu
            options={sortCasesFilters}
            selectedValue={filters.sortOrders && filters.sortOrders[0]}
            testIds={{
              button: 'sortCasesButton',
              menu: 'sortCasesMenu',
              menuItem: 'sortCasesFilter',
            }}
            onChangeFilters={filter => onFiltersChange('sortOrders', [filter])}
          />
        </span>
        {!isLoading && bereavements.length === 0 ? (
          <div className={styles.o_block_content}>
            <div className={styles.long_primer}>
              {t('No bereavements to show')}
            </div>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={bereavements.length}
            hasMore={bereavements.length > 0 && hasMoreBereavements}
            next={getMoreBereavements}
            className={styles.u_overflow__hidden}
          >

            <div className={rcStyles.funeral_list}>
              {bereavements.map(bereavementItem => (
                <CaseCard
                  bereavementData={bereavementItem}
                  key={bereavementItem.id}
                />
              ))}
            </div>
            {isLoading && (
              <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                <IconLoad />
              </div>
            )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  </ScreenLayout>
);

CaseListScreen.propTypes = {
  bereavements: PropTypes.arrayOf(bereavementType),
  homes: PropTypes.arrayOf(organisationalUnitType),
  hasMoreBereavements: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  getMoreBereavements: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default CaseListScreen;
