import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import OfflineScreen from 'screens/OfflineScreen';
import WebSocketMessageErrorScreen from 'screens/WebSocketMessageErrorScreen';
import Login from 'screens/LoginScreen';
import { dispatchRequestAction } from 'actions/request';
import { setShouldUpdateAction, setCurrentEnvironmentAction } from 'actions/user';
import routes from 'constants/routes';
import { locationType, historyType } from 'types/reactRouter';
import MainScreen from './MainScreen';

class MainScreenContainer extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired),
    isOnline: PropTypes.bool.isRequired,
    shouldUpdate: PropTypes.bool.isRequired,
    updatingMessage: PropTypes.string,
    isDispatching: PropTypes.bool.isRequired,
    requests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    location: locationType.isRequired,
    history: historyType.isRequired,
    dispatchRequests: PropTypes.func.isRequired,
    setShouldUpdate: PropTypes.func.isRequired,
    setCurrentEnvironment: PropTypes.func.isRequired,
    processingOfRequestInQueueFailed: PropTypes.bool.isRequired,
  }

  static getDerivedStateFromProps(nextProps) {
    const { accessToken } = nextProps;
    return {
      isLoggedIn: !!accessToken,
    };
  }

  static getRoutes(isLoggedIn = false, scopes, availableFeatures) {
    if (!isLoggedIn) {
      return routes.map((route) => {
        if (route.path === '/') {
          return { ...route, title: 'Login' };
        }

        return route;
      });
    }

    if (process.env.FEATURE_FLAG_AVAILABLE_ROUTES) {
      const availableRoutes = process.env.FEATURE_FLAG_AVAILABLE_ROUTES.split(',');
      return routes.filter(route => availableRoutes.includes(route.path));
    }

    return routes.filter((route) => {
      let hasFeatureFlags = true;
      let hasScopes = true;
      if (route.requiredScopes) {
        hasScopes = route.requiredScopes.every(scope => scopes.includes(scope));
      }
      if (route.requiredFeatureFlags) {
        hasFeatureFlags = route.requiredFeatureFlags.every(featureFlag => availableFeatures.includes(featureFlag));
      }

      return hasScopes && hasFeatureFlags;
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const {
      isOnline,
      requests,
      dispatchRequests,
      setShouldUpdate,
      setCurrentEnvironment,
      history,
    } = this.props;
    setCurrentEnvironment(process.env.CURRENT_ENVIRONMENT);
    if (isOnline && requests.length > 0) {
      dispatchRequests();
    }
    this.unlisten = history.listen(() => {
      const { shouldUpdate } = this.props;
      if (shouldUpdate) {
        OfflinePluginRuntime.update();
        setShouldUpdate(false);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const {
      scopes,
      availableFeatures,
      isOnline,
      isDispatching,
      updatingMessage,
      location,
      processingOfRequestInQueueFailed,
    } = this.props;
    const { isLoggedIn } = this.state;
    const currentLocation = (location && location.pathname) ? location.pathname : '/';

    const isPortalLocation = currentLocation.includes('/portal/');
    if (isPortalLocation) {
      return null;
    }

    const routeList = MainScreenContainer.getRoutes(isLoggedIn, scopes, availableFeatures);
    const currentRoute = routeList.find(route => !!matchPath(currentLocation, {
      path: route.path,
      exact: true,
      strict: true,
    }));

    const isCurrentRoutePublic = currentRoute && currentRoute.isPublic;
    if (!isLoggedIn && !isCurrentRoutePublic) {
      return <Login redirect={currentLocation} />;
    }

    if (!isOnline && !process.env.FEATURE_FLAG_OFFLINE_FUNCTIONALITY) {
      return <OfflineScreen />;
    }

    if (processingOfRequestInQueueFailed && currentLocation !== '/logout') {
      return <WebSocketMessageErrorScreen />;
    }

    return (
      <MainScreen
        routes={routeList}
        isLoggedIn={isLoggedIn}
        updatingMessage={updatingMessage}
        isOnline={isOnline}
        isDispatching={isDispatching}
        currentLocation={currentLocation}
      />
    );
  }
}

const mapStateToProps = state => ({
  accessToken: state.userStore.user.accessToken,
  scopes: state.userStore.user.policy && state.userStore.user.policy.scopes,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  isOnline: state.userStore.isOnline,
  shouldUpdate: state.userStore.shouldUpdate,
  updatingMessage: state.userStore.updatingMessage,
  isDispatching: state.requestQueueStore.isDispatching,
  requests: state.requestQueueStore.requests,
  processingOfRequestInQueueFailed: state.requestQueueStore.processingOfRequestInQueueFailed,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  dispatchRequests: dispatchRequestAction,
  setShouldUpdate: setShouldUpdateAction,
  setCurrentEnvironment: setCurrentEnvironmentAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainScreenContainer));
