import React from 'react';
import ostyle from './offline.scss';

const IconSync = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.93 191.49">
    <g className={ostyle.loader}>
      <path className={ostyle.spinner} d="M171.27,67V82.05a2.18,2.18,0,0,1-2.49,2.47h-30a4.24,4.24,0,0,1-2.91-1,2.8,2.8,0,0,1-.85-3.21,5.45,5.45,0,0,1,1.43-2.23L144.56,70c.51-.51.51-.53-.11-.89A31.49,31.49,0,0,0,97,97.45c0,.87,0,.87-.85.87H82.34c-.9,0-1,0-1-1a50.19,50.19,0,0,1,.5-7.94A45.85,45.85,0,0,1,89,70.54a46.68,46.68,0,0,1,27.21-19.76,45.82,45.82,0,0,1,15-1.54,46.86,46.86,0,0,1,24.42,8.46c.92.65.72.64,1.49-.11l8.11-8a4.4,4.4,0,0,1,2.4-1.38,3.26,3.26,0,0,1,3.67,3.44c0,3.9,0,7.8,0,11.7Z" />
      <path className={ostyle.spinner} d="M102.21,107c5,0,10,0,15.06,0a3.92,3.92,0,0,1,2.94,1.16,2.73,2.73,0,0,1,.74,2.72,5.42,5.42,0,0,1-1.5,2.52l-8.08,8.06c-.52.52-.52.54.1.9a31.53,31.53,0,0,0,47.05-22.2,34.91,34.91,0,0,0,.38-6.29c0-.69,0-.73.7-.73h14.18c.69,0,.78.09.78.79.14,12-3.46,22.68-11.28,31.82a46.28,46.28,0,0,1-28.21,15.93,45.23,45.23,0,0,1-10.26.54,47,47,0,0,1-24.59-8.55c-.77-.55-.65-.52-1.27.09l-8.09,7.91a7.67,7.67,0,0,1-1.11.94,3,3,0,0,1-3.21.34A3.43,3.43,0,0,1,84.66,140c0-.16,0-.32,0-.49q0-15.06,0-30.12a4.91,4.91,0,0,1,0-.77A2,2,0,0,1,86.51,107c.62,0,1.23,0,1.85,0Z" />
    </g>
    <path className={ostyle.offline_cloud} d="M216,191l-173.94.47a42.09,42.09,0,0,1-10.64-82.81,104.1,104.1,0,0,1-.84-12.85,95.67,95.67,0,0,1,185-34.44A65.05,65.05,0,0,1,275.76,131C273.82,157.89,249,191,216,191ZM42.05,130.27a19.13,19.13,0,0,0,0,38.26h172.2l2.14-.46a42.09,42.09,0,0,0-6-83.72H207.9l-9.19.53L196.26,76a72.78,72.78,0,0,0-140,39.79l4.89,16.38L44,130.27Z" />
  </svg>
);

export default IconSync;
