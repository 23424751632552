import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import styles from './singleValueStyles.scss';
// import en from './translations/en';

class SingleValueView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
    };
  }

  render = () => {
    const { chartData } = this.state;

    return (
      <div className={styles.singleValue}>
        <div className={styles.is_value}>{chartData.value}</div>
        <div className={styles.is_label}>{t(chartData.label)}</div>
      </div>
    );
  }
}

SingleValueView.propTypes = {
  chartData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SingleValueView;
