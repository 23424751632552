import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ChartistView from './Chartist';
import TableView from './Table';
import SingleValueView from './SingleValue';
import en from './translations/en';

class Chart extends Component {
  constructor(props) {
    super(props);

    i18next.addResources('en', 'translation', en.translation);

    this.state = {
      chartData: props.chartData,
      chartType: props.chartType,
      axis: props.chartData.axis,
      reportInfo: props.reportInfo,
    };
  }

  factoryChart = (chartType) => {
    switch (chartType) {
      case 'Bar':
        return this.bar();
      case 'Column':
        return this.column();
      case 'Pie':
        return this.pie();
      case 'Donut':
        return this.donut();
      case 'DonutMultiple':
        return this.donutMultiple();
      case 'Table':
        return this.table();
      case 'Single':
        return this.singleValue();
      default:
        return this.table();
    }
  }

  renderNoData = () => (
    <div>No data</div>
  )

  bar = () => {
    const { chartData, axis, reportInfo } = this.state;

    if (chartData.series.length === 0) {
      return this.renderNoData();
    }

    const chartistView = new ChartistView({
      chartData,
      axis,
      reportInfo,
    });

    return chartistView.renderBar();
  }

  column = () => {
    const { chartData, axis } = this.state;

    if (chartData.series.length === 0) {
      return this.renderNoData();
    }

    const chartistView = new ChartistView({
      chartData,
      axis,
    });

    return chartistView.renderColumn();
  }

  pie = () => {
    const { chartData, axis } = this.state;

    if (chartData.series.length === 0) {
      return this.renderNoData();
    }

    const chartistView = new ChartistView({
      chartData,
      axis,
    });

    return chartistView.renderPie();
  }

  donut = () => {
    const { chartData, axis } = this.state;

    if (chartData.series.length === 0) {
      return this.renderNoData();
    }

    const chartistView = new ChartistView({
      chartData,
      axis,
    });

    return chartistView.renderDonut();
  }

  donutMultiple = () => {
    const { chartData, axis } = this.state;

    if (chartData.length === 0) {
      return this.renderNoData();
    }

    const chartistView = new ChartistView({
      chartData,
      axis,
    });

    return chartistView.renderDonutMultiple();
  }

  table = () => {
    const { chartData } = this.state;

    // return null;
    if (chartData.values.length === 0) {
      return this.renderNoData();
    }

    return (
      <TableView
        chartData={chartData}
      />
    );
  }

  singleValue = () => {
    const { chartData } = this.state;

    if (chartData.value === undefined || chartData.value === null) {
      return this.renderNoData();
    }

    return (
      <SingleValueView
        chartData={chartData}
      />
    );
  }

  render() {
    const { chartType } = this.state;
    return this.factoryChart(chartType);
  }
}

Chart.propTypes = {
  chartData: PropTypes.objectOf(PropTypes.object).isRequired,
  chartType: PropTypes.objectOf(PropTypes.string).isRequired,
  reportInfo: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Chart;
