import { t } from 'i18next';
import moment from 'moment';
import {
  categories,
  committalTypes,
  requiredCategoriesForBurial,
  requiredCategoriesForCremation,
  additionalRequiredCategoriesForPackage,
  routesForCategory,
  serviceCategories,
} from 'constants/arrangement';
import { variantStatuses } from 'constants/catalogue';
import { generateHexId, isNullOrUndefined, getSelectionAggregate } from 'services/utils';
import { vehicleTypes } from 'constants/service';
import { categoriesDefinedInPackage } from './package';

export const getIsFamilyArranged = (arrangement, category) => {
  if (
    category === categories.FLOWERS
    && arrangement.categoryInformation
    && arrangement.categoryInformation.flowers
    && arrangement.categoryInformation.flowers.isFamilyArranged
  ) {
    return true;
  }
  if (
    category === categories.OFFICIANTS
    && arrangement.categoryInformation
    && arrangement.categoryInformation.officiant
    && arrangement.categoryInformation.officiant.isFamilyArranged
  ) {
    return true;
  }
  return false;
};

export const hasCategorySelectedItems = (arrangement, type, category) => (
  !!arrangement[`${type.toLowerCase()}Selections`]
  && arrangement[`${type.toLowerCase()}Selections`].some(selection => (
    selection[`${type.toLowerCase()}`].category === category
  ))
);

export const getUniqueKeyForSelection = (selection) => {
  if (selection.placeholder) {
    return `placeholder-${selection.placeholder.category}`;
  }
  const aggregate = getSelectionAggregate(selection);
  return `${aggregate.id}-${selection.id}`;
};

export const filterSelectionsOptions = {
  ALL: 'ALL',
  PACKAGE: 'PACKAGE',
  NON_PACKAGE: 'NON_PACKAGE',
  DISPLAY_AS_DISBURSEMENTS: 'DISPLAY_AS_DISBURSEMENTS',
  PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
  NON_DISPLAY_AS_DISBURSEMENTS: 'NON_DISPLAY_AS_DISBURSEMENTS',
  NON_PROFESSIONAL_SERVICES: 'NON_PROFESSIONAL_SERVICES',
};

const filterSelectionsBySingleFilter = (selections, filter) => selections.filter((selection) => {
  const selectionAggregate = getSelectionAggregate(selection);

  switch (filter) {
    case filterSelectionsOptions.PACKAGE:
      return selection.isPackageSelection;
    case filterSelectionsOptions.NON_PACKAGE:
      return !selection.isPackageSelection;
    case filterSelectionsOptions.DISPLAY_AS_DISBURSEMENTS:
      return (selectionAggregate.displayAsDisbursementToBereaved
        || selectionAggregate.category === categories.DISBURSEMENTS);
    case filterSelectionsOptions.PROFESSIONAL_SERVICES:
      return selectionAggregate.category === categories.PROFESSIONAL_SERVICES;
    case filterSelectionsOptions.NON_DISPLAY_AS_DISBURSEMENTS:
      return !(selectionAggregate.displayAsDisbursementToBereaved
        || selectionAggregate.category === categories.DISBURSEMENTS);
    case filterSelectionsOptions.NON_PROFESSIONAL_SERVICES:
      return selectionAggregate.category !== categories.PROFESSIONAL_SERVICES;
    default:
      return true;
  }
});

const filterSelectionsByMultiFilter = (selections, filters) => filters.reduce(
  (accumulator, filter) => filterSelectionsBySingleFilter(accumulator, filter),
  selections,
);

export const filterSelectionsOnArrangement = (
  arrangement, filters,
) => {
  const productSelections = arrangement.productSelections || [];
  const serviceSelections = arrangement.serviceSelections || [];

  return [
    ...filterSelectionsByMultiFilter(productSelections, filters),
    ...filterSelectionsByMultiFilter(serviceSelections, filters),
  ];
};

export const requiredCategoriesForArrangement = (arrangement) => {
  if (arrangement.committalType === committalTypes.BURIAL) {
    return requiredCategoriesForBurial;
  }
  if (arrangement.committalType === committalTypes.CREMATION) {
    return requiredCategoriesForCremation;
  }
  return [];
};

export const makePlaceholdersForCategories = categoriesArray => categoriesArray.map(category => ({
  placeholder: {
    category,
  },
}));

export const getPlaceholderSelectionsForArrangement = (arrangement, filter) => {
  const requiredCategories = requiredCategoriesForArrangement(arrangement);
  const selectedPackage = (arrangement.packageSelection)
    ? arrangement.packageSelection.package : null;
  const packageCategories = categoriesDefinedInPackage(selectedPackage);
  const requiredPackageCategories = packageCategories.filter(
    category => [
      ...requiredCategories,
      ...additionalRequiredCategoriesForPackage,
    ].includes(category),
  );
  const requiredNonPackageCategories = requiredCategories.filter(
    category => !requiredPackageCategories.includes(category),
  );
  const allSelections = filterSelectionsOnArrangement(arrangement, [filterSelectionsOptions.ALL]);
  const foundCategories = allSelections.reduce((accumulator, selection) => {
    const selectionAggregate = getSelectionAggregate(selection);
    if (accumulator.includes(selectionAggregate.category)) {
      return accumulator;
    }
    return [...accumulator, selectionAggregate.category];
  }, []);
  const missingPackageCategories = requiredPackageCategories.filter(
    category => !foundCategories.includes(category),
  );
  const missingNonPackageCategories = requiredNonPackageCategories.filter(
    category => !foundCategories.includes(category),
  );
  if (filter === filterSelectionsOptions.NON_PACKAGE) {
    return makePlaceholdersForCategories(missingNonPackageCategories);
  }
  if (filter === filterSelectionsOptions.PACKAGE) {
    return makePlaceholdersForCategories(missingPackageCategories);
  }
  return makePlaceholdersForCategories([
    ...missingPackageCategories,
    ...missingNonPackageCategories,
  ]);
};

export const getArrangementSelectionsSortedByEstimateOrder = (arrangement, selections) => {
  const orderedSelections = [];
  let unorderedSelections = [];

  if (arrangement.orderOfEstimateSelections) {
    for (let i = 0; i < arrangement.orderOfEstimateSelections.length; i += 1) {
      const estimateOrderSelection = selections.find(
        selection => selection.id === arrangement.orderOfEstimateSelections[i],
      );

      if (estimateOrderSelection) {
        orderedSelections.push(estimateOrderSelection);
      }
    }

    const orderedSelectionIds = orderedSelections.map(selection => selection.id);

    unorderedSelections = selections.filter(
      selection => orderedSelectionIds.indexOf(selection.id) === -1,
    );
  } else {
    unorderedSelections = selections;
  }

  return [
    ...orderedSelections,
    ...unorderedSelections,
  ];
};

export const getOriginalPriceForSelection = (selection) => {
  if (selection.salePriceAtConfirmation) {
    return selection.salePriceAtConfirmation;
  }
  const selectionAggregate = getSelectionAggregate(selection);
  return selectionAggregate.variants.find(
    variant => variant.id === selection.variantId,
  ).prices.sale;
};

export const getVariantTitleForDisplay = (selection) => {
  const selectionAggregate = getSelectionAggregate(selection);
  const numberOfVariants = selectionAggregate.variants
    ?.filter(variant => variant.status === variantStatuses.ACTIVE).length ?? 0;
  if (numberOfVariants < 2) {
    return null;
  }
  return selectionAggregate.variants.find(variant => variant.id === selection.variantId).name;
};

const notSelectedTextsForCategory = {
  [categories.SERVICE_VENUES]: 'Service venue not selected',
  [categories.CEMETERIES]: 'Cemetery not selected',
  [categories.RECEPTION_VENUES]: 'Reception venue not selected',
  [categories.VEHICLES]: 'Vehicle not selected',
  [categories.COFFINS]: 'Coffin not selected',
  [categories.OFFICIANTS]: 'Officiant not selected',
  [categories.FLOWERS]: 'Flowers not selected',
  [categories.URNS]: 'Urn not selected',
  [categories.CREMATORIA]: 'Crematorium not selected',
  [categories.MEMORIALISATION]: 'Memorialisations not selected',
  [categories.OTHER]: 'Other service not selected',
};

const arrangedByFamilyTextsForCategory = {
  [categories.SERVICE_VENUES]: 'Service venue is family arranged',
  [categories.CEMETERIES]: 'Cemetery is family arranged',
  [categories.RECEPTION_VENUES]: 'Reception venue is family arranged',
  [categories.VEHICLES]: 'Vehicles are family arranged',
  [categories.COFFINS]: 'Coffin is family arranged',
  [categories.OFFICIANTS]: 'Officiant is family arranged',
  [categories.FLOWERS]: 'Flowers are family arranged',
  [categories.URNS]: 'Urn is family arranged',
  [categories.CREMATORIA]: 'Crematorium is family arranged',
  [categories.MEMORIALISATION]: 'Memorialisations are family arranged',
};

export const getPlaceholderTextForCategory = (category, isFamilyArranged) => {
  const dictionary = isFamilyArranged
    ? arrangedByFamilyTextsForCategory : notSelectedTextsForCategory;
  return t(dictionary[category]);
};

export const getCategoryDisplayText = (category) => {
  switch (category) {
    case categories.SERVICE_VENUES:
      return t('Service venue');
    case categories.CEMETERIES:
      return t('Cemetery');
    case categories.RECEPTION_VENUES:
      return t('Reception venue');
    case categories.VEHICLES:
      return t('Vehicle');
    case categories.COFFINS:
      return t('Coffin');
    case categories.OFFICIANTS:
      return t('Officiant');
    case categories.FLOWERS:
      return t('Flower');
    case categories.MEMORIALISATION:
      return t('Memorialisations');
    case categories.CREMATORIA:
      return t('Crematorium');
    case categories.OTHER:
      return t('Other');
    case categories.DISBURSEMENTS:
      return t('Disbursement');
    case categories.PROFESSIONAL_SERVICES:
      return t('Professional service');
    default:
      return category;
  }
};

export const getOfficiantVenueDisplayText = (category) => {
  switch (category) {
    case categories.SERVICE_VENUES:
      return t('Service venue');
    case categories.CEMETERIES:
      return t('Burial');
    case categories.CREMATORIA:
      return t('Crematorium');
    case categories.RECEPTION_VENUES:
      return t('Reception venue');
    default:
      return null;
  }
};

export const getCategoryForRoute = route => (
  Object.keys(routesForCategory).find(key => routesForCategory[key] === route)
);

export const duplicateVehicleJourney = (vehicle, vehicleType) => {
  const isHearse = (vehicleType && vehicleType === vehicleTypes.HEARSE) || false;
  const duplicate = {
    ...vehicle,
    id: generateHexId(),
    isHearse,
    journeys: vehicle.journeys.map(({ id, ...otherJourneyProps }) => ({
      ...otherJourneyProps,
      id: generateHexId(),
    })),
  };

  if (isHearse && !isNullOrUndefined(duplicate.noOfPassengers)) {
    delete duplicate.noOfPassengers;
  }
  if (!isHearse && vehicle.isHearse) {
    duplicate.noOfPassengers = 4;
  }

  return duplicate;
};

export const checkArrangementsHaveViewings = (arrangements = []) => !!arrangements.find(
  ({ categoryInformation }) => !!categoryInformation?.care?.viewings?.length,
);

export const sortArrangementsByLastEdited = (arrangements = []) => (
  arrangements.sort((a, b) => {
    const aLastEdited = moment(a.timeUpdated || a.timeCreated);
    const bLastEdited = moment(b.timeUpdated || b.timeCreated);

    return bLastEdited - aLastEdited;
  })
);

export const getArrangementViewingsCount = arrangement => (
  arrangement?.categoryInformation?.care?.viewings?.length || 0
);

export const isDuplicateCategorySelection = (item, arrangement, type, category) => {
  const key = type.toLowerCase();
  const { id } = item;
  const { productSelections, serviceSelections } = arrangement;

  const matchByCategoryAndId = categoryItem => categoryItem?.category === category && categoryItem?.id === id;
  const categorySelections = type === 'PRODUCT' ? productSelections : serviceSelections;

  return categorySelections && !!categorySelections
    .map(categorySelection => categorySelection[key])
    .find(matchByCategoryAndId);
};

export const getSuggestedArrangementVenueDates = (arrangement) => {
  const { categoryInformation } = arrangement;

  const {
    cemetery,
    crematorium,
    receptionVenue,
    serviceVenue,
  } = categoryInformation || {};

  const venueDates = [];

  if (cemetery?.startDateTime) {
    venueDates.push({
      key: serviceCategories.CEMETERIES,
      label: t('Cemetery'),
      value: cemetery.startDateTime,
    });
  }
  if (crematorium?.startDateTime) {
    venueDates.push({
      key: serviceCategories.CREMATORIA,
      label: t('Crematorium'),
      value: crematorium.startDateTime,
    });
  }
  if (receptionVenue?.startDateTime) {
    venueDates.push({
      key: serviceCategories.RECEPTION_VENUES,
      label: t('Reception venue'),
      value: receptionVenue.startDateTime,
    });
  }
  if (serviceVenue?.startDateTime) {
    venueDates.push({
      key: serviceCategories.SERVICE_VENUES,
      label: t('Service venue'),
      value: serviceVenue.startDateTime,
    });
  }

  return venueDates;
};

export const isSelectionAProfessionalService = selection => (selection
  && !!selection.service
  && selection.service.category === serviceCategories.PROFESSIONAL_SERVICES
);

export const isSelectionADisbursement = (selection) => {
  if (!selection) {
    return false;
  }
  const displayAsDisbursementToBereaved = !!(selection?.service?.displayAsDisbursementToBereaved
    || selection?.product?.displayAsDisbursementToBereaved);
  const hasDisbursementCategory = !!(selection?.service?.category === serviceCategories.DISBURSEMENTS);
  return displayAsDisbursementToBereaved || hasDisbursementCategory;
};

export const filterForInPackageArrangementSelections = (selections) => {
  if (!selections) {
    return null;
  }
  return selections.filter((selection) => {
    const { isPackageSelection } = selection;
    const isProfessionalService = isSelectionAProfessionalService(selection);
    const isDisbursement = isSelectionADisbursement(selection);

    return isPackageSelection
      && !isProfessionalService
      && !isDisbursement;
  });
};

export const filterForAdditionalPackageArrangementSelections = (selections) => {
  if (!selections) {
    return null;
  }
  return selections.filter((selection) => {
    const { isPackageSelection } = selection;
    const isProfessionalService = isSelectionAProfessionalService(selection);
    const isDisbursement = isSelectionADisbursement(selection);

    return !isPackageSelection
      && !isProfessionalService
      && !isDisbursement;
  });
};

export const filterForProfessionalServiceArrangementSelections = (selections) => {
  if (!selections) {
    return null;
  }
  return selections.filter((selection) => {
    const isProfessionalService = isSelectionAProfessionalService(selection);
    const isDisbursement = isSelectionADisbursement(selection);

    return isProfessionalService
      && !isDisbursement;
  });
};

export const filterForDisbursementArrangementSelections = (selections) => {
  if (!selections) {
    return null;
  }
  return selections.filter(selection => isSelectionADisbursement(selection));
};

export const getServiceVenueCategoryInformationByCategory = (categoryInformation, category) => {
  switch (category) {
    case serviceCategories.SERVICE_VENUES:
      return categoryInformation?.serviceVenue;
    case serviceCategories.CREMATORIA:
      return categoryInformation?.crematorium;
    case serviceCategories.CEMETERIES:
      return categoryInformation?.cemetery;
    default:
      return null;
  }
};
