import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import InvoiceSectionItem from 'components/account/InvoiceSectionItem';
import { invoiceSectionType } from 'types/account';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const InvoiceSection = ({
  section,
  disabled,
  onEditSectionItem,
  onSelectSection,
  onSelectItem,
}) => (

  <div
    className={rcStyles.lists_group}
    data-test-id="sectionContainer"
  >
    <Checkbox
      checked={section.items.length === section.items.filter(item => item.isSelected).length}
      onChange={event => onSelectSection(section.title, event.target.checked)}
      inputProps={{
        'data-test-id': 'selectSectionCheckbox',
      }}
      color="primary"
    />
    <div className={rcStyles.list_container}>
      <h3
        className={styles.long_primer}
        data-test-id="sectionTitle"
      >
        {section.title}
      </h3>
      {section.items.length > 0 && (
        <ul
          className={rcStyles.list}
          data-test-id="itemList"
        >
          {section.items.map((item, index) => (
            <li
              key={`${item.title}${index}`}
              data-testid="item"
              className={rcStyles.list__item}
            >
              <InvoiceSectionItem
                item={item}
                index={index}
                sectionName={section.title}
                disabled={disabled}
                onSelectItem={onSelectItem}
                onEditSectionItem={onEditSectionItem}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

InvoiceSection.propTypes = {
  section: invoiceSectionType.isRequired,
  disabled: PropTypes.bool,
  onEditSectionItem: PropTypes.func.isRequired,
  onSelectSection: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

export default InvoiceSection;
