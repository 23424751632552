import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { nameType } from 'types/common';
import { buildAddressString, buildNameString } from 'services/utils';
import styles from './styles.scss';

const CalendarEventContentCortege = ({
  start,
  caseOwner,
  locations,
  deceasedName,
  bereavedName,
  noOfPassengers,
  isHearse,
}) => (
  <Fragment>
    <div className={styles.rbc_dialog__body}>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Journey date')}
        </div>
        <div className={styles.is_value}>
          {moment(start).format('DD MMM YY')}
        </div>
      </div>
      {caseOwner && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>{t('Funeral owner')}</div>
          <div className={styles.is_value}>
            {buildNameString(caseOwner.name)}
          </div>
        </div>
      )}
      {deceasedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Deceased name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(deceasedName)}
          </div>
        </div>
      )}
      {bereavedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Client name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(bereavedName)}
          </div>
        </div>
      )}
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Pickup time')}
        </div>
        <div className={styles.is_value}>
          {moment(start).format('HH:mm')}
        </div>
      </div>
      {!isHearse && noOfPassengers && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Passengers')}
          </div>
          <div className={styles.is_value}>
            {noOfPassengers}
          </div>
        </div>
      )}
      {locations && locations.map(location => (
        <div
          key={buildAddressString(location.address)}
          className={classNames(
            styles.is_label,
            styles.rbc_dialog__item__wide,
          )}
        >
          <div className={styles.is_label}>
            {t(`Route ${location.type.toLowerCase()}`)}
          </div>
          <div className={styles.is_value}>
            {buildAddressString(location.address)}
          </div>
        </div>
      ))}
    </div>
  </Fragment>
);

CalendarEventContentCortege.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  deceasedName: nameType.isRequired,
  bereavedName: nameType.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  caseOwner: PropTypes.objectOf(PropTypes.any).isRequired,
  noOfPassengers: PropTypes.number.isRequired,
  isHearse: PropTypes.bool.isRequired,
};

export default CalendarEventContentCortege;
