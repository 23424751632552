import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import Price from 'components/common/Price';
import ArrangementSelectedItemAvatar from 'components/arrangement/ArrangementSelectedItemAvatar';
import { productType, serviceType, packageType } from 'types/bereavement';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementSelectedPackage = ({
  item,
  onRemove,
  disabled,
  selectedItemRef,
}) => (
  <div
    className={rcStyles.package_selection}
    ref={selectedItemRef}
  >
    <div className={rcStyles.package_selection__body}>
      <div className={rcStyles.is_icon}>
        <ArrangementSelectedItemAvatar
          image={item.image}
          title={item.name}
          category="PACKAGES"
        />
      </div>
      <div className={rcStyles.is_name}>
        {item.name}
      </div>
      <Price price={item.price} customClass={styles.c_price_label} />
    </div>
    <Button
      size="small"
      variant="outlined"
      color="primary"
      onClick={onRemove}
      data-test-id="remove"
      classes={{ root: styles.o_button__round }}
      disabled={disabled}
    >
      <RemoveIcon />
    </Button>
  </div>
);

ArrangementSelectedPackage.propTypes = {
  item: PropTypes.oneOfType([productType, serviceType, packageType]).isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedItemRef: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementSelectedPackage;
