import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getCatalogueItemsAction, getNextCatalogueItemsAction, resetCatalogueItemsAction,
} from 'actions/portal';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import {
  CatalogueCategory,
  CatalogueItemType,
  ProductCategory,
  ServiceCategory,
  CatalogueItemOrder,
  Product,
  Service,
} from 'types/ts/catalogue';
import { RootState } from 'types/ts/state';

import { Props, State } from './index.types';
import PortalCatalogueCategoryScreen from './PortalCatalogueCategoryScreen';

class PortalCatalogueCategoryScreenContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { location } = props;
    const { state } = location;

    this.state = {
      selectedItem: state?.item ? state.item : null,
      showDetails: false,
    };
  }

  componentDidMount() {
    const { resetCatalogueItems } = this.props;
    resetCatalogueItems();
    this.getCatalogueItems();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      location, match, catalogueItems, getNextCatalogueItems,
    } = this.props;
    const { state } = location;
    const { selectedItem } = prevState;

    const itemId = match?.params?.itemId;
    if (!itemId && !selectedItem && catalogueItems?.items?.length) {
      const item = catalogueItems.items[0];
      this.redirectToCatalogueItem(item);
      return;
    }

    const { item } = state || {};
    if (item && item?.id !== selectedItem?.id) {
      this.setSelectedItem(item);
      return;
    }

    const hasNextPage = catalogueItems?.pagination?.hasNextPage;
    const loadedItem = catalogueItems.items.find(ci => ci.id === itemId);
    const lastItem = catalogueItems.items.slice(catalogueItems.items.length - 1)[0];
    const isLastItem = lastItem && lastItem.id === itemId;
    const isLoading = catalogueItems?.isLoading;

    if ((isLastItem || !loadedItem) && hasNextPage && !isLoading) {
      getNextCatalogueItems();
      return;
    }

    if (loadedItem && selectedItem?.id !== loadedItem.id) {
      this.setSelectedItem(loadedItem);
    }
  }

  setSelectedItem = (item: Product|Service) => {
    this.setState({
      selectedItem: item,
      showDetails: !!item,
    });
  }

  getCategoryPathFromRouteMatch = (props: Props) => {
    const { match } = props;
    return match?.params?.category;
  };

  getCategoryFromRouteMatch = (): CatalogueCategory => {
    const { tenant, match } = this.props;
    const { category: categoryPath } = match?.params;
    const {
      cataloguePdfCategoryDisplayPreferences: displayPreferences,
    } = tenant || {};

    return getTenantCatalogueCategories(displayPreferences)
      .filter((item: CatalogueCategory) => item.visible)
      .find((item: CatalogueCategory) => item.path === categoryPath);
  }

  getCatalogueItems = (sortOrder?: CatalogueItemOrder) => {
    const { match, getCatalogueItems } = this.props;
    const { params: { homeId } } = match;
    const routeCategory = this.getCategoryFromRouteMatch();
    const type = routeCategory.type as CatalogueItemType;
    const isProductType = type === CatalogueItemType.PRODUCT;
    const categoryType = isProductType
      ? routeCategory.category as ProductCategory
      : routeCategory.category as ServiceCategory;

    getCatalogueItems(type, categoryType, homeId, sortOrder);
  }

  handleGetCatalogueItems = () => {
    this.getCatalogueItems();
  }

  handleSelectCatalogueItem = (item: Product|Service) => {
    const { match } = this.props;
    const itemId = match?.params?.itemId;
    if (item?.id !== itemId) {
      this.redirectToCatalogueItem(item);
    } else {
      this.setState({ showDetails: !!item });
    }
  }

  redirectToCatalogueItem = (item?: Product|Service) => {
    if (item) {
      const { history, match } = this.props;
      const { params: { homeId } } = match;
      const category = this.getCategoryFromRouteMatch();
      history.push(`/portal/${homeId}/catalogue/${category?.path}/${item.id}`, { item });
    }
  }

  handleHideDetails = () => {
    this.setState({ showDetails: false });
  }

  render() {
    const {
      catalogueItems, home, tenant, match, history,
    } = this.props;
    const { selectedItem, showDetails } = this.state;

    const {
      bereavedPortalStyles, cataloguePdfCategoryDisplayPreferences: displayPreferences,
    } = tenant || {};
    const { bereavedPortalLogo } = bereavedPortalStyles || {};

    const category = this.getCategoryFromRouteMatch();
    if (!category || !category.visible) {
      const { params: { homeId } } = match;
      history.push(`/portal/${homeId}`);
      return null;
    }

    return (
      <PortalCatalogueCategoryScreen
        category={category}
        catalogueItems={catalogueItems}
        logoUri={bereavedPortalLogo?.uri}
        displayPreferences={displayPreferences}
        home={home}
        selectedItem={selectedItem}
        showDetails={showDetails}
        getCatalogueItems={this.handleGetCatalogueItems}
        onHideDetails={this.handleHideDetails}
        onSelectCatalogueItem={this.handleSelectCatalogueItem}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tenant: state?.portalStore?.tenant,
  home: state?.portalStore?.home,
  catalogueItems: state?.portalStore?.catalogueItems,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCatalogueItems: getCatalogueItemsAction,
  getNextCatalogueItems: getNextCatalogueItemsAction,
  resetCatalogueItems: resetCatalogueItemsAction,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortalCatalogueCategoryScreenContainer),
);
