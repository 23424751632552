import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SupportSection from 'components/support/SupportSection';
import OfflineIndicator from 'components/common/OfflineIndicator';
import NotFoundScreen from 'screens/NotFoundScreen';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const MainScreen = ({
  routes,
  isLoggedIn,
  updatingMessage,
  isOnline,
  isDispatching,
  currentLocation,
}) => (
  <Fragment>
    {updatingMessage && (
      <div className={rcStyles.updating_backdrop}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad />
        </div>
        <div className={classNames(
          rcStyles.updating_message,
          styles.long_primer,
          styles.is_primary,
          styles.is_light,
        )}
        >
          {updatingMessage}
        </div>
      </div>
    )}
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={!route.hasSubRoutes}
          path={route.path}
          render={props => (
            <Fragment>
              <Helmet>
                <title>{t(route.title || process.env.APP_TITLE)}</title>
                <meta
                  name="description"
                  content={t(route.description || 'Arranger provides everything you need to manage funeral arrangements, on one online easy-to-use platform')}
                />
                <style type="text/css">
                  {`
                    @font-face {
                      font-family: "Tondo";
                      src: url("/fonts/tondo/Tondo_W_Rg.woff2") format("woff2"),
                        url("/fonts/tondo/Tondo_W_Rg.woff") format("woff"),
                        url("/fonts/tondo/Tondo_W_Rg.eot") format("opentype");
                      font-display: auto;
                      font-style: normal;
                      font-weight: 400;
                    }

                    @font-face {
                      font-family: "Tondo";
                      src: url("/fonts/tondo/Tondo_W_Bd.woff2") format("woff2"),
                        url("/fonts/tondo/Tondo_W_Bd.woff") format("woff"),
                        url("/fonts/tondo/Tondo_W_Bd.eot") format("opentype");
                      font-display: auto;
                      font-style: normal;
                      font-weight: 700;
                    }

                    @font-face {
                      font-family: "Tondo";
                      src: url("/fonts/tondo/Tondo_W_Lt.woff2") format("woff2"),
                        url("/fonts/tondo/Tondo_W_Lt.woff") format("woff"),
                        url("/fonts/tondo/Tondo_W_Lt.eot") format("opentype");
                      font-display: auto;
                      font-style: normal;
                      font-weight: 300;
                    }

                    body,
                    #root {
                      -webkit-font-smoothing: antialiased;
                      /*  nasty fix to override a mui 'feature' that adds 15px padding to
                        *  the body when opening a select list
                        *  forcing this rule to override it
                        */
                      padding-right: 0 !important;
                      margin: 0;
                      padding: 0;
                      font-family: 'Tondo', sans-serif;
                    }

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                      margin: 0;
                    }

                    hr {
                      border: 0;
                      height: 1px;
                      background: hsla(269, 21%, 95%, 1);
                      margin: 2rem 0;
                    }

                    ul {
                      list-style: none;
                      margin: 0;
                      padding: 0;
                    }

                    a {
                      text-decoration: none;
                      outline: none;
                    }
                  `}
                </style>
              </Helmet>
              {(!isOnline || isDispatching) && <OfflineIndicator isSyncing={isDispatching} />}
              {isLoggedIn && process.env.FEATURE_FLAG_SUPPORT_PANEL && <SupportSection location={currentLocation} />}
              <route.component
                {...props}
                redirect={currentLocation}
                parent={route.parent}
                title={route.title}
              />
            </Fragment>
          )}
        />
      ))}
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

MainScreen.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  updatingMessage: PropTypes.string,
  isOnline: PropTypes.bool.isRequired,
  isDispatching: PropTypes.bool.isRequired,
  currentLocation: PropTypes.string.isRequired,
};

export default MainScreen;
