import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ContactSupport = ({
  switchWindow,
  toggleSlideInWindow,
}) => {
  const phoneNumber = '01392 793080';
  const email = 'support@arranger.com';
  const hoursnote = 'Excluding bank holidays';

  return (
    <div className={classNames(
      styles.js_page,
      styles.c_page__full_height,
      styles.has_white_background,
    )}
    >
      <div className={rcStyles.nav}>
        <IconButton
          className={styles.o_button__white}
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => { switchWindow(); }}
          data-test-id="switchWindow"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          className={styles.o_button__white}
          color="secondary"
          size="small"
          variant="contained"
          aria-label="Close"
          onClick={toggleSlideInWindow}
          data-test-id="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={rcStyles.body}>
        <h2 className={classNames(
          styles.long_primer,
          styles.u_push__bottom,
        )}
        >
          {t('Contact Details')}
        </h2>
        <div className={rcStyles.contact_item}>
          <PhoneIcon className={rcStyles.contact_item__icon} />
          <a
            className={rcStyles.contact_item__label}
            href={`tel:${phoneNumber.replace(/\s/g, '')}`}
          >
            {phoneNumber}
          </a>
        </div>
        <div className={rcStyles.contact_item}>
          <EmailIcon className={rcStyles.contact_item__icon} />
          <a
            className={rcStyles.contact_item__label}
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </div>
        <p className={styles.brevier}>
          <b>{t('Opening hours')}</b>
          <br />
          {t('Monday to Thursday 8:30am - 5:30pm')}
          <br />
          {t('Friday 8:30am - 5:00pm')}
          <br />
          {hoursnote}
        </p>
      </div>
    </div>
  );
};

ContactSupport.propTypes = {
  switchWindow: PropTypes.func,
  toggleSlideInWindow: PropTypes.func,
};

export default ContactSupport;
