import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { defaultMoneyObject, isNullOrUndefined } from 'services/utils';
import CurrencyField from 'components/common/CurrencyField';
import Price from 'components/common/Price';
import { arrangementType } from 'types/bereavement';
import { discountTypes } from 'constants/arrangement';
import classNames from 'classnames';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const EstimateTotals = ({
  arrangement,
  disabled,
  onSetGlobalDiscount,
  onSetRequestedDeposit,
}) => (
  <Fragment>
    <h2 className={styles.long_primer}>
      {t('Totals')}
    </h2>
    <div className={rcStyles.estimate_totals}>
      {process.env.FEATURE_FLAG_ESTIMATE_DISBURSEMENTS && (
        <div className={rcStyles.estimate_totals__line}>
          <span className={rcStyles.estimate_totals__label}>
            {t('Total disbursements: ')}
          </span>
          <Price
            price={arrangement.calculation.totalDisbursements}
            customClass={styles.c_price_label}
          />
        </div>
      )}
      {(arrangement.discountType !== discountTypes.NONE) && (
        <Fragment>
          <div className={rcStyles.estimate_totals__line}>
            <span className={rcStyles.estimate_totals__label}>
              {t('Total before discount: ')}
            </span>
            <Price
              price={arrangement.calculation.totalWithoutDiscounts}
              customClass={styles.c_price_label}
            />
          </div>
          <div className={rcStyles.estimate_totals__line}>
            <span className={rcStyles.estimate_totals__label}>
              {t('Amount of discount: ')}
            </span>
            {(arrangement.discountType === discountTypes.ITEMISED) && (
              <Price
                price={arrangement.calculation.discounts}
                customClass={styles.c_price_label}
              />
            )}
            {(arrangement.discountType === discountTypes.GLOBAL) && (
              <CurrencyField
                classes={{
                  root:
                    classNames(styles.c_price_label),
                }}
                value={(arrangement.globalDiscount && arrangement.globalDiscount.amount) || null}
                min={0}
                nullable
                InputProps={{
                  inputProps: {
                    'data-test-id': 'globalDiscount',
                  },
                }}
                onChange={(value) => {
                  onSetGlobalDiscount(defaultMoneyObject(value));
                }}
                disabled={disabled}
              />
            )}
          </div>
        </Fragment>
      )}
      <div className={rcStyles.estimate_totals__line}>
        <span className={rcStyles.estimate_totals__label}>
          {t('Total estimate: ')}
        </span>
        <Price
          price={arrangement.calculation.finalTotal}
          customClass={styles.c_price_label}
        />
      </div>
      <div className={rcStyles.estimate_totals__line}>
        <span className={rcStyles.estimate_totals__label}>
          {t('Deposit required: ')}
        </span>
        <CurrencyField
          classes={{
            root:
              classNames(styles.c_price_label),
          }}
          value={(arrangement.requestedDeposit && arrangement.requestedDeposit.amount) || null}
          min={0}
          nullable
          InputProps={{
            inputProps: {
              'data-test-id': 'requestedDeposit',
            },
          }}
          onChange={(value) => {
            onSetRequestedDeposit(!isNullOrUndefined(value) ? defaultMoneyObject(value) : null);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  </Fragment>
);

EstimateTotals.propTypes = {
  arrangement: arrangementType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSetGlobalDiscount: PropTypes.func.isRequired,
  onSetRequestedDeposit: PropTypes.func.isRequired,
};

export default EstimateTotals;
