import React, { useState } from 'react';

import Styled from './index.styled';
import { Props } from './index.types';

const ExpandableText: React.FC<Props> = ({ text, clamp }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) {
    return null;
  }

  if (text?.length <= clamp) {
    return <p>{text}</p>;
  }

  const ExpansionToggle = () => (
    <Styled.ExpansionLink
      onClick={() => setIsExpanded(!isExpanded)}
      data-test-id="expansionToggle"
    >
      {isExpanded ? 'Show Less' : 'Read more' }
    </Styled.ExpansionLink>
  );

  const reducedText = text.slice(0, clamp);

  return (
    <p>
      {isExpanded ? text : reducedText}
      {!isExpanded && <Styled.Ellipsis>...</Styled.Ellipsis>}
      <ExpansionToggle />
    </p>
  );
};

export default ExpandableText;
