import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import {
  clearBereavementsAction,
  fetchBereavementsAction,
  backgroundFetchBereavementsAction,
  updateFiltersAction,
  editCaseStatusAction,
} from 'actions/bereavement';
import { fetchArrangementCatalogueItemsAction } from 'actions/catalogue';
import {
  ownersFilters,
  homesFilters,
  statusesFilters,
  BEREAVEMENTS_PER_PAGE,
} from 'constants/bereavement';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import { matchType } from 'types/reactRouter';
import LiteArrangementListScreen from './LiteArrangementListScreen';

class LiteArrangementListScreenContainer extends Component {
  debounceGetBereavements = debounce((filters) => {
    const { clearBereavements } = this.props;
    clearBereavements();
    this.setState({ bereavements: [] });
    this.getMoreBereavements(filters);
  }, 500);

  static propTypes = {
    bereavements: PropTypes.arrayOf(bereavementType.isRequired).isRequired,
    homes: PropTypes.arrayOf(organisationalUnitType.isRequired).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    isOnline: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isListLoaded: PropTypes.bool.isRequired,
    match: matchType,
    clearBereavements: PropTypes.func.isRequired,
    fetchBereavements: PropTypes.func.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    backgroundFetchBereavements: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    editCaseStatus: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.isLoading && nextProps.bereavements.length > state.bereavements.length) {
      return { bereavements: [...nextProps.bereavements] };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavements: [],
      homes: [{
        id: homesFilters[0].id,
        name: t(homesFilters[0].name),
      }, ...props.homes],
    };
  }

  componentDidMount() {
    const {
      bereavements,
      filters,
      isOnline,
      isListLoaded,
      updateFilters,
      backgroundFetchBereavements,
      fetchCatalogueItems,
    } = this.props;

    if (!filters.organisationalUnitId || !filters.ownerId) {
      updateFilters('organisationalUnitId', homesFilters[0].id);
      updateFilters('ownerId', ownersFilters[0].id);
      updateFilters('caseStatus', statusesFilters[0].id);
    }

    if (bereavements.length > 0) {
      this.getDisplayBereavements();
    }

    if (isOnline) {
      fetchCatalogueItems('products');
      fetchCatalogueItems('services');

      if (isListLoaded && bereavements.length > 0) {
        backgroundFetchBereavements(bereavements[bereavements.length - 1].id);
      } else {
        this.getMoreBereavements();
      }
    }
  }

  getDisplayBereavements = () => {
    const { bereavements, pagination } = this.props;
    const { bereavements: displayBereavements } = this.state;

    if (displayBereavements.length >= bereavements.length && pagination.hasNextPage) {
      this.getMoreBereavements();
      return;
    }

    const lastIndex = bereavements.length - displayBereavements.length < BEREAVEMENTS_PER_PAGE
      ? bereavements.length
      : displayBereavements.length + BEREAVEMENTS_PER_PAGE;

    this.setState({
      bereavements: [
        ...displayBereavements,
        ...bereavements.slice(displayBereavements.length, lastIndex),
      ],
    });
  }

  getMoreBereavements = (filters) => {
    const { fetchBereavements } = this.props;
    fetchBereavements(filters);
  }

  handleFiltersChange = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetBereavements({ ...filters, [key]: value });
  }

  handleEditCaseStatus = (bereavementId, status) => {
    const { editCaseStatus, filters } = this.props;

    this.setState(prevState => ({
      bereavements: prevState.bereavements
        .filter(bereavement => !(bereavement.id === bereavementId && filters.caseStatus !== status)),
    }));

    editCaseStatus(bereavementId, 'caseStatus', status);
  }

  render() {
    const {
      bereavements: propsBereavements,
      pagination,
      filters,
      isOnline,
      isLoading,
      match,
    } = this.props;

    const { bereavements: stateBereavements, homes } = this.state;
    const hasMoreBereavements = isLoading && propsBereavements.length > stateBereavements.length;

    return (
      <LiteArrangementListScreen
        bereavements={stateBereavements}
        homes={homes}
        hasMoreBereavements={pagination.hasNextPage || hasMoreBereavements}
        isOnline={isOnline}
        isLoading={isLoading}
        filters={filters}
        match={match}
        getMoreBereavements={this.getMoreBereavements}
        onFiltersChange={this.handleFiltersChange}
        onEditCaseStatus={this.handleEditCaseStatus}
      />
    );
  }
}

const mapStateToProps = state => ({
  isOnline: state.userStore.isOnline,
  homes: state.userStore.user.staffMember.organisationalUnitsFlattened,
  bereavements: state.bereavementStore.bereavements,
  pagination: state.bereavementStore.pagination,
  filters: state.bereavementStore.filters,
  isLoading: state.bereavementStore.isLoading,
  isListLoaded: state.bereavementStore.isListLoaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearBereavements: clearBereavementsAction,
  fetchBereavements: fetchBereavementsAction,
  fetchCatalogueItems: fetchArrangementCatalogueItemsAction,
  backgroundFetchBereavements: backgroundFetchBereavementsAction,
  updateFilters: updateFiltersAction,
  editCaseStatus: editCaseStatusAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withApollo(LiteArrangementListScreenContainer)),
);
