import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import storage from 'redux-persist/lib/storage';
import { bereavementReducer } from 'reducers/bereavement';
import { catalogueReducer } from 'reducers/catalogue';
import { directoryListingsReducer } from 'reducers/directoryListings';
import { homesReducer } from 'reducers/homes';
import { homeGroupsReducer } from 'reducers/homeGroups';
import { requestQueueReducer } from 'reducers/request';
import { userReducer } from 'reducers/user';
import { portalReducer } from 'reducers/portal';
import { snackbarReducer } from 'reducers/snackbar';
import { taskReducer } from 'reducers/task';
import { setIsOnlineAction } from 'actions/user';
import { staffMembersReducer } from 'reducers/staffMembers';
import logger from 'redux-logger';

const bereavementPersistConfig = {
  key: 'bereavement',
  storage,
};

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['updatingMessage'],
};

const portalPersistConfig = {
  key: 'portal',
  storage,
  blacklist: ['updatingMessage'],
};

const cataloguePersistConfig = {
  key: 'catalogue',
  storage,
};

const requestQueuePersistConfig = {
  key: 'requestQueue',
  storage,
};

const middleware = [logger, dynamicMiddlewares];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

export const rootReducer = combineReducers({
  bereavementStore: persistReducer(bereavementPersistConfig, bereavementReducer),
  catalogueStore: persistReducer(cataloguePersistConfig, catalogueReducer),
  directoryListingsStore: directoryListingsReducer,
  homesStore: homesReducer,
  homeGroupsStore: homeGroupsReducer,
  requestQueueStore: persistReducer(requestQueuePersistConfig, requestQueueReducer),
  snackbarStore: snackbarReducer,
  staffMembersStore: staffMembersReducer,
  taskStore: taskReducer,
  userStore: persistReducer(userPersistConfig, userReducer),
  portalStore: persistReducer(portalPersistConfig, portalReducer),
});

export const store = createStore(
  rootReducer,
  enhancer,
);

export const persistor = persistStore(store, {}, () => (
  store.dispatch(setIsOnlineAction(navigator.onLine))
));
