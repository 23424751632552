import { clearAllAction as clearAllBereavements } from 'actions/bereavement';
import { clearAllAction as clearAllCatalogueAction } from 'actions/catalogue';
import { clearRequestsAction } from 'actions/request';

export const userActions = {
  SET_USER: 'USER_SET_USER',
  REMOVE_USER: 'USER_REMOVE_USER',
  SET_IS_ONLINE: 'USER_SET_IS_ONLINE',
  SET_SHOULD_UPDATE: 'USER_SET_SHOULD_UPDATE',
  SET_UPDATING_MESSAGE: 'USER_SET_UPDATING_MESSAGE',
  SET_CURRENT_ENVIRONMENT: 'USER_SET_CURRENT_ENVIRONMENT',
  SET_XERO_CONSENT: 'USER_SET_XERO_CONSENT',
  ADD_ADDRESS_TO_LIST: 'USER_ADD_ADDRESS_TO_LIST',
  CLEAR_ADDRESS_LIST: 'USER_CLEAR_ADDRESS_LIST',
  SET_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITIONS: 'USER_SET_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITIONS',
  UPDATE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION: 'USER_UPDATE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION',
  DELETE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION: 'USER_DELETE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION',
  SET_ARRANGEMENT_CATEGORY_DISPLAY_PREFERENCES: 'USER_SET_ARRANGEMENT_CATEGORY_DISPLAY_PREFERENCES',
};

export const setUserAction = payload => ({ type: userActions.SET_USER, payload });

export const removeUserAction = () => ({ type: userActions.REMOVE_USER });

export const setIsOnlineAction = isOnline => ({
  type: userActions.SET_IS_ONLINE,
  payload: isOnline,
});

export const setShouldUpdateAction = shouldUpdate => ({
  type: userActions.SET_SHOULD_UPDATE,
  payload: shouldUpdate,
});

export const setUpdatingMessageAction = updatingMessage => ({
  type: userActions.SET_UPDATING_MESSAGE,
  payload: updatingMessage,
});

export const setCurrentEnvironmentAction = currentEnv => ({
  type: userActions.SET_CURRENT_ENVIRONMENT,
  payload: currentEnv,
});

export const setXeroConsentAction = hasGivenConsentToAXeroApp => ({
  type: userActions.SET_XERO_CONSENT,
  payload: hasGivenConsentToAXeroApp,
});

export const addAddressToListAction = address => ({
  type: userActions.ADD_ADDRESS_TO_LIST,
  payload: address,
});

export const clearAddressListAction = () => ({ type: userActions.CLEAR_ADDRESS_LIST });

export const setTenantCorrespondenceTemplateDefinitions = definitions => ({
  type: userActions.SET_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITIONS,
  payload: definitions,
});

export const updateTenantCorrespondenceTemplateDefinition = definition => ({
  type: userActions.UPDATE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION,
  payload: definition,
});

export const deleteTenantCorrespondenceTemplateDefinition = definition => ({
  type: userActions.DELETE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION,
  payload: definition,
});

export const setArrangementCategoryDisplayPreferences = preferences => ({
  type: userActions.SET_ARRANGEMENT_CATEGORY_DISPLAY_PREFERENCES,
  payload: preferences,
});

export const logoutAction = () => (dispatch) => {
  dispatch(removeUserAction());
  dispatch(clearAllBereavements());
  dispatch(clearAllCatalogueAction());
  dispatch(clearRequestsAction());
  dispatch(clearAddressListAction());
};

export const setTenantCorrespondenceTemplateDefinitionsAction = definitions => (dispatch) => {
  dispatch(setTenantCorrespondenceTemplateDefinitions(definitions));
};

export const updateTenantCorrespondenceTemplateDefinitionAction = definition => (dispatch) => {
  dispatch(updateTenantCorrespondenceTemplateDefinition(definition));
};

export const deleteTenantCorrespondenceTemplateDefinitionAction = definition => (dispatch) => {
  dispatch(deleteTenantCorrespondenceTemplateDefinition(definition));
};

export const setArrangementCategoryDisplayPreferencesAction = preferences => (dispatch) => {
  dispatch(setArrangementCategoryDisplayPreferences(preferences));
};
