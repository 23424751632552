import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ExpandableBanner = ({
  wrapperClasses,
  iconClasses,
  headerClasses,
  headingClasses,
  containerClasses,
  title,
  children,
  disable,
  isExpanded,
  toggleIsExpanded,
  hasTab,
  actionButton,
}) => (
  <div
    className={classNames(
      rcStyles.banner,
      wrapperClasses,
      isExpanded && rcStyles.is_expanded,
    )}
  >
    <div
      data-test-id={isExpanded ? 'bannerExpand' : 'bannerCollapse'}
      onClick={toggleIsExpanded}
      role="button"
      tabIndex="-1"
      className={
        isExpanded
          ? classNames(
            rcStyles.banner__toolbar,
            headerClasses,
            hasTab ? styles.no_border : '',
          )
          : classNames(
            rcStyles.banner__toolbar,
            headerClasses,
          )
      }
    >
      {!disable && (
        <div className={classNames(
          rcStyles.is_icon,
          iconClasses,
          children && rcStyles.has_no_icon,
        )}
        >
          {children && (
            <KeyboardArrowDown
              color="primary"
              style={{
                transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          )}
        </div>
      )}
      <div className={classNames(
        headingClasses,
      )}
      >
        {title}
      </div>
      {actionButton && (
        <Fragment>
          { actionButton }
        </Fragment>
      )}
    </div>
    {children && (
      <div className={classNames(
        containerClasses,
      )}
      >
        <Collapse
          in={disable ? true : isExpanded}
        >
          <div className={rcStyles.banner__body}>
            {children}
          </div>
        </Collapse>
      </div>
    )}
  </div>
);

ExpandableBanner.propTypes = {
  wrapperClasses: PropTypes.arrayOf(PropTypes.string),
  iconClasses: PropTypes.arrayOf(PropTypes.string),
  headerClasses: PropTypes.arrayOf(PropTypes.string),
  headingClasses: PropTypes.arrayOf(PropTypes.string),
  containerClasses: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disable: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  hasTab: PropTypes.bool,
  actionButton: PropTypes.objectOf(PropTypes.any),
};

export default ExpandableBanner;
