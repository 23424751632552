import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import {
  faiths,
  nationalities,
  placesOfDeath,
  reasonsForNoMCCDIssued,
} from 'constants/bereavement';
import { genders } from 'constants/general';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import IconCal from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from 'components/common/Select';
import NameFormSection from 'components/common/NameFormSection';
import AddressFormSection from 'components/common/AddressFormSection';
import DynamicList from 'components/common/DynamicList';
import NameDirectoryListingFormSection from 'components/directoryListings/NameDirectoryListingFormSection';
import DatePicker from 'components/common/DatePicker';
import DateTimePicker from 'components/common/DateTimePicker';
import { bereavementType } from 'types/bereavement';
import ExpandableFormSection from 'components/common/ExpandableFormSection';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const FirstCallDeceasedForm = ({
  bereavement,
  onDeceasedChange,
  onDeceasedTransferIntoCareChange,
  onBereavementChange,
  onCoronerChange,
  errors,
  disabled,
}) => {
  const deceased = bereavement.deceasedPeople[0];
  const transferIntoCare = deceased && deceased.transfersIntoCare && deceased.transfersIntoCare[0];
  const {
    clinicians, coronerName, coronerDirectoryId, coronerDirectoryListing,
  } = bereavement;
  const transferIntoCareErrors = errors && errors.transfersIntoCare && errors.transfersIntoCare[0];

  const TransferIntoCareDetails = (
    <div className={rcStyles.ready_collection_block}>
      <div className={rcStyles.is_transfer_from}>
        <AddressFormSection
          address={transferIntoCare && transferIntoCare.fromLocation}
          lookupLabel={t('Transfer from *')}
          onChange={location => onDeceasedTransferIntoCareChange('fromLocation', location)}
          errors={transferIntoCareErrors && transferIntoCareErrors.fromLocation}
          disabled={disabled}
          data-test-id="transferFromFormSection"
        />
      </div>
      <div className={rcStyles.is_transfer_to}>
        <AddressFormSection
          address={transferIntoCare && transferIntoCare.toLocation}
          lookupLabel={t('Transfer to *')}
          onChange={location => onDeceasedTransferIntoCareChange('toLocation', location)}
          errors={transferIntoCareErrors && transferIntoCareErrors.toLocation}
          disabled={disabled}
          data-test-id="transferToFormSection"
        />
      </div>
      <div className={rcStyles.is_start_date}>
        <DateTimePicker
          value={(transferIntoCare && transferIntoCare.startDateTime) || null}
          placeholder={t('Start date & time of transfer *')}
          label={t('Start date & time of transfer *')}
          openToDate={new Date()}
          minDate={deceased.dateOfBirth || undefined}
          onChange={date => onDeceasedTransferIntoCareChange('startDateTime', date)}
          isClearable
          errors={transferIntoCareErrors && transferIntoCareErrors.startDateTime}
          helperText={transferIntoCareErrors && transferIntoCareErrors.startDateTime}
          disabled={(disabled || !deceased.isReadyForCollection)}
          data-test-id="transferIntoCareStartDatetime"
        />
      </div>
      <div className={rcStyles.is_end_date}>
        <DateTimePicker
          value={(transferIntoCare && transferIntoCare.endDateTime) || null}
          placeholder={t('End date & time of transfer *')}
          label={t('End date & time of transfer *')}
          openToDate={new Date()}
          minDate={deceased.dateOfBirth || undefined}
          onChange={date => onDeceasedTransferIntoCareChange('endDateTime', date)}
          isClearable
          errors={transferIntoCareErrors && transferIntoCareErrors.endDateTime}
          helperText={transferIntoCareErrors && transferIntoCareErrors.endDateTime}
          disabled={(disabled || !deceased.isReadyForCollection)}
          data-test-id="transferIntoCareEndDatetime"
        />
      </div>
      <div
        className={classNames(
          rcStyles.is_2_col,
          rcStyles.is_instructions,
        )}
      >
        <TextField
          label={t('Instructions')}
          value={(transferIntoCare && transferIntoCare.instructions) || ''}
          onChange={event => onDeceasedTransferIntoCareChange('instructions', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'transferIntoCareInstructions',
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
  return (
    <div>
      <ExpandableFormSection title={t('Deceased details')}>
        <div className={classNames(
          rcStyles.v_spacing,
        )}
        >
          <div className={rcStyles.colspan2}>
            <NameFormSection
              name={deceased.name}
              labelTitle={t('Title *')}
              onChangeHandler={name => onDeceasedChange('name', name)}
              labelGivenName={t('Given Name *')}
              labelFamilyName={t('Family Name *')}
              errors={errors.name}
              disabled={disabled}
            />
          </div>
          <DateTimePicker
            value={deceased.deathDateTime || null}
            label={t('Date and Time of Death')}
            placeholder={t('Date and Time of Death')}
            minDate={deceased.dateOfBirth || undefined}
            openToDate={new Date()}
            onChange={date => onDeceasedChange('deathDateTime', date)}
            disableFuture
            isClearable
            errors={errors && !!errors.deathDateTime}
            helperText={errors && errors.deathDateTime}
            data-test-id="deathDateTime"
          />
          <TextField
            label={t('Age at Death')}
            placeholder={t('Age at Death')}
            type="number"
            value={deceased.ageAtDeath !== null ? deceased.ageAtDeath : ''}
            onChange={event => onDeceasedChange('ageAtDeath', event.target.value)}
            disabled={disabled || !!(deceased.dateOfBirth && deceased.deathDateTime)}
            inputProps={{
              'data-test-id': 'ageAtDeath',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconCal className={styles.o_icon__blue} />
                </InputAdornment>
              ),
            }}
            error={errors && !!errors.ageAtDeath}
            helperText={errors && errors.ageAtDeath}
            fullWidth
          />
          <div
            className={classNames(
              rcStyles.colspan2,
            )}
          >
            <AddressFormSection
              address={deceased.address}
              lookupLabel={t('Address')}
              onChange={address => onDeceasedChange('address', address)}
              errors={errors.address}
              disabled={disabled}
              fullWidth
              className={classNames(
                styles.u_fullWidth,
              )}
              data-test-id="addressFormSection"
            />
          </div>
        </div>

        <div className={classNames(
          rcStyles.v_spacing,
        )}
        >
          <Select
            label={t('Marital Status')}
            value={deceased.maritalStatus}
            onChange={event => onDeceasedChange('maritalStatus', event.target.value)}
            inputProps={{
              'data-test-id': 'maritalStatus',
            }}
            fullWidth
            disabled={disabled}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="SINGLE">{t('Single')}</MenuItem>
            <MenuItem value="MARRIED">{t('Married')}</MenuItem>
            <MenuItem value="CIVILPARTNERED">{t('Civil Partnership')}</MenuItem>
            <MenuItem value="DIVORCED">{t('Divorced')}</MenuItem>
            <MenuItem value="WIDOWED">{t('Widowed')}</MenuItem>
            <MenuItem value="OTHER">{t('Other')}</MenuItem>
          </Select>
          <TextField
            label={t('Previous Surname')}
            value={deceased.previousSurname || ''}
            onChange={event => onDeceasedChange('previousSurname', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'previousSurname',
            }}
            disabled={disabled}
          />
          <div
            className={classNames(
              rcStyles.colspan2,
            )}
          >
            <TextField
              label={t('Also Known As')}
              value={deceased.alsoKnownAs || ''}
              onChange={event => onDeceasedChange('alsoKnownAs', event.target.value)}
              fullWidth
              inputProps={{
                'data-test-id': 'alsoKnownAs',
              }}
              disabled={disabled}
            />
          </div>
          <DatePicker
            value={deceased.dateOfBirth || null}
            label={t('Date of Birth')}
            onChange={date => onDeceasedChange('dateOfBirth', date)}
            maxDate={deceased.deathDateTime || undefined}
            disableFuture
            isClearable
            errors={errors && !!errors.dateOfBirth}
            helperText={errors && errors.dateOfBirth}
            data-test-id="dateOfBirth"
          />
          <AutoCompleteTextField
            placeholder={t('Gender')}
            value={deceased.gender || ''}
            suggestions={genders}
            onChange={gender => onDeceasedChange('gender', gender)}
            disabled={disabled}
            data-test-id="gender"
          />
          <AutoCompleteTextField
            placeholder={t('Nationality')}
            value={deceased.nationality || ''}
            suggestions={nationalities}
            onChange={nationality => onDeceasedChange('nationality', nationality)}
            fullWidth
            data-test-id="nationality"
            disabled={disabled}
          />
          <AutoCompleteTextField
            placeholder={t('Faith')}
            value={deceased.faith || ''}
            suggestions={faiths}
            onChange={faith => onDeceasedChange('faith', faith)}
            disabled={disabled}
            data-test-id="faith"
          />
          <TextField
            label={t('NI Number')}
            value={deceased.NINumber || ''}
            onChange={event => onDeceasedChange('NINumber', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'NINumber',
            }}
            disabled={disabled}
          />
          <TextField
            label={t('NHS Number')}
            value={deceased.NHSNumber || ''}
            onChange={event => onDeceasedChange('NHSNumber', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'NHSNumber',
            }}
            disabled={disabled}
          />
        </div>
      </ExpandableFormSection>
      <div className={styles.u_push__top_double}>
        <ExpandableFormSection title={t('Deceased care')}>
          <div className={classNames(
            rcStyles.v_spacing,
          )}
          >
            <div className={classNames(
              rcStyles.colspan2,
            )}
            >
              <AddressFormSection
                address={deceased.collectionLocation}
                lookupLabel="Body location"
                onChange={collectionLocation => onDeceasedChange('collectionLocation', collectionLocation)}
                errors={errors.collectionLocation}
                disabled={disabled}
                data-test-id="bodyLocationFormSection"
              />
            </div>
          </div>
          <div className={classNames(
            rcStyles.v_spacing,
          )}
          >
            <div className={classNames(
              rcStyles.colspan2,
            )}
            >
              <AutoCompleteTextField
                placeholder={t('Place of death')}
                value={deceased.placeOfDeath || ''}
                suggestions={placesOfDeath}
                onChange={placeOfDeath => onDeceasedChange('placeOfDeath', placeOfDeath)}
                disabled={disabled}
                data-test-id="placeOfDeath"
              />
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={deceased.isReadyForCollection}
                      onChange={event => onDeceasedChange('isReadyForCollection', event.target.checked)}
                      inputProps={{
                        'data-test-id': 'isReadyForCollection',
                      }}
                      color="primary"
                      disabled={disabled}
                    />
                  )}
                  label={t('Body ready for collection')}
                />
              </FormGroup>
              {deceased.isReadyForCollection && TransferIntoCareDetails}
            </div>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={deceased.isInfectious}
                    onChange={event => onDeceasedChange('isInfectious', event.target.checked)}
                    inputProps={{
                      'data-test-id': 'isInfectious',
                    }}
                    color="primary"
                    disabled={disabled}
                    className={styles.u_flush__left}
                  />
                )}
                label={t('Is Infectious')}
              />
            </FormGroup>
          </div>
        </ExpandableFormSection>
      </div>
      <div className={styles.u_push__top_double}>
        <ExpandableFormSection hideInitialElements title={t('Doctor & Care')}>
          <div className={rcStyles.v_spacing}>
            <div className={rcStyles.colspan2}>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={deceased.doNotPerformFirstOffices}
                      onChange={event => onDeceasedChange('firstOffices', {
                        ...deceased.firstOffices,
                        doNotPerform: event.target.checked,
                      })}
                      inputProps={{
                        'data-test-id': 'doNotPerformFirstOffices',
                      }}
                      color="primary"
                      disabled={disabled}
                    />
                  )}
                  label={t('Opt out of First Offices')}
                  className={rcStyles.rc_checkbox_margin}
                />
              </FormGroup>
            </div>
            {deceased.firstOffices && deceased.firstOffices.doNotPerform && (
              <div className={rcStyles.colspan2}>
                <TextField
                  label={t('Opt out reason')}
                  placeholder={t('Opt out reason')}
                  value={deceased.firstOffices && deceased.firstOffices.reasonNotToPerform}
                  onChange={event => onDeceasedChange('firstOffices', {
                    ...deceased.firstOffices,
                    reasonNotToPerform: event.target.value,
                  })}
                  inputProps={{
                    'data-test-id': 'reasonNotToPerformFirstOffices',
                  }}
                  fullWidth
                />
              </div>
            )}
            <div className={rcStyles.colspan2}>
              <FormControl data-test-id="isMccdIssued" component="fieldset">
                <div className={rcStyles.radio}>
                  <div className={rcStyles.radio__label}>
                    {t('MCCD issued')}
                  </div>
                  <div>
                    <RadioGroup
                      aria-label={t('MCCD Issued')}
                      name="isMCCDIssued"
                      value={`${deceased.isMCCDIssued}`}
                      onChange={event => onDeceasedChange('isMCCDIssued', event.target.value === 'true')}
                      row
                      data-test-id="isMCCDIssuedGroup"
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" disabled={disabled} data-test-id="yes" />}
                        label={t('Yes')}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" disabled={disabled} data-test-id="no" />}
                        label={t('No')}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </FormControl>
            </div>
            {deceased.isMCCDIssued === false && (
              <div className={rcStyles.colspan2}>
                <AutoCompleteTextField
                  placeholder={t('Reason for no MCCD')}
                  value={deceased.reasonForNoMCCDIssued || ''}
                  suggestions={reasonsForNoMCCDIssued}
                  onChange={reasonForNoMCCDIssued => onDeceasedChange('reasonForNoMCCDIssued', reasonForNoMCCDIssued)}
                  disabled={disabled}
                  data-test-id="reasonForNoMCCDIssued"
                />
              </div>
            )}
            <div className={rcStyles.colspan2}>
              <DynamicList
                component={(
                  <NameDirectoryListingFormSection
                    title={t('Clinician')}
                    disabled={disabled}
                  />
                )}
                addButtonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'medium',
                }}
                addButtonInner={(
                  <div className={styles.o_fb__inline}>
                    <AddIcon />
                    {t('Add clinician')}
                  </div>
                )}
                removeButtonProps={{
                  variant: 'outlined',
                  size: 'small',
                  color: 'primary',
                  classes: { root: styles.o_button__round },
                }}
                removeButtonInner={
                  <RemoveIcon />
                }
                isStacked
                dataList={clinicians}
                onChange={updatedClinicians => onBereavementChange('clinicians', updatedClinicians)}
                disabled={disabled}
                defaultEmpty
                data-test-id="clinicianFormSection"
              />
            </div>
            <div className={rcStyles.colspan2}>
              <NameDirectoryListingFormSection
                title={t('Coroner')}
                fieldData={{
                  name: coronerName,
                  directoryListing: coronerDirectoryListing,
                  directoryId: coronerDirectoryId,
                }}
                onChange={nameAddress => onCoronerChange(nameAddress)}
                disabled={disabled}
                data-test-id="coronerFormSection"
              />
            </div>
          </div>
        </ExpandableFormSection>
      </div>
      <div className={styles.u_push__top_double}>
        <ExpandableFormSection disableExpansion title={t('Advised possessions')}>
          <div className={classNames(
            styles.o_fb,
            styles.u_push__bottom,
          )}
          >
            <div className={styles.o_fb__item}>
              <TextField
                label={t('Possessions')}
                value={bereavement.advisoryPossessions || ''}
                onChange={event => onBereavementChange('advisoryPossessions', event.target.value)}
                fullWidth
                inputProps={{
                  'data-test-id': 'possessions',
                }}
              />
            </div>
          </div>
        </ExpandableFormSection>
      </div>
      <div className={styles.u_push__top_double}>
        <ExpandableFormSection disableExpansion title={t('Service requests')}>
          <div className={classNames(
            styles.o_fb,
            styles.u_push__bottom,
          )}
          >
            <div className={styles.o_fb__item}>
              <Select
                label={t('Service Type')}
                value={bereavement.advisoryCommittalType}
                onChange={event => onBereavementChange('advisoryCommittalType', event.target.value)}
                inputProps={{
                  'data-test-id': 'serviceType',
                }}
                className={styles.u_fullWidth}
              >
                <MenuItem>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="BURIAL">{t('Burial')}</MenuItem>
                <MenuItem value="CREMATION">{t('Cremation')}</MenuItem>
              </Select>
            </div>
          </div>
        </ExpandableFormSection>
      </div>
    </div>
  );
};

FirstCallDeceasedForm.propTypes = {
  bereavement: bereavementType.isRequired,
  onDeceasedChange: PropTypes.func.isRequired,
  onDeceasedTransferIntoCareChange: PropTypes.func.isRequired,
  onBereavementChange: PropTypes.func.isRequired,
  onCoronerChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default FirstCallDeceasedForm;
