import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ScreenLayout from 'components/common/ScreenLayout';
import PageLoader from 'components/common/PageLoader';
import { categories } from 'constants/businessIntelligence';

import overrideTheme from 'services/themes/overrideTheme';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import Chart from 'components/common/Chart';
import rcStyles from './styles.scss';

const BusinessIntelligenceDashboardScreen = ({
  activeCategory,
  reports,
  isLoading,
  onSelectCategory,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <ScreenLayout
      title={t('Business Intelligence')}
      dashboardBannerProps={{
        breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/business-intelligence', title: 'Business intelligence' }],
        description: t('View business intelligence reports'),
      }}
    >
      <div className={rcStyles.page_layout}>
        <div className={rcStyles.page_navigation}>
          <ul className={rcStyles.page_navigation_list}>
            {categories.map(category => (
              <li
                className={classNames(
                  rcStyles.page_navigation_list_item,
                  category.value === activeCategory.value ? rcStyles.is_current : null,
                )}
                key={category.name}
              >
                <a
                  onClick={() => onSelectCategory(category)}
                  data-test-id="reportCategory"
                  role="button"
                  tabIndex="-1"
                >
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={rcStyles.page_main}>
          {isLoading ? (
            <PageLoader
              isLoading={isLoading}
            />
          ) : (
            <Fragment>
              <h3 className={styles.c_heading__main}>{activeCategory.name}</h3>
              <div className={rcStyles.reports_layout}>
                {reports.map(report => (
                  <div
                    className={classNames(
                      styles.c_k_tile,
                      report.tileType === 'TWO_BY_ONE' ? rcStyles.is_2col : rcStyles.is_1col,
                    )}
                    key={report.lookId}
                  >
                    {report.chart.type !== 'Single' ? (
                      <div className={styles.c_k_tile__header}>
                        <h3 className={styles.is_heading}>{report.title}</h3>
                      </div>
                    ) : (
                      ''
                    )}
                    <Chart
                      chartData={JSON.parse(report.chart.payload)}
                      chartType={report.chart.type}
                      reportInfo={report.reportInfo}
                    />
                  </div>
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </ScreenLayout>
  </MuiThemeProvider>
);

BusinessIntelligenceDashboardScreen.propTypes = {
  activeCategory: PropTypes.objectOf(PropTypes.string).isRequired,
  reports: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
};

export default BusinessIntelligenceDashboardScreen;
