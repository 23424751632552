import React from 'react';
import ostyle from './offline.scss';

const IconOffline = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.93 191.49">
    <path className={ostyle.offline_cloud} d="M216,191l-173.94.47a42.09,42.09,0,0,1-10.64-82.81,104.1,104.1,0,0,1-.84-12.85,95.67,95.67,0,0,1,185-34.44A65.05,65.05,0,0,1,275.76,131C273.82,157.89,249,191,216,191ZM42.05,130.27a19.13,19.13,0,0,0,0,38.26h172.2l2.14-.46a42.09,42.09,0,0,0-6-83.72H207.9l-9.19.53L196.26,76a72.78,72.78,0,0,0-140,39.79l4.89,16.38L44,130.27Z" />
    <rect className={ostyle.offline_cross} x="115.28" y="51.52" width="26.28" height="107.86" transform="translate(-36.95 121.69) rotate(-45)" />
    <rect className={ostyle.offline_cross} x="74.33" y="92.38" width="108.07" height="26.28" transform="translate(-37.02 121.67) rotate(-45)" />
  </svg>
);

export default IconOffline;
