import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { createBereavedPersonMutation, editBereavedPersonMutation } from 'actions/bereavement.mutations.gql';
import { bereavementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import LiteClientForm from './LiteClientForm';
import { validationSchema } from './validation';

class LiteClientFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    isNewBereavement: PropTypes.bool.isRequired,
    setErrors: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    client: apolloClientType,
  }

  constructor(props) {
    super(props);

    const formData = props.bereavement.bereavedPeopleConnections
      ? {
        name: props.bereavement.bereavedPeopleConnections[0].bereavedPerson.name,
        email: props.bereavement.bereavedPeopleConnections[0].bereavedPerson.emails[0],
      } : {};

    this.state = {
      formData,
      isValidationEnabled: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value) => {
    const { validate, setErrors } = this.props;
    const { formData, isValidationEnabled } = this.state;

    const newFormData = {
      ...formData,
      [key]: value,
    };

    this.setState({ formData: newFormData });

    validate(newFormData, validationSchema, true);
    if (!isValidationEnabled) {
      setErrors({});
    }
  }

  handleSave = () => {
    const {
      bereavement,
      validate,
      onSave,
      client,
    } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate(formData, validationSchema, true);

    if (isValid) {
      this.setState({ isSaving: true });

      const input = {
        name: formData.name,
        emails: formData.email ? [formData.email] : [],
      };
      if (bereavement.bereavedPeopleConnections) {
        input.id = bereavement.bereavedPeopleConnections[0].bereavedPersonId;
      }

      client.mutate({
        mutation: input.id ? editBereavedPersonMutation : createBereavedPersonMutation,
        variables: { input },
      }).then(({ data }) => {
        setTimeout(() => {
          const result = input.id ? data.bereaved.editBereavedPerson : data.bereaved.createBereavedPerson;
          onSave(result.bereavedPerson);
        }, 3000);
      });
    }
  }

  render() {
    const {
      isNewBereavement,
      errors,
      formRefs,
      onCancel,
    } = this.props;
    const { formData, isSaving } = this.state;

    return (
      <LiteClientForm
        formData={formData}
        isNewBereavement={isNewBereavement}
        isSaving={isSaving}
        errors={errors}
        formRefs={formRefs}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onCancel={onCancel}
      />
    );
  }
}

export default withApollo(withValidation(LiteClientFormContainer));
