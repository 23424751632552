export default {
  translation: {
    'report.81.bereavements.account_total_uninvoiced_sum': 'Total not yet invoiced',

    'report.110.bereavement_account_payments.total': 'Payments recieved month to date',

    'report.111.bereavement_account_payments.total': 'Payments recieved quarter to date',

    'report.109.staff_members.name': 'Arranger',
    'report.109.arrangements.final_total_sum': 'Total',

    'report.88.bereavement_status_action_log.count': 'Funerals',
    'report.88.created': 'Created',
    'report.88.closed': 'Closed',

    'report.97.bereavements.count': 'Funerals created this month',

    'report.92.organisational_units.name': 'Home',
    'report.92.row_total_value': 'Total',

    'report.94.organisational_units.name': 'Home',
    'report.94.row_total_value': 'Total',

    'report.107.staff_members.name': 'Arranger',
    'report.107.arrangements.final_total_sum': 'Total',

    'report.108.staff_members.name': 'Arranger',
    'report.108.arrangements.final_total_sum': 'Total',

    'report.103.bereavement_deceased_people.name': 'Deceased Name',
    'report.103.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.103.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.103.bereavements.funeral_date': 'Funeral Date',
    'report.103.bereavements.account_total_uninvoiced_sum': 'Not yet invoiced amount',

    'report.85.bereavement_deceased_people.name': 'Deceased Name',
    'report.85.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.85.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.85.bereavements.funeral_date': 'Funeral Date',
    'report.85.bereavements.account_total_uninvoiced': 'Not invoiced amount',

    'report.79.bereavement_account_debtor_days.average_debtor_days': 'Debtor Days (average)',

    'report.77.bereavement_deceased_people.name': 'Deceased Name',
    'report.77.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.77.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.77.bereavements.funeral_date': 'Funeral Date',
    'report.77.bereavement_possessions.count': 'Possessions',

    'report.104.bereavement_deceased_people.name': 'Deceased Name',
    'report.104.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.104.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.104.bereavements.funeral_date': 'Funeral Date',
    'report.104.bereavements.account_balance_sum': 'Account Balance',

    'report.86.bereavement_deceased_people.name': 'Deceased Name',
    'report.86.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.86.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.86.bereavements.funeral_date': 'Funeral Date',
    'report.86.bereavements.account_balance': 'Account Balance',

    'report.72.bereavement_deceased_people.name': 'Deceased Name',
    'report.72.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.72.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.72.bereavements.funeral_date': 'Funeral Date',
    'report.72.bereavement_deceased_people_transfers_into_care.date_time': 'Date and time of collection',

    'report.87.bereavement_deceased_people.name': 'Deceased Name',
    'report.87.bereavement_deceased_people.date_of_birth_date': 'Date of Birth',
    'report.87.bereavement_deceased_people.date_of_death_date': 'Date of Death',
    'report.87.bereavements.funeral_date': 'Funeral Date',
    'report.87.bereavements.account_balance': 'Account Balance',
    'report.87.days_overdue': 'Days Overdue',

    'report.84.bereavements.account_balance_sum': 'Total outstanding balances',

    'report.112.bereavement_account_payments.total': 'Payments recieved year to date',

    'report.93.staff_members.name': 'Arranger',
    'report.93.row_total_value': 'Total',

    'report.98.organisational_units.name': 'Home',
    'report.98.staff_members.name': 'Arranger',
    'report.98.row_total_value': 'Total',

    'report.99.staff_members.name': 'Arranger',
    'report.99.row_total_value': 'Total',

    'report.95.staff_members.name': 'Arranger',
    'report.95.row_total_value': 'Total',

    'report.96._measure': 'Funerals',
    'report.96.bereavements.count': 'Completed',

    'report.60.bereavements.count': 'Funerals completed this month',

    'report.61.bereavements.count': 'Funerals created',
    'report.61._measure': 'Funerals',

    'report.62.bereavements.count': 'Funerals created',
    'report.62._measure': 'Funerals',

    'report.102.bereavements.count': 'Open cases with no confirmed arrangement',

    'report.63.services.title': 'Vehicle',
    'report.63.service_variants.name': 'Variant',
    'report.63.arrangement_service_selections.selection_count': 'Times selected',

    'report.64.services.title': 'Service',
    'report.64.service_variants.name': 'Variant',
    'report.64.arrangement_service_selections.selection_count': 'Times selected',

    'report.65.products.title': 'Product',
    'report.65.product_variants.name': 'Variant',
    'report.65.products.category': 'Category',
    'report.65.arrangement_product_selections.selection_count': 'Times selected',

    'report.78.bereavement_account_payments.total': 'Total Paid',
    'report.78.bereavements.account_total_invoiced_sum': 'Total Invoiced',
    'report.78._measure': '(£)',

    'report.113.arrangements.final_total_sum': 'Total Value',
    'report.113._measure': '(£)',

    'report.106.arrangements.final_total_sum': 'Final Total Sum',
  },
};
