import { string, object, array } from 'yup';

export const validationSchema = object().shape({
  location: object().nullable().required('Viewing address is required').shape({
    addressLine1: string().nullable().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }),
  startDateTime: string().nullable().required('Appointment date is required'),
  endDateTime: string().nullable().required('Appointment date is required'),
  permittedAttendees: array().of(string()),
});
