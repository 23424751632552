import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

import { setEstimateSelectionsOrder as setEstimateSelectionsOrderAction } from 'actions/arrangement';
import { editArrangementAction } from 'actions/bereavement';
import { discountTypes } from 'constants/arrangement';
import { arrayMove } from 'services/utils';
import {
  filterForInPackageArrangementSelections,
  filterForAdditionalPackageArrangementSelections,
  filterForProfessionalServiceArrangementSelections,
  filterForDisbursementArrangementSelections,
} from 'services/utils/arrangement';
import { apolloClientType } from 'types/apollo';
import { bereavementType, arrangementType } from 'types/bereavement';

import ArrangementEstimateScreen from './ArrangementEstimateScreen';
import { setHideDiscountOptionOnArrangement } from './mutations.gql';

class ArrangementEstimateScreenContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    disabled: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    client: apolloClientType.isRequired,
    editArrangement: PropTypes.func.isRequired,
    setEstimateSelectionsOrder: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isConfirmationWarningModalOpen: false,
      confirmationWarningModalCallback: null,
      duplicateSelectionItem: null,
      duplicateItemConfirmCallback: null,
    };
  }

  handleDuplicateItemConfirm = () => {
    const { duplicateItemConfirmCallback } = this.state;

    duplicateItemConfirmCallback();

    this.setState({
      duplicateSelectionItem: null,
      duplicateItemConfirmCallback: null,
    });
  }

  handleDuplicateItemCancel = () => {
    this.setState({
      duplicateSelectionItem: null,
      duplicateItemConfirmCallback: null,
    });
  }

  handleOpenDuplicateSelectionModal = (item, callback) => {
    this.setState({
      duplicateSelectionItem: item,
      duplicateItemConfirmCallback: callback,
    });
  }

  handleOnClickConfirmationWarningModalConfirm = () => {
    const { confirmationWarningModalCallback } = this.state;

    confirmationWarningModalCallback();

    this.setState({
      isConfirmationWarningModalOpen: false,
      confirmationWarningModalCallback: null,
    });
  }

  handleOnClickConfirmationWarningModalCancel = () => {
    this.setState({
      isConfirmationWarningModalOpen: false,
      confirmationWarningModalCallback: null,
    });
  }

  handleOpenConfirmationWarningModal = (callback) => {
    this.setState({
      isConfirmationWarningModalOpen: true,
      confirmationWarningModalCallback: callback,
    });
  }

  handleHideDiscountOnChange = (value) => {
    const {
      client,
      bereavement,
      arrangement,
      editArrangement,
    } = this.props;

    editArrangement(bereavement.id, arrangement.id, { hideDiscount: value });

    client.mutate({
      mutation: setHideDiscountOptionOnArrangement,
      variables: {
        input: {
          bereavementId: bereavement.id,
          arrangementId: arrangement.id,
          hideDiscount: value,
        },
      },
    });
  }

  hasADiscountBeenApplied = () => {
    const { arrangement } = this.props;

    return (
      arrangement.discountType === discountTypes.GLOBAL
      && arrangement.globalDiscount
      && arrangement.globalDiscount.amount > 0
    ) || (
      arrangement.discountType === discountTypes.ITEMISED
      && arrangement.calculation.discounts.amount > 0
    );
  }

  handleOnSortEnd = (oldSelection, newSelection) => {
    const {
      setEstimateSelectionsOrder,
      bereavement,
      arrangement,
    } = this.props;

    const arrangementSelections = [
      ...arrangement.productSelections ?? [],
      ...arrangement.serviceSelections ?? [],
    ];

    const arrangementSelectionsWithOrderNumber = arrangementSelections.map((selection, index) => {
      let arrangementOrderIndex;
      if (arrangement.orderOfEstimateSelections) {
        arrangementOrderIndex = arrangement.orderOfEstimateSelections.findIndex(
          selectionOrder => selectionOrder === selection.id,
        );
      }
      return {
        ...selection,
        estimateOrder: arrangementOrderIndex > -1
          ? arrangementOrderIndex
          : arrangementSelections.length + index,
      };
    });

    const sortedSelectionsByEstimateOrder = arrangementSelectionsWithOrderNumber.sort(
      (selection1, selection2) => selection1.estimateOrder - selection2.estimateOrder,
    );

    const fromIndex = sortedSelectionsByEstimateOrder.findIndex(selection => selection.id === oldSelection.id);
    const toIndex = sortedSelectionsByEstimateOrder.findIndex(selection => selection.id === newSelection.id);

    const sortedSelectionsAfterMove = arrayMove(
      sortedSelectionsByEstimateOrder,
      fromIndex,
      toIndex,
    );

    const inPackageArrangementSelections = filterForInPackageArrangementSelections(
      sortedSelectionsAfterMove,
    );
    const additionalArrangementSelections = filterForAdditionalPackageArrangementSelections(
      sortedSelectionsAfterMove,
    );
    const professionalServiceArrangementSelections = filterForProfessionalServiceArrangementSelections(
      sortedSelectionsAfterMove,
    );
    const disbursementArrangementSelections = filterForDisbursementArrangementSelections(
      sortedSelectionsAfterMove,
    );

    const sortedEstimateSelectionsBySection = [
      ...inPackageArrangementSelections ?? [],
      ...additionalArrangementSelections ?? [],
      ...professionalServiceArrangementSelections ?? [],
      ...disbursementArrangementSelections ?? [],
    ];

    const selectionIds = sortedEstimateSelectionsBySection.map(selection => selection.id);

    setEstimateSelectionsOrder(
      bereavement.id,
      arrangement.id,
      selectionIds,
    );
  }

  render() {
    const {
      bereavement,
      arrangement,
      availableFeatures,
      disabled,
      isOnline,
    } = this.props;

    const {
      duplicateSelectionItem,
      isConfirmationWarningModalOpen,
    } = this.state;

    return (
      <ArrangementEstimateScreen
        bereavement={bereavement}
        arrangement={arrangement}
        availableFeatures={availableFeatures}
        duplicateSelectionItem={duplicateSelectionItem}
        isConfirmationWarningModalOpen={isConfirmationWarningModalOpen}
        disabled={disabled}
        onDuplicateItemConfirm={this.handleDuplicateItemConfirm}
        onDuplicateItemCancel={this.handleDuplicateItemCancel}
        onClickConfirmationWarningModalConfirm={this.handleOnClickConfirmationWarningModalConfirm}
        onClickConfirmationWarningModalCancel={this.handleOnClickConfirmationWarningModalCancel}
        onOpenDuplicateSelectionModal={this.handleOpenDuplicateSelectionModal}
        onOpenConfirmationWarningModal={this.handleOpenConfirmationWarningModal}
        isOnline={isOnline}
        onHideDiscountChange={this.handleHideDiscountOnChange}
        hasADiscountBeenApplied={this.hasADiscountBeenApplied}
        onSortEnd={this.handleOnSortEnd}
      />
    );
  }
}

const mapStateToProps = state => ({
  isOnline: state.userStore.isOnline,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  editArrangement: editArrangementAction,
  setEstimateSelectionsOrder: setEstimateSelectionsOrderAction,
}, dispatch);

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(ArrangementEstimateScreenContainer));
