import { TenantFeature } from 'types/ts/tenant';

export const featureFlags = {
  FUNERAL_ZONE_OBITUARIES: 'funeralZoneObituaries' as TenantFeature,
  CASES_SCREEN: 'casesScreen' as TenantFeature,
  LITE_ARRANGEMENTS_SCREEN: 'liteArrangementsScreen' as TenantFeature,
  CATALOGUE_ARRANGEMENT_SETTINGS_SCREEN: 'catalogueArrangementSettingsScreen' as TenantFeature,
  ACCOUNTS_SCREEN: 'accountsScreen' as TenantFeature,
  TASKS_SCREEN: 'tasksScreen' as TenantFeature,
  REPORTS_SCREEN: 'reportsScreen' as TenantFeature,
  FIRST_CALL_SCREEN: 'firstCallScreen' as TenantFeature,
  CALENDAR: 'calendar' as TenantFeature,
  DIRECTORY_LISTINGS_SCREEN: 'directoryListingsScreen' as TenantFeature,
  ESTIMATE_DISCOUNT_SELECTOR: 'estimateDiscounts' as TenantFeature,
  ESTIMATE_SERVICE_EMAIL: 'estimateServiceEmail' as TenantFeature,
  ESTIMATE_PRINT_SERVICE: 'estimatePrintService' as TenantFeature,
  DISPLAY_CATEGORY_INFORMATION: 'categoryInformation' as TenantFeature,
  RESTRICT_ROLES_TO_ARRANGER_AND_ADMINISTRATOR: 'restrictRolesToArrangerAndAdministrator' as TenantFeature,
  LITE_SPLASH_SCREEN_LOGO: 'liteSplashScreenLogo' as TenantFeature,
  CORRESPONDENCE_TEMPLATES_SCREEN: 'correspondenceTemplatesScreen' as TenantFeature,
  ESTIMATE_TEMPLATES_SCREEN: 'estimateTemplatesScreen' as TenantFeature,
  INVOICE_TEMPLATES_SCREEN: 'invoiceTemplatesScreen' as TenantFeature,
  CORRESPONDENCE_VALUES_SCREEN: 'correspondenceValuesScreen' as TenantFeature,
  BUSINESS_INTELLIGENCE_SCREEN: 'businessIntelligenceScreen' as TenantFeature,
  XERO_INTEGRATION: 'xeroIntegration' as TenantFeature,
};
