import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import IconWatch from '@material-ui/icons/WatchLater';
import Select from 'components/common/Select';
import NameFormSection from 'components/common/NameFormSection';
import AddressFormSection from 'components/common/AddressFormSection';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import BodySizeFormSection from 'components/bereavement/BodySizeFormSection/index';
import DateTimePicker from 'components/common/DateTimePicker';
import DatePicker from 'components/common/DatePicker';
import MediaUpload from 'components/common/MediaUpload';
import NumberField from 'components/common/NumberField';
import { faiths, nationalities, placesOfDeath } from 'constants/bereavement';
import { genders } from 'constants/general';
import { deceasedPersonType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const DeceasedForm = ({
  deceasedPerson,
  disabled,
  onDeceasedChange,
  onImageIdChange,
  onImageChange,
  onSave,
  formRefs,
  errors,
}) => (
  <Fragment>
    <div
      ref={formRefs.name}
      className={classNames(
        rcStyles.v_spacing,
      )}
    >
      <div
        className={classNames(
          rcStyles.colspan3,
          styles.long_primer,
        )}
      >
        {t('Deceased details')}
      </div>
      <div
        className={classNames(
          rcStyles.colspan3,
        )}
      >
        <NameFormSection
          name={deceasedPerson.name}
          labelTitle={t('Title')}
          onChangeHandler={name => onDeceasedChange('name', name)}
          labelGivenName={t('Given Name *')}
          labelFamilyName={t('Family Name *')}
          errors={errors.name}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.maritalStatus}
      >
        <Select
          label={t('Marital Status')}
          value={deceasedPerson.maritalStatus}
          onChange={event => onDeceasedChange('maritalStatus', event.target.value)}
          inputProps={{
            'data-test-id': 'maritalStatus',
          }}
          fullWidth
          disabled={disabled}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="SINGLE">{t('Single')}</MenuItem>
          <MenuItem value="MARRIED">{t('Married')}</MenuItem>
          <MenuItem value="CIVILPARTNERED">{t('Civil Partnership')}</MenuItem>
          <MenuItem value="DIVORCED">{t('Divorced')}</MenuItem>
          <MenuItem value="WIDOWED">{t('Widowed')}</MenuItem>
          <MenuItem value="OTHER">{t('Other')}</MenuItem>
        </Select>
      </div>
      <div
        ref={formRefs.previousSurname}
      >
        <TextField
          label={t('Previous Surname')}
          value={deceasedPerson.previousSurname || ''}
          onChange={event => onDeceasedChange('previousSurname', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'previousSurname',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.alsoKnownAs}
      >
        <TextField
          label={t('Also Known As')}
          value={deceasedPerson.alsoKnownAs || ''}
          onChange={event => onDeceasedChange('alsoKnownAs', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'alsoKnownAs',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.dateOfBirth}
      >
        <DatePicker
          value={deceasedPerson.dateOfBirth || null}
          label={t('Date of Birth')}
          onChange={date => onDeceasedChange('dateOfBirth', date)}
          disableFuture
          isClearable
          errors={errors && !!errors.dateOfBirth}
          helperText={errors && errors.dateOfBirth}
          disabled={disabled}
          data-test-id="dateOfBirth"
        />
      </div>
      <div
        ref={formRefs.placeOfBirth}
      >
        <TextField
          label={t('Place of Birth')}
          value={deceasedPerson.placeOfBirth || ''}
          onChange={event => onDeceasedChange('placeOfBirth', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'placeOfBirth',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.gender}
      >
        <AutoCompleteTextField
          placeholder={t('Gender')}
          value={deceasedPerson.gender || ''}
          suggestions={genders}
          onChange={gender => onDeceasedChange('gender', gender)}
          disabled={disabled}
          data-test-id="gender"
        />
      </div>
      <div
        ref={formRefs.nationality}
      >
        <AutoCompleteTextField
          placeholder={t('Nationality')}
          value={deceasedPerson.nationality || ''}
          suggestions={nationalities}
          onChange={nationality => onDeceasedChange('nationality', nationality)}
          fullWidth
          data-test-id="nationality"
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.faith}
      >
        <AutoCompleteTextField
          placeholder={t('Faith')}
          value={deceasedPerson.faith || ''}
          suggestions={faiths}
          onChange={faith => onDeceasedChange('faith', faith)}
          disabled={disabled}
          data-test-id="faith"
        />
      </div>
      <div
        ref={formRefs.occupation}
      >
        <TextField
          label={t('Occupation')}
          value={deceasedPerson.occupation || ''}
          onChange={event => onDeceasedChange('occupation', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'occupation',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.NINumber}
      >
        <TextField
          label={t('NI Number')}
          value={deceasedPerson.NINumber || ''}
          onChange={event => onDeceasedChange('NINumber', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'NINumber',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.NHSNumber}
      >
        <TextField
          label={t('NHS Number')}
          value={deceasedPerson.NHSNumber || ''}
          onChange={event => onDeceasedChange('NHSNumber', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'NHSNumber',
          }}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.address}
        className={classNames(
          rcStyles.colspan3,
        )}
      >
        <AddressFormSection
          address={deceasedPerson.address}
          lookupLabel={t('Address')}
          onChange={address => onDeceasedChange('address', address)}
          className={styles.u_fullWidth}
          errors={errors.address}
          disabled={disabled}
          data-test-id="deceasedAddressFormSection"
        />
      </div>
      <div
        className={classNames(
          rcStyles.colspan3,
          styles.long_primer,
        )}
      >
        {t('Deceased care')}
      </div>
      <div
        ref={formRefs.deathDateTime}
      >
        <DateTimePicker
          value={deceasedPerson.deathDateTime || null}
          label={t('Date and Time of Death')}
          onChange={date => onDeceasedChange('deathDateTime', date)}
          isClearable
          disableFuture
          disabled={disabled}
          errors={errors && !!errors.deathDateTime}
          helperText={errors && errors.deathDateTime}
          data-test-id="deathDateTime"
        />
      </div>
      <div
        ref={formRefs.ageAtDeath}
      >
        <NumberField
          label={t('Age at Death')}
          value={deceasedPerson.ageAtDeath !== null ? deceasedPerson.ageAtDeath : ''}
          onChange={event => onDeceasedChange('ageAtDeath', event.target.value)}
          disabled={disabled || !!(deceasedPerson.dateOfBirth && deceasedPerson.deathDateTime)}
          error={errors && !!errors.ageAtDeath}
          helperText={errors && errors.ageAtDeath}
          fullWidth
          data-test-id="ageAtDeath"
        />
      </div>
      <div
        ref={formRefs.registrationOfDeathDate}
      >
        <DatePicker
          value={deceasedPerson.registrationOfDeathDate || null}
          label={t('Registration of Death Date')}
          onChange={time => onDeceasedChange('registrationOfDeathDate', time)}
          disableFuture
          isClearable
          icon={<IconWatch className={styles.o_icon__blue} />}
          disabled={disabled}
          data-test-id="registrationOfDeathDate"
        />
      </div>
      <div
        ref={formRefs.placeOfDeath}
      >
        <AutoCompleteTextField
          placeholder={t('Place of Death')}
          value={deceasedPerson.placeOfDeath || ''}
          suggestions={placesOfDeath}
          onChange={placeOfDeath => onDeceasedChange('placeOfDeath', placeOfDeath)}
          disabled={disabled}
          data-test-id="placeOfDeath"
        />
      </div>
      <div
        className={classNames(
          rcStyles.colspan2,
        )}
      >
        <AddressFormSection
          address={deceasedPerson.collectionLocation}
          lookupLabel={t('Body location')}
          onChange={collectionLocation => onDeceasedChange('collectionLocation', collectionLocation)}
          errors={errors.collectionLocation}
          disabled={disabled}
          data-test-id="collectionLocationFormSection"
        />
      </div>
      <div
        ref={formRefs.bodySize}
        className={classNames(
          rcStyles.colspan3,
        )}
      >
        <BodySizeFormSection
          bodySize={deceasedPerson.bodySize}
          onChange={onDeceasedChange}
          disabled={disabled}
          errors={errors.bodySize}
          data-test-id="bodySizeFormSection"
        />
      </div>
      <div
        ref={formRefs.weight}
      >
        <NumberField
          label={t('Weight')}
          value={deceasedPerson.weight !== null ? deceasedPerson.weight : ''}
          onChange={event => onDeceasedChange('weight', event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('Kg')}</InputAdornment>,
          }}
          disabled={disabled}
          data-test-id="weight"
        />
      </div>
      <div
        className={classNames(
          rcStyles.colspan3,
        )}
      >
        <FormGroup>
          <MediaUpload
            images={deceasedPerson.image ? [deceasedPerson.image] : []}
            acceptImages
            showImagePreview
            disabled={disabled}
            contentDisposition="inline"
            onUploadStart={onImageIdChange}
            onUploadDone={onImageChange}
            data-test-id="deceasedPersonImageUpload"
          />
        </FormGroup>
      </div>
      <div className={classNames(
        rcStyles.colspan3,
        styles.has_top_border,
      )}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          data-test-id="saveDeceasedForm"
          disabled={disabled}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  </Fragment>
);
DeceasedForm.propTypes = {
  deceasedPerson: deceasedPersonType.isRequired,
  disabled: PropTypes.bool,
  onDeceasedChange: PropTypes.func.isRequired,
  onImageIdChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default DeceasedForm;
