import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Price from 'components/common/Price';
import { moneyType } from 'types/common';
import { defaultMoneyObject, isNullOrUndefined } from 'services/utils';
import CurrencyField from 'components/common/CurrencyField';
import classNames from 'classnames';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const InvoiceOverridablePrice = ({
  originalPrice,
  overridePrice,
  isOverrideItem,
  disabled,
  onChange,
  testIds,
}) => (
  <Fragment>
    <div className={classNames(
      styles.o_fb,
    )}
    >
      <Price
        price={originalPrice}
        customClass={classNames(
          styles.c_price_label,
          styles.u_push__right_half,
          styles.o_fb,
          styles.o_fb__v_center,
          { [styles.c_strike_through]: overridePrice && overridePrice.amount !== originalPrice.amount },
        )}
        dataTestId={testIds && testIds.originalPrice}
      />
      {isOverrideItem && (
        <div className={rcStyles.rc_input_width}>
          <CurrencyField
            classes={{
              root: classNames(
                styles.u_push__right,
              ),
            }}
            min={0}
            nullable
            label={t('New price')}
            value={(overridePrice !== null && overridePrice !== undefined) ? overridePrice.amount : ''}
            inputProps={{
              'data-test-id': testIds && testIds.overridePrice,
            }}
            onChange={(value) => {
              onChange(!isNullOrUndefined(value) ? defaultMoneyObject(value) : null);
            }}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  </Fragment>
);

InvoiceOverridablePrice.propTypes = {
  originalPrice: moneyType.isRequired,
  overridePrice: moneyType,
  isOverrideItem: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  testIds: PropTypes.shape({
    originalPrice: PropTypes.string,
    overridePrice: PropTypes.string,
  }),
};

export default InvoiceOverridablePrice;
