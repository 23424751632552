import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { categories } from 'constants/businessIntelligence';
import { apolloClientType } from 'types/apollo';
import BusinessIntelligenceDashboardScreen from './BusinessIntelligenceDashboardScreen';
import { getReports } from './queries.gql';

class BusinessIntelligenceDashboardScreenContainer extends Component {
  static propTypes = {
    client: apolloClientType,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeCategory: categories[0],
      reports: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { activeCategory } = this.state;

    this.loadReports(activeCategory);
  }

  loadReports = (category) => {
    const { client } = this.props;

    this.setState({
      reports: [],
      isLoading: true,
    });


    client.query({
      query: getReports,
      variables: {
        input: {
          category: category.value,
        },
      },
    }).then(({ data }) => {
      const { activeCategory } = this.state;
      if (!data.reports || data.reports[0].category !== activeCategory.value) {
        return;
      }

      const { reports } = data.reports[0];
      this.setState({
        reports: reports || [],
      });
    }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  handleSelectCategory = (category) => {
    const { activeCategory } = this.state;

    if (activeCategory.value === category.value) {
      return;
    }

    this.setState({
      activeCategory: category,
    });
    this.loadReports(category);
  }

  render() {
    const { activeCategory, reports, isLoading } = this.state;
    return (
      <BusinessIntelligenceDashboardScreen
        activeCategory={activeCategory}
        reports={reports}
        isLoading={isLoading}
        onSelectCategory={this.handleSelectCategory}
      />
    );
  }
}

export default withApollo(BusinessIntelligenceDashboardScreenContainer);
