import { store } from 'services/state';
import { setIsOnlineAction } from 'actions/user';
import { setIsDispatchingAction, dispatchRequestAction } from 'actions/request';

export const initialiseNetworkSubscriptions = () => {
  window.addEventListener('online', () => {
    store.dispatch(setIsOnlineAction(true));
    const { requests } = store.getState().requestQueueStore;
    if (requests.length > 0) {
      store.dispatch(setIsDispatchingAction(true));
      store.dispatch(dispatchRequestAction());
    }
  });

  window.addEventListener('offline', () => {
    store.dispatch(setIsOnlineAction(false));
  });
};
