export const requestQueueActions = {
  CLEAR_REQUESTS: 'REQUEST_QUEUE_CLEAR_REQUESTS',
  SET_REQUESTS: 'REQUEST_QUEUE_SET_REQUESTS',
  ADD_REQUESTS: 'REQUEST_QUEUE_ADD_REQUESTS',
  REMOVE_REQUEST: 'REQUEST_QUEUE_REMOVE_REQUEST',
  SET_IS_DISPATCHING: 'REQUEST_QUEUE_SET_IS_DISPATCHING',
  HANDLE_WEBSOCKET_MESSAGE: 'REQUEST_QUEUE_HANDLE_WEBSOCKET_MESSAGE',
  SET_LOCATION_BEFORE_ERROR: 'REQUEST_QUEUE_SET_LOCATION_BEFORE_ERROR',
  SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED: 'REQUEST_QUEUE_SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED',
};

export const clearRequestsAction = () => (
  { type: requestQueueActions.CLEAR_REQUESTS }
);

export const setRequestsAction = requests => (
  { type: requestQueueActions.SET_REQUESTS, payload: requests }
);

export const addRequestsAction = requests => (
  { type: requestQueueActions.ADD_REQUESTS, payload: requests }
);

export const removeRequestAction = () => (
  { type: requestQueueActions.REMOVE_REQUEST }
);

export const setIsDispatchingAction = isDispatching => (
  { type: requestQueueActions.SET_IS_DISPATCHING, payload: isDispatching }
);

export const handleWebsocketMessageAction = data => ({
  type: requestQueueActions.HANDLE_WEBSOCKET_MESSAGE,
  payload: data,
});

export const setLocationBeforeErrorAction = locationBeforeError => (
  { type: requestQueueActions.SET_LOCATION_BEFORE_ERROR, payload: locationBeforeError }
);

export const setProcessingOfRequestInQueueFailedAction = processingOfRequestInQueueFailedAction => ({
  type: requestQueueActions.SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED,
  payload: processingOfRequestInQueueFailedAction,
});

export const dispatchRequestAction = () => (dispatch, getState, client) => {
  const { requests } = getState().requestQueueStore;
  const { isOnline } = getState().userStore;
  if (requests.length > 0) {
    const [request] = requests;
    const { operation } = request.query.definitions[0];
    const method = operation === 'mutation' ? client.mutate : client.query;

    method({
      [operation]: request.query,
      variables: request.variables,
      context: {
        headers: {
          'Offline': 1,
        },
      },
    }).then(() => {
      dispatch(removeRequestAction());
      if (isOnline && getState().requestQueueStore.requests.length > 0) {
        dispatch(dispatchRequestAction());
      } else {
        dispatch(setIsDispatchingAction(false));
      }
    });
  }
};
