import {
  string, object, boolean, mixed,
} from 'yup';

export const validationSchema = object().shape({
  __typename: string().nullable(),
  fromLocation: mixed()
    .when('__typename', {
      is: 'InternalTransfer',
      then: string().nullable().required('Transfer from is required'),
    })
    .when('__typename', {
      is: ('ExternalTransfer'),
      then: object().nullable().shape({
        addressLine1: string().nullable().required('First line of address is required'),
        addressLine2: string().nullable(),
        town: string().nullable().required('Town is required'),
        county: string().nullable(),
        postCode: string().nullable().required('Post Code is required'),
        countryCode: string().nullable().required('Country is required'),
      }).required('Transfer from is required'),
    })
    .when('__typename', {
      is: ('TransferIntoCare'),
      then: object().nullable().shape({
        addressLine1: string().nullable().required('First line of address is required'),
        addressLine2: string().nullable(),
        town: string().nullable().required('Town is required'),
        county: string().nullable(),
        postCode: string().nullable().required('Post Code is required'),
        countryCode: string().nullable().required('Country is required'),
      }).required('Transfer from is required'),
    }),
  toLocation: mixed()
    .when('__typename', {
      is: 'InternalTransfer',
      then: string().nullable().required('Transfer to is required'),
    })
    .when('__typename', {
      is: ('ExternalTransfer'),
      then: object().nullable().shape({
        addressLine1: string().nullable().required('First line of address is required'),
        addressLine2: string().nullable(),
        town: string().nullable().required('Town is required'),
        county: string().nullable(),
        postCode: string().nullable().required('Post Code is required'),
        countryCode: string().nullable().required('Country is required'),
      }).required('Transfer to is required'),
    })
    .when('__typename', {
      is: ('TransferIntoCare'),
      then: object().nullable().shape({
        addressLine1: string().nullable().required('First line of address is required'),
        addressLine2: string().nullable(),
        town: string().nullable().required('Town is required'),
        county: string().nullable(),
        postCode: string().nullable().required('Post Code is required'),
        countryCode: string().nullable().required('Country is required'),
      }).required('Transfer to is required'),
    }),
  startDateTime: string().nullable().required('Start date & time is required'),
  endDateTime: string().when('__typename', {
    is: typeName => typeName !== 'InternalTransfer',
    then: string().nullable().required('End date & time is required'),
  }),
  isComplete: boolean(),
  instructions: string().nullable(),
  isPossessionsChecked: boolean(),
  isIdentificationChecked: boolean(),
  receivedBy: string().nullable(),
  completionNotes: string().nullable(),
});
