import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { nameType } from 'types/common';
import Select from 'components/common/Select';

import rcStyles from './styles.scss';

const NameFormSection = ({
  name,
  disabled,
  labelTitle,
  onChangeHandler,
  labelGivenName,
  labelMiddleName,
  labelFamilyName,
  titles,
  errors,
}) => (
  <div className={rcStyles.name_inputs}>
    <div className={rcStyles.is_title}>
      <Select
        label={t(labelTitle)}
        value={name.title}
        onChange={event => onChangeHandler('title', event.target.value)}
        inputProps={{
          'data-test-id': 'title',
        }}
        fullWidth
        disabled={disabled}
        error={errors && !!errors.title}
        errorText={errors && errors.title}
        className={rcStyles.is_title}
      >
        {titles.map(title => <MenuItem value={title}>{t(title)}</MenuItem>)}
      </Select>
    </div>
    <TextField
      label={labelGivenName}
      value={name.givenName || ''}
      onChange={event => onChangeHandler('givenName', event.target.value)}
      fullWidth
      inputProps={{
        'data-test-id': 'givenName',
      }}
      error={errors && !!errors.givenName}
      helperText={errors && errors.givenName}
      disabled={disabled}
      className={rcStyles.is_first_name}
    />

    <TextField
      label={labelMiddleName}
      value={name.middleName || ''}
      onChange={event => onChangeHandler('middleName', event.target.value)}
      fullWidth
      inputProps={{
        'data-test-id': 'middleName',
      }}
      error={errors && !!errors.middleName}
      helperText={errors && errors.middleName}
      disabled={disabled}
      className={rcStyles.is_middle_name}
    />

    <TextField
      label={labelFamilyName}
      value={name.familyName || ''}
      onChange={event => onChangeHandler('familyName', event.target.value)}
      fullWidth
      inputProps={{
        'data-test-id': 'familyName',
      }}
      error={errors && !!errors.familyName}
      helperText={errors && errors.familyName}
      disabled={disabled}
      className={rcStyles.is_last_name}
    />
  </div>
);

NameFormSection.propTypes = {
  name: nameType.isRequired,
  disabled: PropTypes.bool,
  labelTitle: PropTypes.string.isRequired,
  labelGivenName: PropTypes.string.isRequired,
  labelMiddleName: PropTypes.string.isRequired,
  labelFamilyName: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string),
  onChangeHandler: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default NameFormSection;
