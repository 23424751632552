import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { getUriForMediaByVariant, getStartAndEndDates } from 'services/utils';
import { matchType } from 'types/reactRouter';
import { mediaVariants } from 'constants/media';
import { committalTypeLabels } from 'constants/arrangement';
import classNames from 'classnames';
import rcStyles from './styles.scss';

const CaseCard = ({
  bereavementData,
  match,
}) => {
  const deceasedPeople = bereavementData.deceasedPeople[0];
  const imageVariantUri = deceasedPeople.image
    && getUriForMediaByVariant(deceasedPeople.image, mediaVariants.FIT_200_X_200);
  const deceasedName = `${deceasedPeople.name.givenName} ${deceasedPeople.name.familyName}`;
  const deceasedDates = getStartAndEndDates(deceasedPeople.dateOfBirth, deceasedPeople.deathDateTime);
  const ownerName = bereavementData.owner
    ? `${bereavementData.owner.name.givenName} ${bereavementData.owner.name.familyName}`
    : null;
  const homeName = bereavementData.home && bereavementData.home.name;
  const confirmedArrangement = bereavementData.arrangements.find(arrangement => arrangement.isConfirmed);
  const committalType = confirmedArrangement && committalTypeLabels
    .find(type => type.value === confirmedArrangement.committalType).label;

  return (
    <Link
      to={`${match.url}/${bereavementData.id}/arrangement`}
      className={classNames(
        rcStyles.case_card_parent,
        confirmedArrangement ? rcStyles.is_confirmed : rcStyles.is_unconfirmed,
      )}
    >
      <div
        className={rcStyles.case_card}
        data-test-id="caseCard"
      >
        {
          imageVariantUri ? (
            <div
              alt={deceasedName}
              style={{
                background: `url('${imageVariantUri}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              className={rcStyles.case_card__image}
            />
          ) : (
            <div className={rcStyles.case_card__image}>
              <PersonIcon />
            </div>
          )
        }
        <div className={rcStyles.case_card__content}>
          <div
            className={rcStyles.is_title}
            data-test-id="caseCardDeceasedName"
          >
            {deceasedName}
          </div>
          <div className={rcStyles.is_dates}>
            {deceasedPeople.dateOfBirth ? deceasedDates : ''}
          </div>
          {confirmedArrangement ? (
            <div className={rcStyles.is_confirmed}>
              {t(committalType)}
            </div>
          ) : (
            <div className={rcStyles.is_unconfirmed}>
              {t('Unconfirmed')}
            </div>
          )}
          <div className={rcStyles.is_owners}>
            <div>{ownerName}</div>
            <div>{homeName}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

CaseCard.propTypes = {
  bereavementData: PropTypes.objectOf(PropTypes.any).isRequired,
  match: matchType.isRequired,
};

export default CaseCard;
