import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import overrideTheme from 'services/themes/overrideTheme';
import AppBar from 'components/common/AppBar';
import LiteArrangementItem from 'components/lite/LiteArrangementItem';
import DashboardBanner from 'components/common/DashboardBanner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ownersFilters, statusesFilters } from 'constants/bereavement';
import Select from 'components/common/Select';
import { matchType } from 'types/reactRouter';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';

import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const LiteArrangementListScreen = ({
  bereavements,
  hasMoreBereavements,
  isOnline,
  isLoading,
  match,
  homes,
  filters,
  getMoreBereavements,
  onFiltersChange,
  onEditCaseStatus,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      <AppBar />
      <div className={classNames(
        styles.u_island,
        rcStyles.tennant_header_bg,
      )}
      >
        <DashboardBanner
          breadcrumbs={[{ path: '/', title: t('Dashboard') }, { path: '/lite-arrangement', title: t('Arrangements') }]}
          title={t('Arrangement list')}
          description={t('View all of your arrangements.')}
        />
      </div>
      <div className={styles.o_view}>
        <div className={styles.o_view__main}>

          <Toolbar className={rcStyles.toolbar}>
            <div>
              <Select
                value={filters.caseStatus}
                onChange={event => onFiltersChange('caseStatus', event.target.value)}
                label={t('Status')}
                inputProps={{ 'data-test-id': 'caseStatus' }}
                disabled={!isOnline}
              >
                {statusesFilters.map(status => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <Select
                value={filters.organisationalUnitId}
                onChange={event => onFiltersChange('organisationalUnitId', event.target.value)}
                label={t('Home')}
                inputProps={{ 'data-test-id': 'homeFilter' }}
                disabled={!isOnline}
              >
                {homes.map(home => (
                  <MenuItem key={home.id} value={home.id}>
                    {home.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <Select
                value={filters.ownerId}
                onChange={event => onFiltersChange('ownerId', event.target.value)}
                label={t('Owner')}
                inputProps={{ 'data-test-id': 'ownerFilter' }}
                disabled={!isOnline}
              >
                {ownersFilters.map(owner => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {owner.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <TextField
                className={styles.u_fullWidth}
                value={filters.searchTerm}
                onChange={event => onFiltersChange('searchTerm', event.target.value)}
                label={t('Search')}
                type="search"
                disabled={!isOnline}
                InputProps={{
                  'data-test-id': 'searchTerm',
                  'startAdornment': (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Toolbar>
        </div>
      </div>
      <div className={classNames(
        styles.o_view,
        styles.is_flush,
      )}
      >
        <div className={styles.o_view__main}>
          {!isLoading && bereavements.length === 0 ? (
            <div className={classNames(
              styles.pica,
              styles.is_primary,
              styles.o_block_content,
            )}
            >
              {t('No arrangements to show')}
            </div>
          ) : (
            <Fragment>
              <InfiniteScroll
                dataLength={bereavements.length}
                hasMore={bereavements.length > 0 && hasMoreBereavements}
                next={getMoreBereavements}
              >
                <div className={rcStyles.v_spacing}>
                  {bereavements.map(bereavementItem => (
                    <LiteArrangementItem
                      bereavement={bereavementItem}
                      key={bereavementItem.id}
                      match={match}
                      onEditCaseStatus={onEditCaseStatus}
                    />
                  ))}
                </div>
              </InfiniteScroll>
              <div>
                {isLoading && (
                  <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                    <IconLoad />
                  </div>
                )}
              </div>
            </Fragment>
          )}
          <Link
            to="/lite-arrangement/create"
            className={rcStyles.fixed_fab}
          >
            <Fab aria-label="add" className={rcStyles.o_tennant_fab}>
              <AddIcon />
            </Fab>
          </Link>
        </div>
      </div>
    </div>
  </MuiThemeProvider>
);

LiteArrangementListScreen.propTypes = {
  bereavements: PropTypes.arrayOf(bereavementType),
  homes: PropTypes.arrayOf(organisationalUnitType),
  hasMoreBereavements: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: matchType.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  getMoreBereavements: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onEditCaseStatus: PropTypes.func.isRequired,
};

export default LiteArrangementListScreen;
