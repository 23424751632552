import { OrderDirection } from 'constants/ts';
import { CatalogueItemOrderStrategy } from 'constants/ts/catalogue';
import { Media, Money } from 'types/ts';

export enum CatalogueItemType {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE'
}

export enum ProductCategory {
  COFFINS = 'COFFINS',
  FLOWERS = 'FLOWERS',
  MEMORIALISATION = 'MEMORIALISATION',
  URNS = 'URNS',
}

export enum ServiceCategory {
  CEMETERIES = 'CEMETERIES',
  CREMATORIA = 'CREMATORIA',
  DISBURSEMENTS = 'DISBURSEMENTS',
  OFFICIANTS = 'OFFICIANTS',
  OTHER = 'OTHER',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  RECEPTION_VENUES = 'RECEPTION_VENUES',
  SERVICE_VENUES = 'SERVICE_VENUES',
  VEHICLES = 'VEHICLES',
}

export type Prices = {
  sale: Money;
  retail: Money;
  cost: Money;
}

export enum VariantStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export type Variant = {
  id: string;
  name: string;
  isDefault?: boolean;
  prices: Prices;
  image?: Media|null;
  status?: VariantStatus|null;
  isGuidePrice?: boolean;
};

interface CatalogueItem {
  id: string;
  title: string;
  subtitle?: string;
  description?: string;
  category?: string;
  catalogueOrder: number;
  catalogueVisibility: boolean;
  images: Media[];
  variants: Variant[];
}

export type Product = CatalogueItem;

export type Service = CatalogueItem;

export type Package = {
  id: string;
  name: string;
  description: string|null;
  price: Money;
  products: Product[];
  services: Service[];
  arrangementVisibility: boolean;
  catalogueVisibility: boolean;
};

export type CatalogueCategory = {
  category: string;
  imagePath: string;
  name: string;
  path: string;
  reference: string;
  type: string;
  visible: boolean;
};

export type CatalogueItemOrder = {
  direction: OrderDirection;
  strategy: CatalogueItemOrderStrategy;
};
