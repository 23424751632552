import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { currencySymbols } from 'constants/currency';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from 'components/admin/AdminCatalogueItemForm/styles.scss';

const AdminCataloguePackageForm = ({
  packageItem,
  disabled,
  errors,
  onChange,
  formRefs,
}) => (
  <Fragment>
    <div className={classNames(
      styles.o_block_content,
      rcStyles.v_spacing,
    )}
    >

      <div
        className={rcStyles.colspan2}
        ref={formRefs.name}
      >
        <TextField
          label={t('Package name *')}
          value={packageItem.name || ''}
          onChange={event => onChange('name', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'packageName',
          }}
          error={errors && errors.name}
          helperText={errors && errors.name}
          disabled={disabled}
        />
      </div>
      <div>
        <TextField
          label={t('Package price')}
          type="number"
          onChange={event => onChange('price', { amount: event.target.value, currency: 'GBP' })}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencySymbols.GBP}
              </InputAdornment>
            ),
            inputProps: {
              'data-test-id': 'cost',
              'step': '.01',
            },
          }}
          defaultValue={parseFloat(packageItem.price.amount || 0).toFixed(2)}
          disabled={disabled}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={t('Package description')}
          value={packageItem.description || ''}
          onChange={event => onChange('description', event.target.value)}
          fullWidth
          multiline
          rows={2}
          inputProps={{
            'data-test-id': 'packageDescription',
          }}
          error={errors && errors.description}
          helperText={errors && errors.description}
          disabled={disabled}
        />
      </div>

      <div className={rcStyles.col1}>
        <TextField
          label={t('Nominal code')}
          value={packageItem.nominalCode || ''}
          onChange={event => onChange('nominalCode', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'nominalCode',
          }}
          disabled={disabled}
        />
      </div>
      <OrganisationalUnitLookup
        value={packageItem.organisationalUnits}
        label="Select home"
        onChange={value => onChange('organisationalUnits', value)}
        isMulti
        data-test-id="homeLookup"
      />
      <div className={rcStyles.col1}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={packageItem.isFixed}
              onChange={event => onChange('isFixed', event.target.checked)}
              inputProps={{ 'data-test-id': 'fixedPackage' }}
              disabled={disabled}
              color="primary"
            />
            )}
          label={t('Fixed package')}
        />
        <p className={styles.brevier}>
          {t('(if the package is fixed no additional products or services can be added to it during the arrangement)')}
        </p>
      </div>
    </div>
  </Fragment>
);

AdminCataloguePackageForm.propTypes = {
  packageItem: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  formRefs: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
};

export default AdminCataloguePackageForm;
