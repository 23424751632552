import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchType, historyType } from 'types/reactRouter';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { blankPackageItem } from 'constants/adminCatalogue';
import {
  clearItemsAction,
  createPackageAction,
  editPackageAction,
  fetchAdminCatalogueItemsAction,
  fetchPackageAction,
  updateFiltersAction,
} from 'actions/catalogue';
import { addCatalogueItemsToPackage } from 'services/utils/catalogue';
import AdminCataloguePackageItemScreen from './AdminCataloguePackageItemScreen';
import { validationSchema } from './validation';

export class AdminCataloguePackageItemScreenContainer extends Component {
  static propTypes = {
    packages: PropTypes.arrayOf(PropTypes.shape({})),
    match: matchType.isRequired,
    isLoading: PropTypes.bool,
    history: historyType,
    isNewItem: PropTypes.bool,
    createPackage: PropTypes.func.isRequired,
    editPackage: PropTypes.func.isRequired,
    fetchPackage: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    generateRefs: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    clearItems: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    fetchAdminCatalogueItems: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      packageItem: {
        ...blankPackageItem,
        isNewItem: true,
      },
      selectedCatalogueItems: [],
      isValidationEnabled: false,
      isPackageItemFormValid: false,
      isLoadingPackageFromState: true,
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    const { match: { params: { itemId } }, packages, isNewItem } = props;
    const {
      clearItems,
      updateFilters,
      fetchAdminCatalogueItems,
    } = props;
    const packageItem = packages.find(item => item.id === itemId);
    let selectedCatalogueItems = [];

    if (packageItem !== undefined) {
      const { products = [], services = [] } = packageItem;
      selectedCatalogueItems = [...products, ...services];
    }

    if (isNewItem || (prevState.packageItem && prevState.packageItem.id)) {
      return null;
    }
    if (!prevState.packageItem.id && packageItem?.id) {
      clearItems('products');
      clearItems('services');
      updateFilters('products', 'organisationalUnitIds', packageItem.organisationalUnitIds);
      updateFilters('services', 'organisationalUnitIds', packageItem.organisationalUnitIds);
      fetchAdminCatalogueItems('products', { clearPagination: true });
      fetchAdminCatalogueItems('services', { clearPagination: true });
    }

    return packageItem ? {
      packageItem,
      selectedCatalogueItems,
      isLoadingPackageFromState: false,
    } : null;
  }

  componentDidMount() {
    const { generateRefs, match: { params: { itemId } }, packages } = this.props;
    const packageItem = packages.find(item => item.id === itemId);

    generateRefs(Object.keys(validationSchema.fields));

    if (itemId && !packageItem) {
      this.getPackage(itemId);
    }

    this.setState(prevState => ({
      packageItem: packageItem || prevState.packageItem,
      isLoadingPackageFromState: false,
    }));
  }

  getPackage = (id) => {
    const { fetchPackage } = this.props;
    fetchPackage(id);
  }

  handleChange = (key, value) => {
    const {
      validate,
      clearItems,
      updateFilters,
      fetchAdminCatalogueItems,
    } = this.props;
    const { packageItem: packageItemFromState, isValidationEnabled } = this.state;
    const packageItem = {
      ...packageItemFromState,
      [key]: value,
    };

    if (key === 'organisationalUnits') {
      const organisationalUnitIds = value.map(item => item.id);
      clearItems('products');
      clearItems('services');
      updateFilters('products', 'organisationalUnitIds', organisationalUnitIds);
      updateFilters('services', 'organisationalUnitIds', organisationalUnitIds);
      fetchAdminCatalogueItems('products', { clearPagination: true });
      fetchAdminCatalogueItems('services', { clearPagination: true });
    }

    if (isValidationEnabled) {
      validate(packageItem, validationSchema);
    }

    this.setState({ packageItem });
  }

  handleMaxQuantityChange = (category, key, value) => {
    this.setState((prevState) => {
      const { packageItem } = prevState;

      const categoryMaxQuantities = packageItem[key] !== undefined ? [...packageItem[key]] : [];
      let hasUpdated = false;
      const updatedCategoryMaxQuantities = categoryMaxQuantities.map((item) => {
        if (item.category !== category) {
          return item;
        }
        hasUpdated = true;
        return {
          ...item,
          value,
        };
      });
      if (!hasUpdated) {
        updatedCategoryMaxQuantities.push({ category, value });
      }

      const updatedPackageItem = { ...packageItem };
      updatedPackageItem[key] = updatedCategoryMaxQuantities;

      return {
        ...prevState,
        packageItem: updatedPackageItem,
      };
    });
  }

  removeMaxQuantity = (category) => {
    const { packageItem: { productCategoryMaxQuantities, serviceCategoryMaxQuantities } } = this.state;

    this.setState(prevState => ({
      packageItem: {
        ...prevState.packageItem,
        productCategoryMaxQuantities: productCategoryMaxQuantities
          .filter(maxQuantity => maxQuantity.category !== category),
        serviceCategoryMaxQuantities: serviceCategoryMaxQuantities
          .filter(maxQuantity => maxQuantity.category !== category),
      },
    }));
  }

  handleSaveCatalogueItemsSelect = (selectedItems) => {
    const { packageItem } = this.state;
    const updatedPackage = addCatalogueItemsToPackage(packageItem, selectedItems);

    this.setState(prevState => ({
      packageItem: updatedPackage,
      selectedCatalogueItems: [
        ...prevState.selectedCatalogueItems,
        ...selectedItems,
      ],
    }));
  }

  handleRemoveCatalogueItem = (id, category) => {
    this.setState((prevState) => {
      const { packageItem } = prevState;
      const services = packageItem.services.filter(service => service.id !== id);
      const products = packageItem.products.filter(product => product.id !== id);

      return {
        selectedCatalogueItems: prevState.selectedCatalogueItems
          .filter(catalogueItem => catalogueItem.id !== id),
        packageItem: {
          ...packageItem,
          services,
          products,
          serviceIds: services.map(service => service.id),
          productIds: products.map(product => product.id),
        },
      };
    }, () => {
      const { selectedCatalogueItems } = this.state;
      const amountInCategory = selectedCatalogueItems.filter(item => item.category === category).length;
      if (amountInCategory === 0) {
        this.removeMaxQuantity(category);
      }
    });
  }

  setIsPackageItemFormValid = (isPackageItemFormValid) => {
    this.setState({ isPackageItemFormValid });
  }

  handleSave = () => {
    const {
      validate,
      createPackage,
      editPackage,
      history,
      isNewItem,
    } = this.props;
    const { packageItem } = this.state;

    this.setState({ isValidationEnabled: true });

    const isPackageItemFormValid = validate(packageItem, validationSchema, true);
    const saveAction = isNewItem ? createPackage : editPackage;

    if (isPackageItemFormValid) {
      saveAction(packageItem);
      history.push('/catalogue/packages');
    }
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue/packages');
  }

  render() {
    const {
      isNewItem,
      isLoading,
      errors,
      formRefs,
    } = this.props;
    const {
      packageItem,
      selectedCatalogueItems,
      isPackageItemFormValid,
      isLoadingPackageFromState,
      isValidationEnabled,
    } = this.state;
    return (
      <div>
        <AdminCataloguePackageItemScreen
          packageItem={packageItem}
          selectedCatalogueItems={selectedCatalogueItems}
          isLoading={isLoading || isLoadingPackageFromState}
          isNewItem={isNewItem}
          isValidationEnabled={isValidationEnabled}
          isPackageItemFormValid={isPackageItemFormValid}
          setIsValid={this.setIsPackageItemFormValid}
          onSaveCatalogueItemsSelect={this.handleSaveCatalogueItemsSelect}
          onRemoveCatalogueItem={this.handleRemoveCatalogueItem}
          onMaxQuantityChange={this.handleMaxQuantityChange}
          onChange={this.handleChange}
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          errors={errors}
          formRefs={formRefs}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  packages: state.catalogueStore.packages.items,
  isLoading: state.catalogueStore.packages.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createPackage: createPackageAction,
  editPackage: editPackageAction,
  fetchPackage: fetchPackageAction,
  updateFilters: updateFiltersAction,
  clearItems: clearItemsAction,
  fetchAdminCatalogueItems: fetchAdminCatalogueItemsAction,
}, dispatch);

export default withValidation(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminCataloguePackageItemScreenContainer),
  ),
);
