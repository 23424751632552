import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Assignment from '@material-ui/icons/Assignment';
import Apps from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  clearAllAction,
  fetchArrangementCatalogueItemsAction,
  fetchArrangementCataloguePackagesAction,
} from 'actions/catalogue';
import { featureFlags } from 'constants/features';

import AdminCatalogueMenuScreen from './AdminCatalogueMenuScreen';

export class AdminCatalogueMenuScreenContainer extends Component {
  static propTypes = {
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    clearAll: PropTypes.func.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    fetchPackageItems: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      clearAll,
      fetchCatalogueItems,
      fetchPackageItems,
    } = this.props;
    clearAll();
    fetchCatalogueItems('services');
    fetchCatalogueItems('products');
    fetchPackageItems();
  }

  render() {
    const { availableFeatures } = this.props;

    const cards = [
      {
        title: 'Services',
        text: 'Create, edit and manage your services',
        icon: BusinessCenter,
        link: '/services',
        testId: 'services',
        disabled: false,
      },
      {
        title: 'Products',
        text: 'Create, edit and manage individual products',
        icon: Assignment,
        link: '/products',
        testId: 'products',
        disabled: false,
      },
      {
        title: 'Packages',
        text: 'Create packages from your services and products',
        icon: Apps,
        link: '/packages',
        testId: 'packages',
        disabled: false,
      },
      {
        title: 'Brochure settings',
        text: 'Choose which product/service sections to include and the order in which they appear',
        icon: SettingsIcon,
        link: '/settings',
        testId: 'catalogue-settings',
        disabled: false,
      },
      {
        title: 'Arrangement Settings',
        text: 'Choose the order in which your products and services will appear within an arrangement',
        icon: SettingsIcon,
        link: '/arrangement-settings',
        testId: 'arrangement-settings',
        disabled: !availableFeatures.includes(featureFlags.CATALOGUE_ARRANGEMENT_SETTINGS_SCREEN),
      },
    ];

    return (
      <AdminCatalogueMenuScreen {...this.props} cards={cards} />
    );
  }
}

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearAll: clearAllAction,
  fetchCatalogueItems: fetchArrangementCatalogueItemsAction,
  fetchPackageItems: fetchArrangementCataloguePackagesAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(AdminCatalogueMenuScreenContainer),
);
