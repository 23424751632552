import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { nameType } from 'types/common';
import NameFormSection from './NameFormSection';

const defaultTitles = [
  'Mr', 'Mrs', 'Miss', 'Ms', 'Master', 'Other',
];
class NameFormSectionContainer extends Component {
  static propTypes = {
    name: nameType.isRequired,
    disabled: PropTypes.bool,
    hidetitle: PropTypes.bool,
    labelTitle: PropTypes.string,
    labelGivenName: PropTypes.string,
    labelMiddleName: PropTypes.string,
    labelFamilyName: PropTypes.string,
    titles: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.objectOf(PropTypes.any),
    onChangeHandler: PropTypes.func.isRequired,
  }

  static defaultProps = {
    labelTitle: 'Title',
    labelGivenName: 'Given Name',
    labelMiddleName: 'Middle Names',
    labelFamilyName: 'Family Name',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate = (nextProps) => {
    const { name, errors, disabled } = this.props;
    return (name.title !== nextProps.name.title
      || name.givenName !== nextProps.name.givenName
      || name.middleName !== nextProps.name.middleName
      || name.familyName !== nextProps.name.familyName)
      || errors !== nextProps.errors
      || disabled !== nextProps.disabled;
  }

  onChangeHandler = (property, value) => {
    const { name, onChangeHandler } = this.props;

    return onChangeHandler({
      ...name,
      [property]: value,
    });
  }

  render() {
    const { titles } = this.props;
    return (
      <NameFormSection
        {...this.props}
        titles={titles || defaultTitles}
        onChangeHandler={this.onChangeHandler}
      />
    );
  }
}

export default NameFormSectionContainer;
