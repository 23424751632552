import { ApolloLink } from 'apollo-link';
import { store } from 'services/state';
import { addRequestsAction } from 'actions/request';

const requestQueueMiddleware = new ApolloLink((operation, forward) => {
  const { isOnline } = store.getState().userStore;
  const type = operation.query.definitions[0].operation;

  if (!isOnline && type === 'mutation' && process.env.FEATURE_FLAG_OFFLINE_FUNCTIONALITY) {
    store.dispatch(addRequestsAction([operation]));
    return null;
  }

  return forward(operation);
});

export default requestQueueMiddleware;
