// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".chartist-tooltip{background-color:#fff;box-shadow:0 0px 6px rgba(0,0,0,0.24),0 0px 0px rgba(0,0,0,0.12);border-left:2px solid #7e5da2;border-radius:2px;display:inline-block;min-width:5em;padding:.5em;pointer-events:none;position:absolute;text-align:left;opacity:0;-webkit-transition:opacity .2s linear;-moz-transition:opacity .2s linear;-o-transition:opacity .2s linear;transition:opacity .2s linear;z-index:1}.chartist-tooltip:before{border:7px solid transparent;border-top-color:#fff;content:\"\";height:0;left:50%;margin-left:-7px;position:absolute;top:100%;width:0}.chartist-tooltip.tooltip-show{opacity:1}.chartist-tooltip>.is_value{font-size:17px;line-height:1.58;font-weight:600;color:#7e5da2;display:block}@media (min-width: 768px){.chartist-tooltip>.is_value{font-size:19px}}.chartist-tooltip>.is_label{font-size:14px;line-height:1.5;font-weight:300;display:block}\n", ""]);
// Exports
exports.locals = {
	"unitWhole": "1rem",
	"unitHalf": ".5rem",
	"unitDouble": "2rem",
	"colorError": "#f44034",
	"colorWarning": "#ffca38",
	"colorPrimary": "#7e5da2",
	"colorButtonSecondary": "#faf9fb",
	"colorGrey": "#bfbcc2",
	"colorGreyLight": "#e5e4e7",
	"colorGreyDark": "#a6a1aa",
	"colorGreyUltraLight": "#f2f0f5",
	"colorSecondaryDarker": "#0d6a9c",
	"colorOffWhite": "#faf9fb",
	"colorBlue": "#108acb",
	"colorWhite": "#fff",
	"colorHighlight": "#108acb",
	"colorBadge": "#6196d6",
	"typographyPrimary": "\"Tondo\",sans-serif",
	"typographyBold": "600"
};
module.exports = exports;
