import React from 'react';
import PropTypes from 'prop-types';
import { currencySymbols } from 'constants/currency';
import { isNullOrUndefined } from 'services/utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const CurrencyField = ({
  defaultValue, InputProps, inputProps, min, max, nullable, onChange, onBlur, value, ...otherProps
}) => {
  const parseAmount = amount => (!isNullOrUndefined(amount) ? parseFloat(amount).toFixed(2) : undefined);
  return (
    <TextField
      {...otherProps}
      type="number"
      defaultValue={(!isNullOrUndefined(value) && parseAmount(value)) || parseAmount(defaultValue)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {currencySymbols.GBP}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        'step': '.01',
        ...inputProps,
      }}
      onChange={(event) => {
        if (event.target.value.length === 2
          && event.target.value.charAt(0) === '0'
          && event.target.value.charAt(1) !== '.') {
          const e = event;
          e.target.value = event.target.value.charAt(1);
        }

        let parsedValue = !(nullable && event.target.value.length === 0) ? parseFloat(event.target.value || 0) : null;
        const isValidValue = !isNullOrUndefined(parsedValue);

        if (min !== undefined && isValidValue && parsedValue < min) {
          parsedValue = min;
        }
        if (max !== undefined && isValidValue && parsedValue > max) {
          parsedValue = max;
        }
        if (onChange) {
          onChange(parsedValue, event);
        }
      }}
      onBlur={(event) => {
        const e = event;
        let parsedValue = !(nullable && e.target.value.length === 0) ? parseFloat(e.target.value || 0) : null;
        const isValidValue = !isNullOrUndefined(parsedValue);
        if (min !== undefined && isValidValue && parsedValue < min) {
          parsedValue = min;
        }
        if (max !== undefined && isValidValue && parsedValue > max) {
          parsedValue = max;
        }
        e.target.value = isValidValue && parsedValue.toFixed(2);
        const returnValue = isValidValue ? parseFloat(parseFloat(parsedValue).toFixed(2)) : null;
        if (onBlur) {
          onBlur(returnValue, e);
        } else if (onChange) {
          onChange(returnValue, e);
        }
      }}
      data-test-id="currencyField"
    />
  );
};

CurrencyField.propTypes = {
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  nullable: PropTypes.bool,
  InputProps: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default CurrencyField;
