import mapActionToReducer from 'redux-action-reducer-mapper';
import { requestQueueActions } from 'actions/request';

export const initialState = {
  requests: [],
  isDispatching: false,
  processingOfRequestInQueueFailed: false,
  locationBeforeError: null,
};

const clearRequests = state => ({
  ...state,
  requests: [],
});

const setProcessingOfRequestInQueueFailed = (state, action) => ({
  ...state,
  processingOfRequestInQueueFailed: action.payload,
});

const setRequests = (state, action) => {
  const requests = action.payload;
  return {
    ...state,
    requests,
  };
};

const addRequests = (state, action) => {
  const requests = action.payload;
  return {
    ...state,
    requests: [...state.requests, ...requests],
  };
};

const removeRequest = (state) => {
  const requests = [...state.requests];
  requests.shift();
  return {
    ...state,
    requests,
  };
};

const setIsDispatching = (state, action) => {
  const isDispatching = action.payload;
  return {
    ...state,
    isDispatching,
  };
};

const handleWebsocketMessage = (state, action) => {
  if (state.processingOfRequestInQueueFailed) {
    return state;
  }

  return {
    ...state,
    processingOfRequestInQueueFailed: action.payload.message.type === 'ERROR',
  };
};

const setLocationBeforeError = (state, action) => ({
  ...state,
  locationBeforeError: action.payload,
});

export const requestQueueReducer = mapActionToReducer({
  default: initialState,
  [requestQueueActions.CLEAR_REQUESTS]: clearRequests,
  [requestQueueActions.SET_REQUESTS]: setRequests,
  [requestQueueActions.ADD_REQUESTS]: addRequests,
  [requestQueueActions.REMOVE_REQUEST]: removeRequest,
  [requestQueueActions.SET_IS_DISPATCHING]: setIsDispatching,
  [requestQueueActions.HANDLE_WEBSOCKET_MESSAGE]: handleWebsocketMessage,
  [requestQueueActions.SET_LOCATION_BEFORE_ERROR]: setLocationBeforeError,
  [requestQueueActions.SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED]: setProcessingOfRequestInQueueFailed,
});
