import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { defaultMoneyObject } from 'services/utils';
import CurrencyField from 'components/common/CurrencyField';
import DateTimePicker from 'components/common/DateTimePicker';
import Select from 'components/common/Select';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import DirectoryListingCard from 'components/common/cards/DirectoryListingCard';
import PayeeListModal from 'components/account/PayeeListModal';
import PayeeFormModal from 'components/account/PayeeFormModal';
import IconDanger from 'icons/IconDanger';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { invoiceType } from 'types/account';
import { reactRefType } from 'types/common';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import { paymentMethods } from 'constants/account';
import InvoiceLookup from 'components/common/lookups/InvoiceLookup';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AccountAddPaymentModal = ({
  bereavedPeopleConnections,
  formData,
  isOpen,
  isEditingPayment,
  isEditingPayee,
  selectedBereaved,
  selectedDirectoryListing,
  hasPayeeAddressError,
  isPayeeListModalOpen,
  isPayeeFormModalOpen,
  togglePayeeListModal,
  togglePayeeFormModal,
  onPayeeChange,
  onChange,
  onCancel,
  onSave,
  createBereavedPerson,
  editBereavedPerson,
  invoices,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby={t('payment-modal-title')}
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCancel}
          data-test-id="feeClose"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {isEditingPayment ? t('Edit payment') : t('Add payment')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            data-test-id="saveCase"
          >
            {isEditingPayment ? t('Save') : t('Add Payment')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent
        className={classNames(
          styles.c_dialog__content,
          styles.u_hard__bottom,
          styles.u_push__top,
        )}
      >
        <div
          ref={formRefs.payeeId}
          className={classNames(
            rcStyles.v_spacing,
            styles.o_block_content,
          )}
        >
          <h3 className={classNames(
            styles.long_primer,
            styles.is_black,
          )}
          >
            {t('Payee details')}
          </h3>
          <div>
            <Button
              data-test-id={t('changePayee')}
              onClick={togglePayeeListModal}
              color="primary"
              variant="contained"
            >
              {t('Change Payee')}
            </Button>
          </div>
          <div>
            {hasPayeeAddressError && (
              <div className={classNames(styles.c_error)}>
                <IconDanger className={classNames(styles.c_error__icon)} />
                <span className={classNames(styles.c_error__msg)}>
                  <Typography color="error">{t('Address required for payee')}</Typography>
                </span>
              </div>
            )}
            {selectedBereaved && (
              <BereavedPersonCard
                bereavedPersonConnection={selectedBereaved}
                customClass={rcStyles.rc_custom_bg}
                onEdit={() => togglePayeeFormModal(true)}
              />
            )}
            {selectedDirectoryListing && (
              <DirectoryListingCard
                directoryListing={selectedDirectoryListing}
                customClass={rcStyles.rc_custom_bg}
              />
            )}
            {errors && (errors.payeeId) && (
              <FormHelperText error>{errors.payeeId}</FormHelperText>
            )}
            {errors && (errors.payeeType) && (
              <FormHelperText error>{errors.payeeType}</FormHelperText>
            )}
          </div>
          <h3 className={classNames(
            styles.long_primer,
            styles.is_black,
          )}
          >
            {t('Payment details')}
          </h3>
          <div className={styles.u_push__bottom}>
            <InvoiceLookup
              label={t('Select invoice to add payment to')}
              localInvoices={invoices && invoices.filter(invoice => !invoice.dateVoided)}
              value={formData.invoice}
              onChange={invoice => onChange('invoice', (invoice && invoice.value) || null)}
              isClearable
              data-test-id="invoiceLookup"
            />
          </div>
          <div
            ref={formRefs.dateTime}
          >
            <DateTimePicker
              value={formData.dateTime}
              label={t('Date paid *')}
              onChange={date => onChange('dateTime', date)}
              showTimeSelect={false}
              displayFormat="dd/MM/yyyy"
              isClearable
              data-test-id="date"
              errors={errors && !!errors.dateTime}
              helperText={errors && errors.dateTime}
            />
          </div>
          <div
            ref={formRefs.amount}
          >
            <CurrencyField
              label={t('Amount paid *')}
              margin="normal"
              min={0}
              value={formData.amount && formData.amount.amount}
              InputProps={{
                inputProps: {
                  'data-test-id': 'amount',
                },
              }}
              onChange={value => onChange('amount', defaultMoneyObject(value))}
              error={errors.amount && !!errors.amount.amount}
              helperText={errors.amount && errors.amount.amount}
              fullWidth
            />
          </div>
          <div
            ref={formRefs.method}
          >
            <Select
              label={t('Method *')}
              value={formData.method}
              onChange={event => onChange('method', event.target.value)}
              inputProps={{
                'data-test-id': 'method',
              }}
              fullWidth
              errorText={errors.method}
            >
              {paymentMethods.map(paymentMethod => (
                <MenuItem
                  key={paymentMethod.id}
                  value={paymentMethod.id}
                >
                  {paymentMethod.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            ref={formRefs.reference}
          >
            <TextField
              label={t('Reference')}
              margin="normal"
              value={formData.reference || ''}
              onChange={event => onChange('reference', event.target.value)}
              inputProps={{
                'data-test-id': 'reference',
              }}
              fullWidth
            />
          </div>
          <PayeeListModal
            bereavedPeopleConnections={bereavedPeopleConnections}
            isOpen={isPayeeListModalOpen}
            isEditingPayee={isEditingPayee}
            selectedBereaved={selectedBereaved}
            selectedDirectoryListing={selectedDirectoryListing}
            payeeType={formData.payeeType}
            savePayeeChange={onPayeeChange}
            onClose={togglePayeeListModal}
            createBereavedPerson={createBereavedPerson}
            editBereavedPerson={editBereavedPerson}
          />
          <PayeeFormModal
            isOpen={isPayeeFormModalOpen}
            isAdding={false}
            isSaving={false}
            isEditingPayee={isEditingPayee}
            payee={selectedBereaved}
            onClose={togglePayeeFormModal}
            onSave={editBereavedPerson}
          />
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

AccountAddPaymentModal.propTypes = {
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditingPayment: PropTypes.bool.isRequired,
  isEditingPayee: PropTypes.bool,
  selectedBereaved: bereavedPersonConnectionType,
  selectedDirectoryListing: directoryListingType,
  hasPayeeAddressError: PropTypes.bool.isRequired,
  isPayeeListModalOpen: PropTypes.bool.isRequired,
  isPayeeFormModalOpen: PropTypes.bool.isRequired,
  onPayeeChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  togglePayeeListModal: PropTypes.func.isRequired,
  togglePayeeFormModal: PropTypes.func.isRequired,
  createBereavedPerson: PropTypes.func.isRequired,
  editBereavedPerson: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(invoiceType),
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default AccountAddPaymentModal;
