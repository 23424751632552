import React from 'react';
import { onError } from 'apollo-link-error';
import { t } from 'i18next';
import { store } from 'services/state';
import { enqueueSnackbarAction } from 'actions/snackbar';
import { addRequestsAction } from 'actions/request';
import Button from '@material-ui/core/Button';

const parseErrorMessage = (graphQLErrors) => {
  const defaultErrorMessage = t('An error has occured.');
  if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
    const domainExceptionPrefix = 'Domain exception: ';
    const { message } = graphQLErrors[0];
    if (message.indexOf(domainExceptionPrefix) === 0) {
      return message.replace(domainExceptionPrefix, '');
    }
  }
  return defaultErrorMessage;
};

const errorHandlingLink = onError(({
  graphQLErrors,
  networkError,
  operation,
}) => {
  if (networkError && networkError.message) {
    if (operation.query.definitions[0].operation === 'mutation' && process.env.FEATURE_FLAG_OFFLINE_FUNCTIONALITY) {
      store.dispatch(addRequestsAction([operation]));
    }
    return;
  }

  store.dispatch(enqueueSnackbarAction({
    message: parseErrorMessage(graphQLErrors),
    options: {
      variant: 'error',
      persist: true,
      action: (
        <Button size="small" data-test-id="dismiss">{t('Dismiss')}</Button>
      ),
    },
  }));
});

export default errorHandlingLink;
