import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';

import { vehicleTypes } from 'constants/service';
import { vehiclesInfoType } from 'types/bereavement';

import Select from 'components/common/Select';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

import VehicleJourney from './VehicleJourney';

const VehiclesList = ({
  vehicles,
  disabled,
  onRemoveVehicle,
  onUpdateVehicle,
  onCreateVehicleJourney,
  onUpdateVehicleJourney,
  onRemoveVehicleJourney,
  onDuplicateVehicleJourney,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <Fragment>
      {vehicles.map((vehicle, vehicleIndex) => (
        <div
          data-test-id="cortegeJourney"
          key={`veh-${vehicle.id}`}
          className={rcStyles.journey}
        >
          <div
            className={rcStyles.is_header}
            data-test-id="journeyType"
          >
            <h3 className={styles.long_primer}>
              {vehicle.isHearse ? t('Hearse') : t('Passenger Vehicle')}
            </h3>
            <Fragment>
              <IconButton
                aria-controls="edit-menu"
                aria-haspopup="true"
                data-test-id="vehicleJourneyOptions"
                onClick={event => setAnchorElement(event.target)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElement}
                keepMounted
                open={!!anchorElement}
                onClose={() => setAnchorElement()}
              >
                <MenuItem
                  data-test-id="delete"
                  onClick={() => {
                    onRemoveVehicle(vehicle.id);
                    setAnchorElement();
                  }}
                >
                  {t('Delete')}
                </MenuItem>
                <MenuItem
                  data-test-id="duplicateAsPassengerJourney"
                  onClick={() => {
                    onDuplicateVehicleJourney(vehicle, vehicleTypes.PASSENGER_VEHICLE);
                    setAnchorElement();
                  }}
                >
                  {t('Duplicate as passenger journey')}
                </MenuItem>
                <MenuItem
                  data-test-id="duplicateAsHearseJourney"
                  onClick={() => {
                    onDuplicateVehicleJourney(vehicle, vehicleTypes.HEARSE);
                    setAnchorElement();
                  }}
                >
                  {t('Duplicate as hearse journey')}
                </MenuItem>
              </Menu>
            </Fragment>
          </div>
          {!vehicle.errors && vehicle.errors.journeys && vehicle.errors.journeys.msg && (
            <div className={rcStyles.has_error}>
              {vehicle.errors.journeys.msg}
            </div>
          )}
          {!vehicle.isHearse && (
            <div className={rcStyles.passenger_count}>
              <Select
                disabled={disabled}
                name="passengerNumbers"
                value={vehicle.noOfPassengers.toString()}
                label={t('No. of passengers')}
                onChange={event => onUpdateVehicle(vehicle.id, {
                  index: vehicleIndex,
                  item: { noOfPassengers: event.target.value },
                })}
                inputProps={{ 'data-test-id': 'passengerNumbers' }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map(passengerNumber => (
                  <MenuItem key={passengerNumber} value={passengerNumber}>{passengerNumber}</MenuItem>
                ))}
              </Select>
            </div>
          )}
          <Fragment>
            {vehicle.journeys.map((journey, journeyIndex) => {
              const journeyErrors = vehicle.errors
                && vehicle.errors.journeys
                && vehicle.errors.journeys[journeyIndex];
              return (
                <Fragment key={`veh-journey-${journeyIndex}`}>
                  <div className={rcStyles.journey__item}>
                    <VehicleJourney
                      journey={journey}
                      disabled={disabled}
                      vehicleId={vehicle.id}
                      errors={journeyErrors}
                      onSelectTypeChange={event => onUpdateVehicleJourney(vehicle.id, {
                        index: journeyIndex,
                        item: { type: event.target.value },
                      })}
                      onAddressChange={address => onUpdateVehicleJourney(vehicle.id, {
                        index: journeyIndex,
                        item: { location: { ...address } },
                      })}
                      onDatePickerChange={dateTime => onUpdateVehicleJourney(vehicle.id, {
                        index: journeyIndex,
                        item: { dateTime },
                      })}
                    />
                    <div className={rcStyles.action}>
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        disabled={disabled}
                        aria-label={t('Remove route')}
                        onClick={() => onRemoveVehicleJourney(
                          vehicle.id,
                          journey.id,
                        )}
                        className={classNames(styles.o_button__round)}
                        data-test-id="removeRoute"
                      >
                        <RemoveIcon />
                      </Button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
            {vehicles.length > 0 && (
              <Button
                color="primary"
                size="medium"
                disabled={disabled}
                variant="outlined"
                className={classNames(
                  styles.u_push__top,
                )}
                onClick={() => onCreateVehicleJourney(vehicle.id)}
                data-test-id="addJourney"
              >
                <AddIcon className={styles.u_push__right_half} />
                {t('Add to journey')}
              </Button>
            )}
          </Fragment>
        </div>
      ))}
    </Fragment>
  );
};

VehiclesList.propTypes = {
  vehicles: PropTypes.arrayOf(vehiclesInfoType),
  disabled: PropTypes.bool.isRequired,
  onUpdateVehicle: PropTypes.func,
  onRemoveVehicle: PropTypes.func.isRequired,
  onCreateVehicleJourney: PropTypes.func.isRequired,
  onUpdateVehicleJourney: PropTypes.func.isRequired,
  onRemoveVehicleJourney: PropTypes.func.isRequired,
  onDuplicateVehicleJourney: PropTypes.func.isRequired,
};

export default VehiclesList;
