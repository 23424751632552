import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import RemoveIcon from '@material-ui/icons/Remove';

import ArrangementOfficiantDirectoryListingSection from 'components/arrangement/ArrangementOfficiantDirectoryListingSection';
import ArrangementSelectedItemAvatar from 'components/arrangement/ArrangementSelectedItemAvatar';
import ContactCard from 'components/common/ContactCard';
import Price from 'components/common/Price';
import DirectoryListingContactDetails from 'components/directoryListings/DirectoryListingContactDetails';
import { variantStatuses } from 'constants/catalogue';
import { getCatalogueVariantImage } from 'services/utils';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import ArrangementOfficiantVenueLookup from '../ArrangementOfficiantVenueLookup';

const ArrangementOfficiantSelectedItem = ({
  item,
  errors,
  arrangement,
  disabled,
  onChangeVariant,
  onChangeCustomDetails,
  onChangeDirectoryListingId,
  onCreateDirectoryListing,
  onEditDirectoryListing,
  onRemove,
  onToggleServiceVenue,
}) => {
  const {
    id,
    service,
    variantId,
    categoryMetaData: { officiant },
  } = item || {};
  const { supplier, variants } = service || {};
  const activeVariants = variants?.filter(variant => variant.status === variantStatuses.ACTIVE);
  const displayDirectoryListingSection = !supplier
    || !!officiant?.directoryListingId
    || !!officiant?.customDetails;
  const customDetailsErrors = errors?.categoryMetaData?.officiant?.customDetails;

  return (
    <div className={rcStyles.item}>
      <div className={rcStyles.item__container}>
        <div className={rcStyles.item__main}>
          <div className={rcStyles.item__details}>
            <ContactCard
              name={service?.title}
              image={(
                <ArrangementSelectedItemAvatar
                  image={getCatalogueVariantImage(service, variantId)}
                  title={service?.title}
                  category={service?.category}
                  classes={rcStyles.is_selected_icon}
                />
              )}
              details={(
                <DirectoryListingContactDetails directoryListing={supplier} />
              )}
            />
          </div>
          <div className={rcStyles.item__variant}>
            {activeVariants?.length > 1 && (
              <Select
                label={t('Variations')}
                value={variantId}
                onChange={event => onChangeVariant(event.target.value)}
                inputProps={{
                  'data-test-id': 'variations',
                }}
                fullWidth
                disabled={disabled}
              >
                {activeVariants?.map(variant => (
                  <MenuItem key={variant.id} value={variant?.id}>
                    {variant?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          <div className={rcStyles.item__price}>
            <Price
              variants={service?.variants}
              selectedVariantId={variantId}
              customClass={styles.c_price_label}
            />
          </div>
        </div>
        <div className={rcStyles.item__attending_service}>
          <ArrangementOfficiantVenueLookup
            arrangement={arrangement}
            disabled={disabled}
            venueSelectionIds={officiant?.venueSelectionIds}
            onToggleServiceVenue={onToggleServiceVenue}
          />
        </div>
        {displayDirectoryListingSection && (
          <div className={rcStyles.item__directory_listing_details}>
            <ArrangementOfficiantDirectoryListingSection
              directoryListingId={officiant?.directoryListingId}
              customDetails={officiant?.customDetails}
              errors={customDetailsErrors}
              disabled={disabled}
              onChangeCustomDetails={onChangeCustomDetails}
              onChangeDirectoryListingId={onChangeDirectoryListingId}
              onCreateDirectoryListing={onCreateDirectoryListing}
              onEditDirectoryListing={onEditDirectoryListing}
            />
          </div>
        )}
      </div>
      <div className={rcStyles.item__remove}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          data-test-id="remove"
          disabled={disabled}
          onClick={() => onRemove(id)}
          className={styles.o_button__round}
        >
          <RemoveIcon />
        </Button>
      </div>
    </div>
  );
};

ArrangementOfficiantSelectedItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  arrangement: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  onChangeVariant: PropTypes.func.isRequired,
  onChangeCustomDetails: PropTypes.func.isRequired,
  onChangeDirectoryListingId: PropTypes.func.isRequired,
  onCreateDirectoryListing: PropTypes.func.isRequired,
  onEditDirectoryListing: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onToggleServiceVenue: PropTypes.func.isRequired,
};

export default ArrangementOfficiantSelectedItem;
