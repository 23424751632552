import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import tableStyles from './tableStyles.scss';

class TableView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
    };
  }

  getHeader = headingValues => (
    <div className={tableStyles.table_top}>
      <div className={classNames(
        tableStyles.table_layout,
        tableStyles.is_row,
      )}
      >
        {headingValues.map((heading, i) => {
          const isFirst = (i === 0) ? tableStyles.is_first : '';
          return (
            <div className={classNames(
              tableStyles.is_label,
              tableStyles.is_cell,
              isFirst,
            )}
            >
              {t(heading.displayName)}
            </div>
          );
        })}
      </div>
    </div>
  )

  getBody = rowValues => (
    <div className={tableStyles.table_main_course}>
      {rowValues.map(row => (
        <div className={classNames(
          tableStyles.table_layout,
          tableStyles.is_row,
        )}
        >
          {this.getRow(row).map((value, i) => {
            const isFirst = (i === 0) ? tableStyles.is_first : '';
            return (
              <div className={classNames(
                tableStyles.is_label,
                tableStyles.is_cell,
                isFirst,
              )}
              >
                {t(value)}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  )

  getFooter = (footerValues) => {
    if (footerValues) {
      return (
        <div className={tableStyles.table_base}>
          <div className={tableStyles.table_serving}>
            <div className={classNames(
              tableStyles.table_layout,
              tableStyles.is_row,
            )}
            >
              {this.getRow(footerValues).map((footerValue, i) => {
                const isFirst = (i === 0) ? tableStyles.is_first : '';
                return (
                  <div className={classNames(
                    tableStyles.is_cell,
                    isFirst,
                  )}
                  >
                    {(i === 0) ? t('Total') : t(footerValue)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    return false;
  }

  getRow = (rowData, nullValue) => {
    const { chartData } = this.state;
    const whenNull = nullValue || '';

    return chartData.headings.map(heading => (
      t(rowData[heading.valueKey] || whenNull)
    ));
  }

  render = () => {
    const { chartData } = this.state;

    return (
      <div className={tableStyles.table_set}>
        {this.getHeader(chartData.headings)}
        {this.getBody(chartData.values)}
        {this.getFooter(chartData.footer)}
      </div>
    );
  }
}

TableView.propTypes = {
  chartData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default TableView;
