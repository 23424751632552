import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CategoryLookup from 'components/common/lookups/CategoryLookup';
import AdminCatalogueItemVariantList from 'components/admin/AdminCatalogueItemVariantList';
import CatalogueItemPreviewModal from 'components/catalogue/CatalogueItemPreviewModal';
import CatalogueItemTagsLookup from 'components/common/lookups/CatalogueItemTagsLookup';
import AddressFormSection from 'components/common/AddressFormSection';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DirectoryListingLookup from 'components/common/lookups/DirectoryListingLookup';
import MediaUpload from 'components/common/MediaUpload';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { categories } from 'constants/arrangement';
import { vehicleTypeLabels, vehicleTypes } from 'constants/service';
import {
  catalogueProductCategories,
  catalogueServiceCategories,
} from 'constants/catalogue';
import { mediaType } from 'types/media';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import Select from 'components/common/Select';
import MenuItem from '@material-ui/core/MenuItem';
import rcStyles from './styles.scss';

const AdminCatalogueItemForm = ({
  isServiceCategory,
  catalogueItemType,
  disabled,
  formData,
  selectedImage,
  isNewItem,
  isPreviewModalOpen,
  selectedVariantForImage,
  isAddressRequired,
  hasXeroIntegration,
  onChange,
  onImageUpload,
  onChangeVariant,
  onAddVariant,
  onDeleteVariant,
  onCancel,
  togglePreviewModal,
  toggleSelectImageModal,
  onSave,
  errors,
  formRefs,
  onChangeImages,
  onSelectVariantImage,
  onSaveVariantImage,
}) => (
  <div className={styles.o_view}>
    <div className={classNames(
      styles.o_view__main,
      styles.o_block_content,
      rcStyles.v_spacing,
    )}
    >
      <div className={rcStyles.colspan2}>
        <CategoryLookup
          value={formData.category || []}
          label="Select category *"
          isClearable={false}
          disabled={!isNewItem}
          onChange={value => onChange('category', value.value || value)}
          categories={isServiceCategory
            ? catalogueServiceCategories
            : catalogueProductCategories}
          data-test-id="categoryLookup"
        />
        {errors && errors.category && (
          <FormHelperText className={rcStyles.rc_error} error>{errors.category}</FormHelperText>
        )}
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={t('Title *')}
          value={formData.title || ''}
          onChange={event => onChange('title', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'title',
          }}
          error={errors && !!errors.title}
          helperText={errors && errors.title}
          disabled={disabled}
          className={rcStyles.col1}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={t('Subtitle')}
          value={formData.subtitle || ''}
          onChange={event => onChange('subtitle', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'subtitle',
          }}
          disabled={disabled}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={t('Description')}
          value={formData.description || ''}
          onChange={event => onChange('description', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'description',
          }}
          disabled={disabled}
          multiline
          rows={3}
          rowsMax={9}
        />
      </div>

      <div className={rcStyles.colspan2}>
        <TextField
          label={t('Supplier reference')}
          value={formData.reference || ''}
          onChange={event => onChange('reference', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'reference',
          }}
          disabled={disabled}
        />
      </div>
      {hasXeroIntegration && (
        <div>
          <TextField
            label={t('Nominal code')}
            value={formData.nominalCode || ''}
            onChange={event => onChange('nominalCode', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'nominalCode',
            }}
            disabled={disabled}
          />
        </div>
      )}
      <div className={rcStyles.col1}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.salesTaxBand === 'FULL'}
              onChange={event => onChange('salesTaxBand', event.target.checked ? 'FULL' : 'ZERO')}
              inputProps={{ 'data-test-id': 'salesTaxBand' }}
              disabled={disabled}
              color="primary"
            />
            )}
          label={t('Sale price includes tax')}
        />
      </div>
      {formData.category.category !== categories.DISBURSEMENTS && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.displayAsDisbursementToBereaved}
              onChange={event => onChange('displayAsDisbursementToBereaved', event.target.checked)}
              inputProps={{ 'data-test-id': 'displayAsDisbursementToBereaved' }}
              disabled={disabled}
              color="primary"
            />
            )}
          label={t('Display as disbursement to bereaved')}
        />
      )}
      {formData.category.category === categories.VEHICLES && (
        <div className={classNames(
          rcStyles.rc_layer_order_0,
          rcStyles.colspan2,
        )}
        >
          <Select
            label={t('Vehicle Type')}
            value={formData.vehicleType}
            onChange={event => onChange('vehicleType', event.target.value)}
            inputProps={{
              'data-test-id': 'vehicleType',
            }}
            fullWidth
          >
            <MenuItem
              value=""
              data-test-id="nullMenuItem"
            >
              {t('None')}
            </MenuItem>
            {Object.keys(vehicleTypes).map(key => (
              <MenuItem
                key={key}
                value={key}
                data-test-id={`${key}MenuItem`}
              >
                {t(vehicleTypeLabels[key])}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {isAddressRequired && (
        <div
          ref={formRefs.address}
          className={classNames(
            rcStyles.rc_layer_order_0,
            rcStyles.colspan2,
          )}
        >
          <AddressFormSection
            address={formData.address}
            lookupLabel={t('Service venue address *')}
            onChange={address => onChange('address', address)}
            errors={errors.address}
            disabled={disabled}
            data-test-id="addressFormSection"
          />
        </div>
      )}
      <div className={classNames(
        rcStyles.rc_layer_order_0,
        rcStyles.colspan2,
      )}
      >
        <DirectoryListingLookup
          value={formData.supplier}
          label="Select supplier"
          onChange={changedValue => onChange('supplier', changedValue && changedValue.value)}
          disabled={disabled}
          isSupplier
          data-test-id="directoryListingLookup"
        />
      </div>
      <div className={classNames(
        rcStyles.rc_layer_order_1,
        rcStyles.colspan2,
      )}
      >
        <OrganisationalUnitLookup
          value={formData.organisationalUnits}
          label="Select home"
          onChange={value => onChange('organisationalUnits', value)}
          isMulti
          data-test-id="homeLookup"
        />
      </div>

      <div className={classNames(
        rcStyles.colspan2,
        rcStyles.rc_layer_order_2,
      )}
      >
        <CatalogueItemTagsLookup
          value={formData.tags}
          label="Add tags"
          onChange={value => onChange('tags', value)}
          isMulti
          category={formData.category && formData.category.category}
          catalogueItemType={catalogueItemType}
          data-test-id="tagsLookup"
        />
      </div>

      {(formData.id || isNewItem) && (
      <div className={rcStyles.colspan3}>
        <MediaUpload
          images={formData.images}
          isMulti
          acceptImages
          provisionalUploadMessage={t('Images are recommended to be a minimum of 1280px x 960px.')}
          showImagePreview
          contentDisposition="inline"
          onChangeImages={onChangeImages}
          onUploadDone={newImage => onImageUpload(newImage)}
          data-test-id="uploadCatalogueItemImage"
        />
      </div>
      )}

      <TextField
        label={t('Note')}
        value={formData.note || ''}
        onChange={event => onChange('note', event.target.value)}
        fullWidth
        inputProps={{
          'data-test-id': 'note',
        }}
        disabled={disabled}
        className={rcStyles.colspan2}
      />

      <div
        ref={formRefs.variants}
        className={rcStyles.colspan3}
      >
        <AdminCatalogueItemVariantList
          variants={formData.variants || []}
          images={formData.images}
          selectedImage={selectedImage}
          disabled={disabled}
          selectedVariantForImage={selectedVariantForImage}
          onChangeVariant={onChangeVariant}
          onAddVariant={onAddVariant}
          onDeleteVariant={onDeleteVariant}
          toggleSelectImageModal={toggleSelectImageModal}
          onSelectVariantImage={onSelectVariantImage}
          onSaveVariantImage={onSaveVariantImage}
          errors={errors}
        />
      </div>

      <div className={classNames(
        styles.o_button_bar,
        styles.has_top_border,
        rcStyles.colspan3,
      )}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          data-test-id="cancel"
        >
          {t('Cancel')}
        </Button>

        <Button
          onClick={togglePreviewModal}
          variant="outlined"
          color="primary"
          data-test-id="previewItem"

        >
          {t('Preview')}
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          data-test-id={`${isNewItem ? 'create' : 'save'}`}
        >
          {t(`${isNewItem ? 'Create' : 'Save'}`)}
        </Button>
      </div>
      <CatalogueItemPreviewModal
        isOpen={isPreviewModalOpen}
        item={formData}
        disabled
        onClose={togglePreviewModal}
      />
    </div>
  </div>
);

AdminCatalogueItemForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedImage: mediaType,
  isNewItem: PropTypes.bool,
  catalogueItemType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isServiceCategory: PropTypes.bool,
  isPreviewModalOpen: PropTypes.bool.isRequired,
  selectedVariantForImage: PropTypes.string,
  isAddressRequired: PropTypes.bool,
  hasXeroIntegration: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onChangeVariant: PropTypes.func.isRequired,
  onAddVariant: PropTypes.func.isRequired,
  onDeleteVariant: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  togglePreviewModal: PropTypes.func.isRequired,
  toggleSelectImageModal: PropTypes.func.isRequired,
  onChangeImages: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectVariantImage: PropTypes.func.isRequired,
  onSaveVariantImage: PropTypes.func.isRequired,
};

export default AdminCatalogueItemForm;
