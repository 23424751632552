import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SupportFooter from 'components/support/SupportFooter';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const LoginScreenView = ({
  formData,
  usernameRef,
  isLoading,
  errors,
  onChange,
  onLogin,
}) => {
  if (isLoading) {
    return (
      <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
        <IconLoad />
      </div>
    );
  }

  return (
    <div className={rcStyles.login_container}>
      <div className={rcStyles.col1}>
        <form onSubmit={onLogin} className={rcStyles.login_form}>
          <div className={rcStyles.logo}>
            <img src="/img/arranger-logo-hor-purple.svg" alt="Arranger" />
          </div>
          <div className={rcStyles.v_spacing}>
            <TextField
              label={t('Username')}
              data-test-id="username"
              InputProps={{
                inputRef: usernameRef,
              }}
              value={formData.username}
              onChange={event => onChange('username', event.target.value)}
              error={!!errors.username}
              helperText={errors.username}
              fullWidth
            />
            <TextField
              type="password"
              label={t('Password')}
              data-test-id="password"
              value={formData.password || ''}
              onChange={event => onChange('password', event.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              fullWidth
            />
            <div className={styles.u_push__bottom}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                data-test-id="login"
                onClick={onLogin}
              >
                {t('Login')}
              </Button>
            </div>
            <Button
              color="primary"
              variant="text"
              component={Link}
              data-test-id="resetPassword"
              to="/reset-password"
            >
              {t('Forgotten password')}
            </Button>
          </div>
        </form>
        {process.env.FEATURE_FLAG_SUPPORT_ON_LOGIN && (
          <div className={rcStyles.support_links}>
            <SupportFooter />
          </div>
        )}
      </div>
      <div className={rcStyles.col2}>
        <div className={rcStyles.is_image}>
          <img src="/img/core/laplady.png" alt="" />
        </div>
      </div>
    </div>
  );
};

LoginScreenView.propTypes = {
  formData: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  usernameRef: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default LoginScreenView;
