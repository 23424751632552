import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import {
  clearBereavementsAction,
  fetchBereavementsAction,
  backgroundFetchBereavementsAction,
  updateFiltersAction,
} from 'actions/bereavement';
import {
  statusesFilters,
  ownersFilters,
  homesFilters,
  BEREAVEMENTS_PER_PAGE,
} from 'constants/bereavement';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import CaseListScreen from './CaseListScreen';

class CaseListScreenContainer extends Component {
  debounceGetBereavements = debounce((filters) => {
    const { clearBereavements } = this.props;
    clearBereavements();
    this.setState({ bereavements: [] });
    this.getMoreBereavements(filters);
  }, 500);

  static propTypes = {
    bereavements: PropTypes.arrayOf(bereavementType.isRequired).isRequired,
    homes: PropTypes.arrayOf(organisationalUnitType.isRequired).isRequired,
    ownerId: PropTypes.string.isRequired,
    isOnline: PropTypes.bool.isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isListLoaded: PropTypes.bool.isRequired,
    clearBereavements: PropTypes.func.isRequired,
    fetchBereavements: PropTypes.func.isRequired,
    backgroundFetchBereavements: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.isLoading && nextProps.bereavements.length > state.bereavements.length) {
      return { bereavements: [...nextProps.bereavements] };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavements: [],
      homes: [{
        id: homesFilters[0].id,
        name: t(homesFilters[0].name),
      }, ...props.homes],
    };
  }

  componentDidMount() {
    const {
      bereavements,
      filters,
      isOnline,
      isListLoaded,
      updateFilters,
      backgroundFetchBereavements,
    } = this.props;

    if (!filters.organisationalUnitId || !filters.ownerId) {
      updateFilters('caseStatus', statusesFilters[0].id);
      updateFilters('organisationalUnitId', homesFilters[0].id);
      updateFilters('ownerId', ownersFilters[0].id);
    }

    if (bereavements.length > 0) {
      this.getDisplayBereavements();
    }

    if (isOnline) {
      if (isListLoaded && bereavements.length > 0) {
        backgroundFetchBereavements(bereavements[bereavements.length - 1].id);
      } else {
        this.getMoreBereavements();
      }
    }
  }

  getDisplayBereavements = () => {
    const { bereavements, pagination } = this.props;
    const { bereavements: displayBereavements } = this.state;

    if (displayBereavements.length >= bereavements.length && pagination.hasNextPage) {
      this.getMoreBereavements();
      return;
    }

    const lastIndex = bereavements.length - displayBereavements.length < BEREAVEMENTS_PER_PAGE
      ? bereavements.length
      : displayBereavements.length + BEREAVEMENTS_PER_PAGE;

    this.setState({
      bereavements: [
        ...displayBereavements,
        ...bereavements.slice(displayBereavements.length, lastIndex),
      ],
    });
  }

  getMoreBereavements = (filters) => {
    const { fetchBereavements } = this.props;
    fetchBereavements(filters);
  }

  handleFiltersChange = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetBereavements({ ...filters, [key]: value });
  }

  filterBereavements = (filters, bereavements) => {
    const { ownerId } = this.props;
    let filtered = bereavements.filter(bereavement => bereavement.caseStatus === filters.caseStatus);
    if (filters.organisationalUnitId !== 'all') {
      filtered = filtered.filter(bereavement => bereavement.homeId === filters.organisationalUnitId);
    }
    if (filters.ownerId === 'mine') {
      filtered = filtered.filter(bereavement => bereavement.ownerId === ownerId);
    }
    return filtered;
  };

  render() {
    const {
      bereavements: stateBereavements,
      pagination,
      filters,
      isLoading,
    } = this.props;
    const { bereavements, homes } = this.state;
    const hasMoreBereavements = stateBereavements.length > bereavements.length;

    return (
      <CaseListScreen
        bereavements={this.filterBereavements(filters, bereavements)}
        hasMoreBereavements={pagination.hasNextPage || hasMoreBereavements}
        getMoreBereavements={this.getDisplayBereavements}
        isLoading={isLoading}
        filters={filters}
        homes={homes}
        onFiltersChange={this.handleFiltersChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  homes: state.userStore.user.staffMember.organisationalUnitsFlattened,
  ownerId: state.userStore.user.staffMember.id,
  isOnline: state.userStore.isOnline,
  bereavements: state.bereavementStore.bereavements,
  pagination: state.bereavementStore.pagination,
  filters: state.bereavementStore.filters,
  isLoading: state.bereavementStore.isLoading,
  isListLoaded: state.bereavementStore.isListLoaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearBereavements: clearBereavementsAction,
  fetchBereavements: fetchBereavementsAction,
  backgroundFetchBereavements: backgroundFetchBereavementsAction,
  updateFilters: updateFiltersAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withApollo(CaseListScreenContainer),
);
